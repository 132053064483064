import { useQuery } from "@apollo/client";
import immer from "immer";
import { useEffect, useState, useMemo } from "react";
import { useCurrentCaTerm } from "../../hooks/use-current-ca-term";
import { defineDataLayer, pushDataLayerItem } from "./data-layer";
import { FetchGtmParams, FetchGtmParamsDocument } from "./graphql/fetch-gtm-param-query";
import { DataLayerItem } from "./types";

/**
 * クエリから取得した内容を dataLayer に追加する内容に変換する関数
 * @param data
 * @returns
 */
export const convertIntoDataLayerItem = (data: FetchGtmParams): DataLayerItem => ({
  user_id: data.viewer.identificationCode,
  office_identification_code: data.viewer.basicSetting.officeIdentificationCode,
  office_type: data.viewer.basicSetting.officeType.toLowerCase(),
});

/**
 * GTM が参照するグローバル変数を宣言するフック
 * @returns 宣言が完了したかどうか
 */
export const useDefineDataLayer = (): boolean => {
  const [defined, setDefined] = useState(false);
  const currentCaTerm = useCurrentCaTerm();
  // FIXME: ヘッダの追加を行う apollo-client のラッパーを実装する
  const headers = useMemo<Readonly<Record<string, string>>>(() => {
    return immer<Record<string, string>>({}, (draft) => {
      if (currentCaTerm) {
        // バックエンド側で課税方式の判定を CA の会計期間で行うことを示す HTTP ヘッダ
        draft["x-ctax-use-current-ca-term"] = "true";
      }
    });
  }, [currentCaTerm]);
  const { data, loading } = useQuery<FetchGtmParams>(FetchGtmParamsDocument, {
    context: { headers },
  });

  useEffect(() => {
    if (loading || defined) {
      return;
    }

    defineDataLayer();

    if (data && !data.viewer.isProxy) {
      pushDataLayerItem(convertIntoDataLayerItem(data));
    }

    setDefined(true);
  }, [defined, loading, data]);

  return defined;
};

import React from "react";
import { UseStoreAndActions } from "./types";

type Comparer = (a: unknown, b: unknown) => boolean;

type StoreContext<
  State extends Readonly<Record<string, unknown>>,
  Actions extends Readonly<Record<string, unknown>>,
> = {
  context: React.Context<UseStoreAndActions<State, Actions> | undefined>;
  StoreProvider: React.ComponentType<{
    store: UseStoreAndActions<State, Actions>;
    children?: React.ReactNode;
  }>;
  useStore: <Slice>(selector: (state: State) => Slice, comparer?: Comparer) => Slice;
  useActions: () => Actions;
};

export const createStoreContext = <
  State extends Readonly<Record<string, unknown>>,
  Actions extends Readonly<Record<string, unknown>>,
>(): StoreContext<State, Actions> => {
  type ContextValue = UseStoreAndActions<State, Actions>;
  const context = React.createContext<ContextValue | undefined>(undefined);

  const useContext = (): ContextValue => {
    const value = React.useContext(context);
    if (value == null) {
      throw new Error("Component must be wrapped with <context.Provider>");
    }
    return value;
  };

  return {
    context,
    StoreProvider: (props) => (
      <context.Provider value={props.store}>{props.children}</context.Provider>
    ),
    useStore: (selector, comparer) => {
      const { useStore } = useContext();
      return useStore(selector, comparer);
    },
    useActions: () => {
      const { actions } = useContext();
      return actions;
    },
  };
};

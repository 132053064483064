/* eslint-disable */
// This file was automatically generated and should not be edited.
// @ts-nocheck
export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: string; output: string };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  Percentage: { input: string; output: string };
};

/** 経理方式 */
export const enum AccountingMethod {
  /** 税抜（内税） */
  TaxExcludedInside = "TAX_EXCLUDED_INSIDE",
  /** 税抜（別記） */
  TaxExcludedSeparate = "TAX_EXCLUDED_SEPARATE",
  /** 税込 */
  TaxIncluded = "TAX_INCLUDED",
}

/** バックグランドタスク */
export type BackgroundTasks = {
  readonly __typename: "BackgroundTasks";
  /** Background task of syncing basic data from cloud accounting */
  readonly syncBasicDataTask: SyncBasicDataTask;
};

/** 金融機関 */
export type BankAccountInfo = {
  readonly __typename: "BankAccountInfo";
  /** 口座番号 */
  readonly bankAccountNumber: Maybe<Scalars["String"]["output"]>;
  /** 預金種類 */
  readonly bankAccountType: Maybe<BankAccountType>;
  /** 金融機関名 */
  readonly bankName: Maybe<Scalars["String"]["output"]>;
  /** 金融機関区分 */
  readonly bankType: Maybe<BankType>;
  /** 支店名 */
  readonly branchName: Maybe<Scalars["String"]["output"]>;
  /** 支店区分 */
  readonly branchType: Maybe<BankBranchType>;
  /** ゆうちょ銀行を使うか */
  readonly isYuucho: Scalars["Boolean"]["output"];
  /** ゆうちょ銀行の貯金記号番号（上５桁） */
  readonly postBankAccountNumber1: Maybe<Scalars["String"]["output"]>;
  /** ゆうちょ銀行の貯金記号番号（下８桁） */
  readonly postBankAccountNumber2: Maybe<Scalars["String"]["output"]>;
  /** 郵便局名等 */
  readonly postOfficeName: Maybe<Scalars["String"]["output"]>;
};

/** Input for saving bank account info */
export type BankAccountInfoInput = {
  /** 口座番号 */
  readonly bankAccountNumber: InputMaybe<Scalars["String"]["input"]>;
  /** 預金種類 */
  readonly bankAccountType: InputMaybe<BankAccountType>;
  /** 金融機関名 */
  readonly bankName: InputMaybe<Scalars["String"]["input"]>;
  /** 金融機関区分 */
  readonly bankType: InputMaybe<BankType>;
  /** 支店名 */
  readonly branchName: InputMaybe<Scalars["String"]["input"]>;
  /** 支店区分 */
  readonly branchType: InputMaybe<BankBranchType>;
  /** ゆうちょ銀行を使うか */
  readonly isYuucho: Scalars["Boolean"]["input"];
  /** ゆうちょ銀行の貯金記号番号（上５桁） */
  readonly postBankAccountNumber1: InputMaybe<Scalars["String"]["input"]>;
  /** ゆうちょ銀行の貯金記号番号（下８桁） */
  readonly postBankAccountNumber2: InputMaybe<Scalars["String"]["input"]>;
  /** 郵便局名等 */
  readonly postOfficeName: InputMaybe<Scalars["String"]["input"]>;
};

/** 預金種類 */
export const enum BankAccountType {
  /** 別段 */
  Betsudan = "BETSUDAN",
  /** 貯蓄 */
  Chochiku = "CHOCHIKU",
  /** 普通 */
  Futsu = "FUTSU",
  /** 納税準備 */
  NozeiJunbi = "NOZEI_JUNBI",
  /** その他 */
  Other = "OTHER",
  /** 当座 */
  Touza = "TOUZA",
  /** 通知 */
  Tsuchi = "TSUCHI",
}

/** 銀行口座の支店区分 */
export const enum BankBranchType {
  /** 本所 */
  Honsho = "HONSHO",
  /** 本店 */
  Honten = "HONTEN",
  /** 支所 */
  Shisho = "SHISHO",
  /** 支店 */
  Shiten = "SHITEN",
  /** 出張所 */
  Shuchoujo = "SHUCHOUJO",
}

/** 金融機関区分 */
export const enum BankType {
  /** 銀行 */
  Ginko = "GINKO",
  /** 漁協 */
  Gyokyo = "GYOKYO",
  /** 金庫 */
  Kinko = "KINKO",
  /** 組合 */
  Kumiai = "KUMIAI",
  /** 農協 */
  Noukyo = "NOUKYO",
}

/** Base period taxable sales info */
export type BasePeriodTaxableSalesInfo = {
  readonly __typename: "BasePeriodTaxableSalesInfo";
  /** 基準期間の課税売上高 */
  readonly basePeriodTaxableSales: Scalars["BigInt"]["output"];
  /** 取得元 */
  readonly basePeriodTaxableSalesType: BasePeriodTaxableSalesInfoType;
  /** 取得元の期間終わり */
  readonly endDate: Scalars["Date"]["output"];
  /** 取得元の期間始まり */
  readonly startDate: Scalars["Date"]["output"];
};

/** Input for fetching BasePeriodTaxableSalesInfo */
export type BasePeriodTaxableSalesInfoInput = {
  /** 取得元 */
  readonly basePeriodTaxableSalesType: BasePeriodTaxableSalesInfoType;
  /** 取得元の期間終わり */
  readonly endDate: Scalars["Date"]["input"];
  /** 取得元の期間始まり */
  readonly startDate: Scalars["Date"]["input"];
};

/** Source of base period taxable sales info */
export const enum BasePeriodTaxableSalesInfoType {
  /** CA_WEBから */
  CaWeb = "CA_WEB",
  /** 消費税申告から */
  Self = "SELF",
}

/** Basic info of current user which is fetched from cloud accounting */
export type BasicInfo = {
  readonly __typename: "BasicInfo";
  /** 経理方式 */
  readonly accountingMethod: AccountingMethod;
  /** 住所 */
  readonly address: Maybe<Scalars["String"]["output"]>;
  /** 代表者氏名また氏名 */
  readonly contactUserName: Maybe<Scalars["String"]["output"]>;
  /** 法人名称また屋号 */
  readonly name: Maybe<Scalars["String"]["output"]>;
  /** 課税方式 */
  readonly officeExciseType: OfficeExciseType;
  /** 電話番号 */
  readonly tel: Maybe<Scalars["String"]["output"]>;
  /** 郵便番号 */
  readonly zipCode: Maybe<Scalars["String"]["output"]>;
};

/** 基本情報データ V1 */
export type BasicInformation_V1 = Node & {
  readonly __typename: "BasicInformation_V1";
  /** 還付先金融機関情報 */
  readonly bankAccountInfo: Maybe<BankAccountInfo>;
  /** 代表者氏名 */
  readonly daihyoNm: Scalars["String"]["output"];
  /** 代表者フリガナ */
  readonly daihyoNmKn: Scalars["String"]["output"];
  /** 税理士/氏名 */
  readonly dairiNm: Maybe<Scalars["String"]["output"]>;
  /** 税理士/電話番号 */
  readonly dairiTel: Maybe<TelephoneNumber>;
  /** 電子申告情報/利用者識別番号 */
  readonly etaxIdentificationNumber: Maybe<Scalars["String"]["output"]>;
  /** 口座情報利用同意 */
  readonly hasAccountInformationUsageAgreement: Scalars["Boolean"]["output"];
  /** 振替継続希望 */
  readonly hasContinuedTransferRequest: Scalars["Boolean"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 税理士法第30条の書面提出 */
  readonly isDairiLaw30: Scalars["Boolean"]["output"];
  /** 税理士法第33条の2の書面提出 */
  readonly isDairiLaw33_2: Scalars["Boolean"]["output"];
  /** 住所 */
  readonly nozeishaAdr: Scalars["String"]["output"];
  /** 法人番号/個人番号 */
  readonly nozeishaBango: Scalars["String"]["output"];
  /** 法人名称/屋号 */
  readonly nozeishaNm: Scalars["String"]["output"];
  /** フリガナ */
  readonly nozeishaNmKn: Scalars["String"]["output"];
  /** 電話番号 */
  readonly nozeishaTel: TelephoneNumber;
  /** 郵便番号 */
  readonly nozeishaZip: ZipCode;
  /** 所轄税務署コード */
  readonly zeimushoCd: Scalars["String"]["output"];
  /** 所轄税務署名 */
  readonly zeimushoNm: Scalars["String"]["output"];
};

/** ユーザーの基本情報 */
export type BasicSetting = {
  readonly __typename: "BasicSetting";
  /** ログイン中のユーザーの基本情報 */
  readonly basicInfo: BasicInfo;
  /** CA側の現在の会計期間 */
  readonly currentCaTerm: Term;
  /** 会計側の選択中の事業者のID */
  readonly officeID: Scalars["String"]["output"];
  /** 事業者番号 */
  readonly officeIdentificationCode: Scalars["String"]["output"];
  /** 事業者名 */
  readonly officeName: Scalars["String"]["output"];
  /** 事業者区分 */
  readonly officeType: OfficeType;
};

/** Confirmed consumption tax data */
export type ConfirmedConsumptionTax = {
  readonly __typename: "ConfirmedConsumptionTax";
  /** 納付期限 */
  readonly deadline: Maybe<Scalars["Date"]["output"]>;
  /** 地方消費税分 */
  readonly localTaxAmount: Scalars["BigInt"]["output"];
  /** 国税分 */
  readonly nationalTaxAmount: Scalars["BigInt"]["output"];
  /** 合計納付（還付）額 */
  readonly totalTaxAmount: Scalars["BigInt"]["output"];
};

/** Parameters for creating taxation period */
export type CreateTaxationPeriodInput = {
  /** 申告タイプ */
  readonly declarationType: DeclarationType;
  /** 課税期間の終了日 */
  readonly endDate: Scalars["Date"]["input"];
  /** 課税期間の短縮をおこなうかどうか */
  readonly isShortTaxationPeriod: Scalars["Boolean"]["input"];
  /** 課税期間の開始日 */
  readonly startDate: Scalars["Date"]["input"];
  /** 会計期間ID(GraphqlID) */
  readonly termID: Scalars["ID"]["input"];
};

/** Data needed to setup and display the chat widget */
export type CustomerSupport = {
  readonly __typename: "CustomerSupport";
  /** ウィジェットを表示して良いか */
  readonly isAvailableWidget: Scalars["Boolean"]["output"];
  /** エクレクトウィジェットを使用するかどうか */
  readonly isOpenEclectWidget: Scalars["Boolean"]["output"];
  /** オーナーかどうか */
  readonly tagIsOwner: Scalars["String"]["output"];
  /** サポートグループ加入済みの事業者かどうか */
  readonly tagIsSgClient: Scalars["String"]["output"];
  /** サポートグループ加入メンバーかどうか */
  readonly tagIsSgMember: Scalars["String"]["output"];
  /** 事業者番号 */
  readonly tagOfficeIdentificationCode: Scalars["String"]["output"];
  /** 事業者区分 */
  readonly tagOffieType: Scalars["String"]["output"];
  /** 契約中のプラン名 */
  readonly tagPremiumPlanName: Scalars["String"]["output"];
  /** 加入中のサポートグループ名 */
  readonly tagSgName: Scalars["String"]["output"];
  /** ユーザ識別子 */
  readonly tagUserIdentificationCode: Scalars["String"]["output"];
  /** メールアドレス */
  readonly userDummyEmail: Scalars["String"]["output"];
  /** ユーザ名 */
  readonly userName: Scalars["String"]["output"];
};

/** Declaration basic data item */
export type DeclarationBasicDataItem_V1 = Node & {
  readonly __typename: "DeclarationBasicDataItem_V1";
  /** 入力された調整額 */
  readonly adjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 本体価格 */
  readonly basePrice: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 */
  readonly consumptionTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 表示順番（グループ内） */
  readonly displayOrder: Scalars["Int"]["output"];
  /** 表示設定 */
  readonly displaySetting: ExciseDisplaySetting;
  /** 税区分マスタのID */
  readonly exciseID: Scalars["ID"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 未区分の項目なのか */
  readonly isUncategorized: Scalars["Boolean"]["output"];
  /** タイトル */
  readonly name: Scalars["String"]["output"];
  /** 税込価格 */
  readonly taxIncludedPrice: Maybe<Scalars["BigInt"]["output"]>;
};

/** Declaration basic data item */
export type DeclarationBasicDataItem_V2 = Node & {
  readonly __typename: "DeclarationBasicDataItem_V2";
  /** 入力された調整額 */
  readonly adjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 本体価格 */
  readonly basePrice: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 */
  readonly consumptionTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 表示順番（グループ内） */
  readonly displayOrder: Scalars["Int"]["output"];
  /** 表示設定 */
  readonly displaySetting: ExciseDisplaySetting_V2;
  /** 税区分マスタのID */
  readonly exciseID: Scalars["ID"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** インボイス区分 */
  readonly invoiceKind: InvoiceKind;
  /** 未区分の項目なのか */
  readonly isUncategorized: Scalars["Boolean"]["output"];
  /** タイトル */
  readonly name: Scalars["String"]["output"];
  /** 税込価格 */
  readonly taxIncludedPrice: Maybe<Scalars["BigInt"]["output"]>;
};

/** Declaration basic data total item */
export type DeclarationBasicDataTotalItem_V2 = Node & {
  readonly __typename: "DeclarationBasicDataTotalItem_V2";
  /** 表示順番（グループ内） */
  readonly displayOrder: Scalars["Int"]["output"];
  /** 表示設定 */
  readonly displaySetting: ExciseDisplaySetting_V2;
  /** 税区分マスタのID */
  readonly exciseID: Scalars["ID"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** インボイス区分 */
  readonly invoiceKind: InvoiceKind;
  /** タイトル */
  readonly name: Scalars["String"]["output"];
};

/** Declaration basic data */
export type DeclarationBasicData_V1 = Node & {
  readonly __typename: "DeclarationBasicData_V1";
  /** 2023.9.30以前に80%控除を使用した仕訳があるか */
  readonly has80UnqualifiedBeforeInvoiceStartDate: Scalars["Boolean"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 使用されてない税区分グループ */
  readonly inactiveExciseTypes: ReadonlyArray<Scalars["String"]["output"]>;
  /** 会計側に消費税に関連する不正な仕訳が存在しているかどうか */
  readonly isIncludeInvalidConsumptionTaxJournal: Scalars["Boolean"]["output"];
  /** 会計データの最終取得時刻 */
  readonly lastCaSyncAt: Maybe<Scalars["DateTime"]["output"]>;
  /** 仕入にて税額を調整する必要があるか（調整額を表示するか） */
  readonly needPurchasingTaxAdjustment: Scalars["Boolean"]["output"];
  /** 売上にて税額を調整する必要があるか（調整額を表示するのか） */
  readonly needSalesTaxAdjustment: Scalars["Boolean"]["output"];
  /** 仕入系のグループ一覧 */
  readonly purchasingGroups: ReadonlyArray<ExciseGroup>;
  /** 仕入にて残りの要調整額 */
  readonly purchasingTaxAdjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上系のグループ一覧 */
  readonly salesGroups: ReadonlyArray<ExciseGroup>;
  /** 売上にて残りの要調整額 */
  readonly salesTaxAdjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** バージョン */
  readonly version: Version;
};

/** Declaration basic data */
export type DeclarationBasicData_V2 = Node & {
  readonly __typename: "DeclarationBasicData_V2";
  /** 2023.9.30以前に80%控除を使用した仕訳があるか */
  readonly has80UnqualifiedBeforeInvoiceStartDate: Scalars["Boolean"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 使用されてない税区分グループ */
  readonly inactiveExciseTypes: ReadonlyArray<Scalars["String"]["output"]>;
  /** 会計側に消費税に関連する不正な仕訳が存在しているかどうか */
  readonly isIncludeInvalidConsumptionTaxJournal: Scalars["Boolean"]["output"];
  /** 会計データの最終取得時刻 */
  readonly lastCaSyncAt: Maybe<Scalars["DateTime"]["output"]>;
  /** 仕入にて税額を調整する必要があるか（調整額を表示するか） */
  readonly needPurchasingTaxAdjustment: Scalars["Boolean"]["output"];
  /** 売上にて税額を調整する必要があるか（調整額を表示するのか） */
  readonly needSalesTaxAdjustment: Scalars["Boolean"]["output"];
  /** 仕入系のグループ一覧 */
  readonly purchasingGroups: ReadonlyArray<ExciseGroup_V2>;
  /** 仕入にて残りの要調整額 */
  readonly purchasingTaxAdjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上系のグループ一覧 */
  readonly salesGroups: ReadonlyArray<ExciseGroup_V2>;
  /** 売上にて残りの要調整額 */
  readonly salesTaxAdjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** バージョン */
  readonly version: Version;
};

/** Parameters for updating declaration basic data v1 */
export type DeclarationBasicV1UpdateInput = {
  /** 各税区分の金額データ */
  readonly exciseItems: ReadonlyArray<ExciseItemInput>;
  /** 経過措置対象課税資産の譲渡等があるか */
  readonly includeOldExciseTransactions: Scalars["Boolean"]["input"];
  /** 仕入の残りの調整額 */
  readonly purchasingAdjustmentAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 売上の残りの調整額 */
  readonly salesTaxAdjustmentAmount: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Parameters for updating declaration basic data v2 */
export type DeclarationBasicV2UpdateInput = {
  /** 各税区分の金額データ */
  readonly exciseItems: ReadonlyArray<ExciseItemInput>;
  /** 経過措置対象課税資産の譲渡等があるか */
  readonly includeOldExciseTransactions: Scalars["Boolean"]["input"];
  /** 仕入の残りの調整額 */
  readonly purchasingAdjustmentAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 売上の残りの調整額 */
  readonly salesTaxAdjustmentAmount: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Parameters for updating the declaration information adjustment amount v1 */
export type DeclarationInformationAdjustAmountV1Input = {
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率4%適用分 */
  readonly fixedAssetsTaxRate4ChangeTaxTypeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率4%適用分 */
  readonly fixedAssetsTaxRate4ChangeToTaxableRentalAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率4%適用分 */
  readonly fixedAssetsTaxRate4TaxableSalesChangeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率6.3%適用分 */
  readonly fixedAssetsTaxRate63ChangeTaxTypeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率6.3%適用分 */
  readonly fixedAssetsTaxRate63ChangeToTaxableRentalAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率6.3%適用分 */
  readonly fixedAssetsTaxRate63TaxableSalesChangeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率7.8%適用分 */
  readonly fixedAssetsTaxRate78ChangeTaxTypeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率7.8%適用分 */
  readonly fixedAssetsTaxRate78ChangeToTaxableRentalAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率7.8%適用分 */
  readonly fixedAssetsTaxRate78TaxableSalesChangeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率6.24%適用分 */
  readonly fixedAssetsTaxRate624ChangeTaxTypeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率6.24%適用分 */
  readonly fixedAssetsTaxRate624ChangeToTaxableRentalAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率6.24%適用分 */
  readonly fixedAssetsTaxRate624TaxableSalesChangeAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate4Common: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate4TaxableSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate4TaxfreeSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 総額 */
  readonly purchaseTaxRate4Total: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate63Common: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate63TaxableSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate63TaxfreeSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 総額 */
  readonly purchaseTaxRate63Total: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate78Common: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate78TaxableSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate78TaxfreeSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 総額 */
  readonly purchaseTaxRate78Total: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate624Common: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate624TaxableSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate624TaxfreeSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 総額 */
  readonly purchaseTaxRate624Total: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** 申告情報データ V1 / 仕入れ税額や固定資産関連の調整額 */
export type DeclarationInformationAdjustAmount_V1 = Node & {
  readonly __typename: "DeclarationInformationAdjustAmount_V1";
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率4%適用分 */
  readonly fixedAssetsTaxRate4ChangeTaxTypeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率4%適用分 */
  readonly fixedAssetsTaxRate4ChangeToTaxableRentalAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率4%適用分 */
  readonly fixedAssetsTaxRate4TaxableSalesChangeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率6.3%適用分 */
  readonly fixedAssetsTaxRate63ChangeTaxTypeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率6.3%適用分 */
  readonly fixedAssetsTaxRate63ChangeToTaxableRentalAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率6.3%適用分 */
  readonly fixedAssetsTaxRate63TaxableSalesChangeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率7.8%適用分 */
  readonly fixedAssetsTaxRate78ChangeTaxTypeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率7.8%適用分 */
  readonly fixedAssetsTaxRate78ChangeToTaxableRentalAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率7.8%適用分 */
  readonly fixedAssetsTaxRate78TaxableSalesChangeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整(加算又は減算)額 > 税率6.24%適用分 */
  readonly fixedAssetsTaxRate624ChangeTaxTypeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 > 税率6.24%適用分 */
  readonly fixedAssetsTaxRate624ChangeToTaxableRentalAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 固定資産にかかる消費税額の調整額 > 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 > 税率6.24%適用分 */
  readonly fixedAssetsTaxRate624TaxableSalesChangeAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 仕入れ税額の調整額 > 税率4%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate4Common: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate4TaxableSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate4TaxfreeSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率4%適用分 > 総額 */
  readonly purchaseTaxRate4Total: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate63Common: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate63TaxableSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate63TaxfreeSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.3%適用分 > 総額 */
  readonly purchaseTaxRate63Total: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate78Common: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate78TaxableSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate78TaxfreeSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率7.8%適用分 > 総額 */
  readonly purchaseTaxRate78Total: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 共通対応（原則課税・個別対応） */
  readonly purchaseTaxRate624Common: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate624TaxableSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 非課税売上対応（原則課税・個別対応） */
  readonly purchaseTaxRate624TaxfreeSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入れ税額の調整額 > 税率6.24%適用分 > 総額 */
  readonly purchaseTaxRate624Total: Maybe<Scalars["BigInt"]["output"]>;
};

/** 申告情報データ V1 */
export type DeclarationInformation_V1 = Node & {
  readonly __typename: "DeclarationInformation_V1";
  /** 仕入れ税額・固定資産関連の調整額 */
  readonly adjustAmountV1: Maybe<DeclarationInformationAdjustAmount_V1>;
  /** 現金主義会計の適用 */
  readonly applyCashBasisAccounting: Scalars["Boolean"]["output"];
  /** 工事進行基準の適用 */
  readonly applyConstructionProgressCriteria: Scalars["Boolean"]["output"];
  /** 延払基準の適用 */
  readonly applyDeferredPaymentCriteria: Scalars["Boolean"]["output"];
  /** 割賦基準の適用 */
  readonly applyInstallmentCriteria: Scalars["Boolean"]["output"];
  /** 基準期間の課税売上高 */
  readonly basePeriodTaxableSales: Maybe<Scalars["BigInt"]["output"]>;
  /** 基準期間の課税売上高の取得情報 */
  readonly basePeriodTaxableSalesInfo: Maybe<BasePeriodTaxableSalesInfo>;
  /** 既確定税額 */
  readonly establishedTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 既確譲渡割額 */
  readonly establishedTransferDiscountAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 中間納付税額 */
  readonly midTermTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付譲渡割額 */
  readonly midTermTransferDiscountAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 仕入処理方法 */
  readonly purchasingProcessingMethod: ProcessingMethod;
  /** 売上処理方法 */
  readonly salesProcessingMethod: ProcessingMethod;
  /** 提出日 */
  readonly teisyutsuDay: Maybe<Scalars["Date"]["output"]>;
};

/** 申告の種類 */
export const enum DeclarationType {
  /** 修正確定 */
  CorrectionFinal = "CORRECTION_FINAL",
  /** 修正中間 */
  CorrectionMidTerm = "CORRECTION_MID_TERM",
  /** 確定 */
  Final = "FINAL",
  /** 中間 */
  MidTerm = "MID_TERM",
}

/** 控除税額の計算方法 */
export const enum DeductionCalculation {
  /** 一括比例配分方式 */
  Ikkatsu = "IKKATSU",
  /** 個別対応方式 */
  Kobetsu = "KOBETSU",
  /** 2割特例 */
  Niwaritokurei = "NIWARITOKUREI",
  /** 全額控除 */
  Zengaku = "ZENGAKU",
}

/** Status of document updating */
export type DocumentUpdate = Node & {
  readonly __typename: "DocumentUpdate";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 最終更新日 */
  readonly lastEditAt: Maybe<Scalars["DateTime"]["output"]>;
};

/** Etax declaration's session data */
export type ETaxDeclarationSessionData = {
  readonly __typename: "ETaxDeclarationSessionData";
  /** Signature of document body */
  readonly documentSignature: Scalars["String"]["output"];
  /** Signature used in the login process */
  readonly loginSignature: Scalars["String"]["output"];
  /** ID of the session */
  readonly sessionID: Scalars["ID"]["output"];
  /** ID of current taxaion period */
  readonly taxationPeriodID: Scalars["ID"]["output"];
};

/** エラーの種類 */
export const enum ErrorType {
  /** CA側の経理方式と消費税申告側の経理方式が一致しない */
  AccountingMethodNotMatch = "ACCOUNTING_METHOD_NOT_MATCH",
  /** 現在の課税期間が持つOfficeに対応するCAのOfficeがない */
  CaOfficeNotFound = "CA_OFFICE_NOT_FOUND",
  /** クラウド会計の利用規約を同意していない場合のエラー */
  CaTermsAgreementRequired = "CA_TERMS_AGREEMENT_REQUIRED",
  /** 現在の課税期間がもつTerm対応するCAのTermがない */
  CaTermNotFound = "CA_TERM_NOT_FOUND",
  /** 「売上積上げx仕入割戻し」の組み合わせのバリデーションエラー */
  CombinationOfSalesTsumiageAndPurchasingWarimodoshiValidationFailure = "COMBINATION_OF_SALES_TSUMIAGE_AND_PURCHASING_WARIMODOSHI_VALIDATION_FAILURE",
  /** 会計側で選択している事業者また会計期間が見つからない */
  CurrentCaOfficeOrTermNotFound = "CURRENT_CA_OFFICE_OR_TERM_NOT_FOUND",
  /** 操作中の課税期間IDと入力された課税期間IDが一致しない */
  CurrentTaxationPeriodNotMatch = "CURRENT_TAXATION_PERIOD_NOT_MATCH",
  /** 操作中の会計期間と会計側の選択中の会計期間が一致しない */
  CurrentTermNotMatch = "CURRENT_TERM_NOT_MATCH",
  /** 申告情報の調整額の合計額が最大値を越える時のエラー */
  DeclarationAdjustmentTotalAmountOverflow = "DECLARATION_ADJUSTMENT_TOTAL_AMOUNT_OVERFLOW",
  /** Tiago で認証エラー */
  EtaxAuthenticationError = "ETAX_AUTHENTICATION_ERROR",
  /** Tiago で通信エラー */
  EtaxConnectionError = "ETAX_CONNECTION_ERROR",
  /** ETAX送信メンテナンスエラー */
  EtaxDeclarationDisabled = "ETAX_DECLARATION_DISABLED",
  /** Tiago メンテナンス中 */
  EtaxMaintenance = "ETAX_MAINTENANCE",
  /** 電子申告セッション切れエラー */
  EtaxSessionExpired = "ETAX_SESSION_EXPIRED",
  /** etax出力に必要な情報が不足している */
  EtaxValidationFailed = "ETAX_VALIDATION_FAILED",
  /** digest取得と申告送信でxtxファイルの内容が一致していない */
  EtaxXtxFileNotMatch = "ETAX_XTX_FILE_NOT_MATCH",
  /** インターナルエラー */
  InternalError = "INTERNAL_ERROR",
  /** 経理方式と処理方法の設定が不正 */
  InvalidProcessingMethodSetting = "INVALID_PROCESSING_METHOD_SETTING",
  /** 不正なリクエストルーティング */
  InvalidRequest = "INVALID_REQUEST",
  /** 課税期間のステータスが不正 */
  InvalidTaxationPeriodStatus = "INVALID_TAXATION_PERIOD_STATUS",
  /** V2の課税期間のバージョンが不正である */
  InvalidTaxationPeriodVersionForV2 = "INVALID_TAXATION_PERIOD_VERSION_FOR_V2",
  /** 決算・申告に対する権限を持っていない */
  NoPermission = "NO_PERMISSION",
  /** CA側の課税形式と消費税申告側の課税形式が一致しない */
  OfficeExciseTypeNotMatch = "OFFICE_EXCISE_TYPE_NOT_MATCH",
  /** 対象の事業者のメンバーが見つからない */
  OfficeMemberNotFound = "OFFICE_MEMBER_NOT_FOUND",
  /** 記帳代行クライアントのメンバーはアクセスできない */
  OutsourcingClientMemberInaccessible = "OUTSOURCING_CLIENT_MEMBER_INACCESSIBLE",
  /** PDF生成中なので関係するデータの更新は不可 */
  PdfGenerationProcessing = "PDF_GENERATION_PROCESSING",
  /** 権限が無い */
  PermissionDenied = "PERMISSION_DENIED",
  /** 必要な料金プランに加入していない */
  PremiumRequired = "PREMIUM_REQUIRED",
  /** ca_webで割戻しの処理方法をc-taxで積上げに変更できないバリデーションエラー */
  ProcessingMethodCombinationWithCaIsNotProper = "PROCESSING_METHOD_COMBINATION_WITH_CA_IS_NOT_PROPER",
  /** 経理方式と処理方法の設定のバリデーションが失敗 */
  ProcessingMethodValidationFailure = "PROCESSING_METHOD_VALIDATION_FAILURE",
  /** 代行ログインする時のIDミスマッチ */
  ProxyLoginIdMismatch = "PROXY_LOGIN_ID_MISMATCH",
  /** 代行ログインする時のセッション有効期限切れ */
  ProxyLoginSessionExpired = "PROXY_LOGIN_SESSION_EXPIRED",
  /** 代行ログインする時操作中でない課税期間を選択/作成/削除のエラー */
  ProxyLoginTaxationPeriodNotOperable = "PROXY_LOGIN_TAXATION_PERIOD_NOT_OPERABLE",
  /** 還付申告を行う必要が無いため実行できない */
  RefundDeclarationUnnecessary = "REFUND_DECLARATION_UNNECESSARY",
  /** 求めるリーソスが存在しない */
  ResourceNotFound = "RESOURCE_NOT_FOUND",
  /** CAからデータ取得中なので関係するデータの更新は不可 */
  SyncCaTaskProcessing = "SYNC_CA_TASK_PROCESSING",
  /** 対象の課税期間は既存のものより古い */
  TargetedTaxationPeriodIsOlderThanExisting = "TARGETED_TAXATION_PERIOD_IS_OLDER_THAN_EXISTING",
  /** 課税方式が課税ではないと利用できない */
  TaxableSettingRequired = "TAXABLE_SETTING_REQUIRED",
  /** 2割特例を設定できなかった */
  TaxationPeriodCantSetSpecialException_20Percent = "TAXATION_PERIOD_CANT_SET_SPECIAL_EXCEPTION_20_PERCENT",
  /** 同じ日付の課税期間が存在する */
  TaxationPeriodDateRangeOverlap = "TAXATION_PERIOD_DATE_RANGE_OVERLAP",
  /** 課税期間が選択されていない */
  TaxationPeriodNotSelected = "TAXATION_PERIOD_NOT_SELECTED",
  /** 現在の課税期間がCAの会計期間の範囲外 */
  TaxationPeriodOutsideOfCaTerm = "TAXATION_PERIOD_OUTSIDE_OF_CA_TERM",
  /** 現在の課税期間バージョンではこの機能は利用できない */
  TaxationPeriodVersionIsNotSupportThisFeature = "TAXATION_PERIOD_VERSION_IS_NOT_SUPPORT_THIS_FEATURE",
  /** 認証エラー */
  Unauthorized = "UNAUTHORIZED",
  /** バリデーションエラー */
  ValidationFailed = "VALIDATION_FAILED",
  /** 編集権限が必要なのに持っていない */
  WritePermissionRequired = "WRITE_PERMISSION_REQUIRED",
  /** XTX バリデーションエラー */
  XtxValidationError = "XTX_VALIDATION_ERROR",
}

/** Electronic declaration reception information */
export type EtaxAcceptance = {
  readonly __typename: "EtaxAcceptance";
  /** 受付日時 */
  readonly acceptDateTime: Scalars["DateTime"]["output"];
  /** 受付番号 */
  readonly acceptID: Scalars["String"]["output"];
  /** 受付ファイル名 */
  readonly fileName: Scalars["String"]["output"];
  /** Etaxから受理された利用者識別番号 */
  readonly userIdentificationNumber: Scalars["String"]["output"];
};

/** Parameters for submitting e-tax declaration */
export type EtaxDeclarationInput = {
  /** Certificate of document body */
  readonly documentCertificate: Scalars["String"]["input"];
  /** Signature of document body (which is got from startEtaxDeclaration) */
  readonly documentSignature: Scalars["String"]["input"];
  /** Certificate used to login (which is got from startEtaxDeclaration) */
  readonly loginCertificate: Scalars["String"]["input"];
  /** Signature used to login (which is got from startEtaxDeclaration) */
  readonly loginSignature: Scalars["String"]["input"];
  /** Whether to resend the declaration */
  readonly resend: InputMaybe<Scalars["Boolean"]["input"]>;
  /** ID of current etax session data (which is got from startEtaxDeclaration) */
  readonly sessionID: Scalars["ID"]["input"];
};

/** Response of submitting e-tax declaration */
export type EtaxDeclarationResponse = {
  readonly __typename: "EtaxDeclarationResponse";
  /** Electronic declaration reception information */
  readonly acceptance: Maybe<EtaxAcceptance>;
  /** 即時通知のエラー情報 */
  readonly errorMessage: Scalars["String"]["output"];
  /** E-Taxシステムから通知されるステータスコード */
  readonly etaxStatusCode: Scalars["String"]["output"];
  /** Response message received from e-tax system */
  readonly message: EtaxMessage;
  /** 送信 成功／失敗 */
  readonly responseStatus: EtaxResponseStatus;
};

/** XTXダウンロード可能かどうかの判定 */
export type EtaxFileDownloadable = {
  readonly __typename: "EtaxFileDownloadable";
  /** 「基本情報」に利用者識別番号が入力されていない */
  readonly isEmptyBasicInfoEtaxIdentificationNumber: Scalars["Boolean"]["output"];
  /** 「基本情報」に還付先金融機関が入力されていない */
  readonly isEmptyBasicInfoRefundBank: Scalars["Boolean"]["output"];
  /** 「基本情報」に還付先金融機関が入力されていないか口座利用区分を利用するになっていない */
  readonly isEmptyBasicInfoRefundBankOrAccountInformationUsageAgreement: Scalars["Boolean"]["output"];
  /** 「基本情報」に未入力の必須項目がある */
  readonly isEmptyBasicInfoRequiredField: Scalars["Boolean"]["output"];
  /** 申告基礎データにデータ入力がない */
  readonly isEmptyDeclarationBasicData: Scalars["Boolean"]["output"];
  /** 修正申告の場合は、既確定税額の入力が必要 */
  readonly isEmptyEstablishedTaxAmount: Scalars["Boolean"]["output"];
  /** 消費税の還付申告に関する明細書に不備がある */
  readonly isEmptyRefundDeclarationDetailRequiredField: Scalars["Boolean"]["output"];
  /** 「申告情報」の計算方法に入力不備がある */
  readonly isIncorrectDeclarationInfoCalculationMethod: Scalars["Boolean"]["output"];
  /** PermissionWriteが必要 */
  readonly isMissingWritePermission: Scalars["Boolean"]["output"];
  /** 「基本情報」の会計設定がクラウド会計と一致しない */
  readonly isNotMatchBasicInfoAccountingSettings: Scalars["Boolean"]["output"];
  /** 課税期間が所属する会計期間とCA_WEBで選択中の会計期間が異なる */
  readonly isTermDifferentWithCATerm: Scalars["Boolean"]["output"];
};

/** Message received from E-tax */
export type EtaxMessage = {
  readonly __typename: "EtaxMessage";
  /** 送信結果サブタイトル1 */
  readonly subTitle1: Scalars["String"]["output"];
  /** 送信結果サブタイトル2 */
  readonly subTitle2: Scalars["String"]["output"];
  /** 送信結果サブタイトル3 */
  readonly subTitle3: Scalars["String"]["output"];
  /** 送信結果タイトル */
  readonly title: Scalars["String"]["output"];
};

/** Status of etax declaration submitting response */
export const enum EtaxResponseStatus {
  /** The declaration is failed to submit */
  Failure = "FAILURE",
  /** The declaration is still being processed */
  Processing = "PROCESSING",
  /** The declaration is successfully submitted */
  Success = "SUCCESS",
}

/** Display setting of excise */
export type ExciseDisplaySetting = {
  readonly __typename: "ExciseDisplaySetting";
  /** 調整額を表示するのか */
  readonly useAdjustmentAmount: Scalars["Boolean"]["output"];
  /** 本体価格を表示するのか */
  readonly useBasePrice: Scalars["Boolean"]["output"];
  /** 消費税額を表示するのか */
  readonly useConsumptionTaxAmount: Scalars["Boolean"]["output"];
  /** 税込価格を表示するのか */
  readonly useTaxIncludedPrice: Scalars["Boolean"]["output"];
};

/** Excise Diaplay Setting */
export const enum ExciseDisplaySettingType {
  /** 自動計算 */
  AutoCalculation = "AUTO_CALCULATION",
  /** 編集可能 */
  Editable = "EDITABLE",
  /** 非表示 */
  None = "NONE",
}

/** Display setting of excise */
export type ExciseDisplaySetting_V2 = {
  readonly __typename: "ExciseDisplaySetting_V2";
  /** 調整額を表示するのか */
  readonly useAdjustmentAmount: ExciseDisplaySettingType;
  /** 本体価格を表示するのか */
  readonly useBasePrice: ExciseDisplaySettingType;
  /** 消費税額を表示するのか */
  readonly useConsumptionTaxAmount: ExciseDisplaySettingType;
  /** 税込価格を表示するのか */
  readonly useTaxIncludedPrice: ExciseDisplaySettingType;
};

/** Group of declaration basic data item */
export type ExciseGroup = Node & {
  readonly __typename: "ExciseGroup";
  /** 表示順番 */
  readonly displayOrder: Scalars["Int"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 現行税率のグループなのか */
  readonly isCurrentTaxRate: Scalars["Boolean"]["output"];
  /** グループ名 */
  readonly name: Scalars["String"]["output"];
  /** サブグループ一覧 */
  readonly subGroups: ReadonlyArray<ExciseSubGroup>;
  /** グループ内のアイテムの金額の合計を表せるフィールド */
  readonly total: Maybe<TotalItem>;
  /** グループの種類 */
  readonly type: GroupType;
};

/** Group of declaration basic data item */
export type ExciseGroup_V2 = Node & {
  readonly __typename: "ExciseGroup_V2";
  /** 表示順番 */
  readonly displayOrder: Scalars["Int"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 現行税率のグループなのか */
  readonly isCurrentTaxRate: Scalars["Boolean"]["output"];
  /** グループ名 */
  readonly name: Scalars["String"]["output"];
  /** サブグループ一覧 */
  readonly subGroups: ReadonlyArray<ExciseSubGroup_V2>;
  /** グループ内のアイテムの金額の合計を表すフィールド */
  readonly total: ReadonlyArray<DeclarationBasicDataTotalItem_V2>;
  /** グループの種類 */
  readonly type: GroupType;
};

/** Parameters for creating/updating excise item */
export type ExciseItemInput = {
  /** 調整額 */
  readonly adjustmentAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 本体価格 */
  readonly basePrice: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 消費税額 */
  readonly consumptionTaxAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 税区分のMasterID */
  readonly exciseID: Scalars["ID"]["input"];
  /** 税込価格 */
  readonly taxIncludedPrice: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Sub group of declaration basic data item */
export type ExciseSubGroup = Node & {
  readonly __typename: "ExciseSubGroup";
  /** 表示順番 */
  readonly displayOrder: Scalars["Int"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** サブグループを表示する必要があるか */
  readonly isDisplay: Scalars["Boolean"]["output"];
  /** Item list */
  readonly items: ReadonlyArray<DeclarationBasicDataItem_V1>;
  /** サブグループ名 */
  readonly name: Scalars["String"]["output"];
};

/** Sub group of declaration basic data item */
export type ExciseSubGroup_V2 = Node & {
  readonly __typename: "ExciseSubGroup_V2";
  /** 表示順番 */
  readonly displayOrder: Scalars["Int"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** サブグループを表示する必要があるか */
  readonly isDisplay: Scalars["Boolean"]["output"];
  /** Item list */
  readonly items: ReadonlyArray<DeclarationBasicDataItem_V2>;
  /** サブグループ名 */
  readonly name: Scalars["String"]["output"];
  /** グループ内のアイテムの金額の合計を表すフィールド */
  readonly total: ReadonlyArray<DeclarationBasicDataTotalItem_V2>;
};

/** 端数処理方法 */
export const enum FractionType {
  /** 切り捨て */
  RoundDown = "ROUND_DOWN",
  /** 四捨五入 */
  RoundOff = "ROUND_OFF",
  /** 切り上げ */
  RoundUp = "ROUND_UP",
}

/** Group type */
export const enum GroupType {
  /** 仕入 */
  Purchasing = "PURCHASING",
  /** 売上 */
  Sales = "SALES",
}

/** 中間納付予定税額 */
export type InterimPaymentInfo = {
  readonly __typename: "InterimPaymentInfo";
  /** 中間申告の合計金額 */
  readonly interimPaymentSum: InterimPaymentSum;
  /** 中間申告の回数とそれぞれの内容 */
  readonly interimPayments: ReadonlyArray<InterimPaymentItem>;
  /** 最後の課税期間の差引税額 */
  readonly lastTaxationPeriodSubtractedAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付税の予定額作成ステータス */
  readonly resultType: InterimPaymentResultType;
  /** 中間申告を行う会計期間の終了日 */
  readonly termEndDate: Scalars["Date"]["output"];
  /** 中間申告を行う会計期間の開始日 */
  readonly termStartDate: Scalars["Date"]["output"];
};

/** 中間納付予定税額の1回あたりの内容 */
export type InterimPaymentItem = {
  readonly __typename: "InterimPaymentItem";
  /** 中間申告の締切日 */
  readonly deadline: Scalars["Date"]["output"];
  /** 中間申告の地方税分 */
  readonly localTaxAmount: Scalars["BigInt"]["output"];
  /** 中間申告の国税分 */
  readonly nationalTaxAmount: Scalars["BigInt"]["output"];
  /** 中間申告の合計税額 */
  readonly totalAmount: Scalars["BigInt"]["output"];
};

/** Type of interim payment estimation response */
export const enum InterimPaymentResultType {
  /** 今期の最終日を含む課税期間がまだ作成されていないため、中間申告の予定額が作成できない */
  FinalTaxationPeriodNotCreated = "FINAL_TAXATION_PERIOD_NOT_CREATED",
  /** 中間申告の予定額が作成された */
  InterimPaymentEstimated = "INTERIM_PAYMENT_ESTIMATED",
  /** 差し引き税額が少ないため中間申告を行う必要がない */
  InterimPaymentNotRequired = "INTERIM_PAYMENT_NOT_REQUIRED",
  /** 会社設立日が設定されてない */
  OfficeEstablishmentDateNotSet = "OFFICE_ESTABLISHMENT_DATE_NOT_SET",
  /** 短縮課税期間であるため中間申告を行う必要がない */
  TaxationPeriodShortened = "TAXATION_PERIOD_SHORTENED",
}

/** 中間納付予定税の合計 */
export type InterimPaymentSum = {
  readonly __typename: "InterimPaymentSum";
  /** 中間申告の地方税分の合計税額 */
  readonly localTaxAmountSum: Scalars["BigInt"]["output"];
  /** 中間申告の国税分の合計税額 */
  readonly nationalTaxAmountSum: Scalars["BigInt"]["output"];
  /** 中間申告の合計税額 */
  readonly totalAmountSum: Scalars["BigInt"]["output"];
};

/** Information about whether it is invalid or not. */
export type InvalidInfo = {
  readonly __typename: "InvalidInfo";
  /** V2 taxation period (tax period with an end date on or after 2023/10/01) is invalid or not. */
  readonly isInvalidVersionForV2: Scalars["Boolean"]["output"];
};

/** インボイス区分 */
export const enum InvoiceKind {
  /** 対象外 */
  NotTarget = "NOT_TARGET",
  /** 適格 */
  Qualified = "QUALIFIED",
  /** 非適格（控除なし） */
  Unqualified = "UNQUALIFIED",
  /** 非適格（軽減措置50%控除） */
  Unqualified_50 = "UNQUALIFIED_50",
  /** 非適格（軽減措置80%控除） */
  Unqualified_80 = "UNQUALIFIED_80",
}

/** Logout response */
export type LogoutPayload = {
  readonly __typename: "LogoutPayload";
  /** URL to be redirected after logout */
  readonly logoutURI: Scalars["String"]["output"];
};

/** Root Mutation */
export type Mutation = {
  readonly __typename: "Mutation";
  /** 課税期間を作成する */
  readonly createTaxationPeriod: Maybe<TaxationPeriod>;
  /** 課税期間を削除する */
  readonly deleteTaxationPeriod: Scalars["Boolean"]["output"];
  /** PDF生成 */
  readonly generatePDF: PdfDownload;
  /** 認可コードを利用してログインを行う */
  readonly login: Scalars["String"]["output"];
  /** ログアウトを行う */
  readonly logout: LogoutPayload;
  /** ウェブのアクセスログを保存する */
  readonly saveWebAccessLog: Scalars["Boolean"]["output"];
  /** 課税期間を選択する */
  readonly selectTaxationPeriod: TaxationPeriod;
  /** 電子申告セッションを開始する */
  readonly startEtaxDeclaration: ETaxDeclarationSessionData;
  /** CAデータ取得タスクを開始する */
  readonly startSyncBasicDataFromCa: SyncBasicDataTask;
  /** 電子申告を行う */
  readonly submitEtaxDeclaration: EtaxDeclarationResponse;
  /** 基本情報を更新する */
  readonly updateBasicInformationV1: BasicInformation_V1;
  /** Update declaration basic data v1 */
  readonly updateDeclarationBasicV1: Scalars["Boolean"]["output"];
  /** Update declaration basic data v2 */
  readonly updateDeclarationBasicV2: Scalars["Boolean"]["output"];
  /** 申告情報を更新する */
  readonly updateDeclarationInformationV1: DeclarationInformation_V1;
  /** 設立日を設定する */
  readonly updateEstablishmentDate: Scalars["Boolean"]["output"];
  /** 基本情報/利用者識別番号を更新する(TaxationPeriod.Statusによらず実行可能) */
  readonly updateEtaxIdentificationNumber: Scalars["Boolean"]["output"];
  /** Update Refund Declaration Detail V1 */
  readonly updateRefundDeclarationDetailV1: Scalars["Boolean"]["output"];
  /** 2割特例を設定する */
  readonly updateTaxationPeriodSpecialException20Percent: Scalars["Boolean"]["output"];
  /** 課税期間のステータスを更新する */
  readonly updateTaxationPeriodStatus: Scalars["Boolean"]["output"];
};

/** Root Mutation */
export type MutationCreateTaxationPeriodArgs = {
  input: CreateTaxationPeriodInput;
  termID: Scalars["String"]["input"];
};

/** Root Mutation */
export type MutationDeleteTaxationPeriodArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
  termID: Scalars["String"]["input"];
};

/** Root Mutation */
export type MutationGeneratePdfArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationLoginArgs = {
  authorization_code: Scalars["String"]["input"];
  code_verifier: InputMaybe<Scalars["String"]["input"]>;
  redirect_uri: Scalars["String"]["input"];
};

/** Root Mutation */
export type MutationSaveWebAccessLogArgs = {
  input: SaveWebAccessLogInput;
};

/** Root Mutation */
export type MutationSelectTaxationPeriodArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
  termID: Scalars["String"]["input"];
};

/** Root Mutation */
export type MutationStartEtaxDeclarationArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationStartSyncBasicDataFromCaArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationSubmitEtaxDeclarationArgs = {
  input: EtaxDeclarationInput;
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateBasicInformationV1Args = {
  accountingMethod: AccountingMethod;
  input: UpdateBasicInformationV1Input;
  officeExciseType: OfficeExciseType;
};

/** Root Mutation */
export type MutationUpdateDeclarationBasicV1Args = {
  input: DeclarationBasicV1UpdateInput;
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateDeclarationBasicV2Args = {
  input: DeclarationBasicV2UpdateInput;
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateDeclarationInformationV1Args = {
  input: UpdateDeclarationInformationV1Input;
};

/** Root Mutation */
export type MutationUpdateEstablishmentDateArgs = {
  establishmentDate: Scalars["Date"]["input"];
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateEtaxIdentificationNumberArgs = {
  etaxIdentificationNumber: UpdateEtaxIdentificationNumberInput;
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateRefundDeclarationDetailV1Args = {
  input: RefundDeclarationDetailV1Input;
  officeType: OfficeType;
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateTaxationPeriodSpecialException20PercentArgs = {
  input: UpdateTaxationPeriodSpecialException20PercentParam;
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Mutation */
export type MutationUpdateTaxationPeriodStatusArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
  taxationPeriodStatus: TaxationPeriodStatus;
};

/** Node interface */
export type Node = {
  /** Unique identifier */
  readonly id: Scalars["ID"]["output"];
};

/** 課税方式 */
export const enum OfficeExciseType {
  /** 原則課税(一括比例配分方式) */
  PrincipleTaxationBulk = "PRINCIPLE_TAXATION_BULK",
  /** 原則課税(個別対応方式) */
  PrincipleTaxationIndividual = "PRINCIPLE_TAXATION_INDIVIDUAL",
  /** 簡易課税 */
  SimplifiedTaxation = "SIMPLIFIED_TAXATION",
  /** 免税事業者 */
  TaxFree = "TAX_FREE",
}

/** 事業者設定 */
export type OfficeSetting = {
  readonly __typename: "OfficeSetting";
  /** 事業者設立日 */
  readonly establishmentDate: Maybe<Scalars["Date"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 事業者 GraphQL ID */
  readonly officeGraphQLID: Scalars["String"]["output"];
  /** 事業者ID */
  readonly officeID: Scalars["String"]["output"];
};

/** 事業者の種類 */
export const enum OfficeType {
  /** 法人 */
  Corporate = "CORPORATE",
  /** 個人 */
  Individual = "INDIVIDUAL",
}

/** PDFダウンロード情報 */
export type PdfDownload = Node & {
  readonly __typename: "PDFDownload";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** Error message (for debugging purpose) */
  readonly message: Maybe<Scalars["String"]["output"]>;
  /** Status of PDF generation */
  readonly status: PdfGenerateStatus;
  /** URL of generated PDF file */
  readonly url: Maybe<Scalars["String"]["output"]>;
};

/** PDF生成状態 */
export const enum PdfGenerateStatus {
  /** キャンセル */
  Canceled = "CANCELED",
  /** 完了 */
  Done = "DONE",
  /** エラーが発生 */
  Error = "ERROR",
  /** 未実行 */
  None = "NONE",
  /** 処理中 */
  Processing = "PROCESSING",
}

/** 権限レベル */
export const enum PermissionLevel {
  /** 閲覧 */
  Read = "READ",
  /** 編集 */
  Write = "WRITE",
}

/** The type of etax procedure which are supported */
export const enum ProcedureType {
  /** 消費税及び地方消費税申告(一般・個人) */
  Rsh0010 = "RSH0010",
  /** 消費税及び地方消費税申告(一般・法人) */
  Rsh0020 = "RSH0020",
  /** 消費税及び地方消費税申告(簡易課税・個人) */
  Rsh0030 = "RSH0030",
  /** 消費税及び地方消費税申告(簡易課税・法人) */
  Rsh0040 = "RSH0040",
}

/** 手続きのバージョン覧 */
export const enum ProcedureVersions {
  /** バージョン V20_0_0 */
  V20_0_0 = "V20_0_0",
  /** バージョン V23_0_0 */
  V23_0_0 = "V23_0_0",
}

/** 売上/仕入の処理方式 */
export const enum ProcessingMethod {
  /** 積み上げ */
  Tsumiage = "TSUMIAGE",
  /** 割り戻し */
  Warimodoshi = "WARIMODOSHI",
}

/** Root Query */
export type Query = {
  readonly __typename: "Query";
  /** 認証URL取得 */
  readonly getAuthorizationURI: Scalars["String"]["output"];
  /** XTXファイルの生成 */
  readonly getGeneratedXTX: XtxDownload;
  /** PDF状態チェック */
  readonly getPDFGenerateStatus: PdfDownload;
  /** 代行ログイントークン取得 */
  readonly getProxyLoginToken: Scalars["String"]["output"];
  /** 住所から管轄税務署リストを取得 */
  readonly getTaxOffices: ReadonlyArray<TaxOffice>;
  /** Get the current urgent maintenance status of the service. */
  readonly isUrgentMaintenance: Scalars["Boolean"]["output"];
  /** 基準期間の課税売上高の候補一覧を取得する */
  readonly retrieveBasePeriodTaxableSales: ReadonlyArray<BasePeriodTaxableSalesInfo>;
  /** ログインしているユーザーの情報取得 */
  readonly viewer: User;
};

/** Root Query */
export type QueryGetAuthorizationUriArgs = {
  code_challenge: InputMaybe<Scalars["String"]["input"]>;
  code_challenge_method: InputMaybe<Scalars["String"]["input"]>;
  email: InputMaybe<Scalars["String"]["input"]>;
  redirect_uri: Scalars["String"]["input"];
  state: InputMaybe<Scalars["String"]["input"]>;
};

/** Root Query */
export type QueryGetGeneratedXtxArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Query */
export type QueryGetPdfGenerateStatusArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Root Query */
export type QueryGetProxyLoginTokenArgs = {
  code: Scalars["String"]["input"];
};

/** Root Query */
export type QueryGetTaxOfficesArgs = {
  address: Scalars["String"]["input"];
};

/** Root Query */
export type QueryRetrieveBasePeriodTaxableSalesArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** Accounting method types which are used in refund declaration detail information */
export const enum RefundDeclarationDetailAccountingMethod {
  /** 税抜き */
  TaxExcluded = "TAX_EXCLUDED",
  /** 税込み */
  TaxIncluded = "TAX_INCLUDED",
}

/** 輸出取引等に利用する主な通関業者 */
export type RefundDeclarationDetailExportCustomsInfo = {
  readonly __typename: "RefundDeclarationDetailExportCustomsInfo";
  /** 通関業者住所 */
  readonly address: Scalars["String"]["output"];
  /** 通関業者名 */
  readonly name: Scalars["String"]["output"];
};

/** 輸出取引等に利用する主な通関業者 */
export type RefundDeclarationDetailExportCustomsInfoInput = {
  /** 所管税関（支署）住所 */
  readonly address: Scalars["String"]["input"];
  /** 所管税関（支署）名 */
  readonly name: Scalars["String"]["input"];
};

/** 課税仕入れ等に係る事項 */
export type RefundDeclarationDetailPurchaseDetail = {
  readonly __typename: "RefundDeclarationDetailPurchaseDetail";
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["output"];
  /** 課税仕入れ等に係る事項：仕入れ金額明細：法人 V1 */
  readonly purchaseDetailCorporate: Maybe<RefundDeclarationDetailPurchaseDetailCorporate_V1>;
  /** 課税仕入れ等に係る事項：仕入れ金額明細：個人 V1 */
  readonly purchaseDetailIndividual: Maybe<RefundDeclarationDetailPurchaseDetailIndividual_V1>;
};

/** 課税仕入れ等に係る事項：仕入れ金額明細：法人 V1 */
export type RefundDeclarationDetailPurchaseDetailCorporate_V1 = {
  readonly __typename: "RefundDeclarationDetailPurchaseDetailCorporate_V1";
  /** 資産科目：税込・税抜 */
  readonly assetAccountingMethod: RefundDeclarationDetailAccountingMethod;
  /** 資産科目：繰延資産：(ロ) イのうち課税仕入れにならないもの */
  readonly assetDeferredAssetNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：繰延資産：(イ) 決算額(税込・税抜) */
  readonly assetDeferredAssetSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：繰延資産：課税仕入高(イ)ー(ロ) */
  readonly assetDeferredAssetTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：固定資産：(ロ) イのうち課税仕入れにならないもの */
  readonly assetFixedAssetNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：固定資産：(イ) 決算額(税込・税抜) */
  readonly assetFixedAssetSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：固定資産：課税仕入高(イ)ー(ロ) */
  readonly assetFixedAssetTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：その他：(ロ) イのうち課税仕入れにならないもの */
  readonly assetOtherNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：その他：(イ) 決算額(税込・税抜)) */
  readonly assetOtherSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：その他：課税仕入高(イ)ー(ロ) */
  readonly assetOtherTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly assetSubtotalNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：小計：(イ) 決算額(税込・税抜) */
  readonly assetSubtotalSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：小計：課税仕入高(イ)ー(ロ) */
  readonly assetSubtotalTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産科目：課税仕入れ等の税額の合計額：消費税額 */
  readonly assetTotalPurchaseConsumptionTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：税込・税抜 */
  readonly profitLossAccountingMethod: RefundDeclarationDetailAccountingMethod;
  /** 損益科目：営業外費用：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossNonOperatingExpensesNonTaxablePurchaseAmount: Maybe<
    Scalars["BigInt"]["output"]
  >;
  /** 損益科目：営業外費用：(イ) 決算額(税込・税抜) */
  readonly profitLossNonOperatingExpensesSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：営業外費用：課税仕入高(イ)ー(ロ) */
  readonly profitLossNonOperatingExpensesTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：その他：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossOtherNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：その他：(イ) 決算額(税込・税抜) */
  readonly profitLossOtherSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：その他：課税仕入高(イ)ー(ロ) */
  readonly profitLossOtherTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：商品仕入高等：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossPurchaseProductNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：商品仕入高等：(イ) 決算額(税込・税抜) */
  readonly profitLossPurchaseProductSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：商品仕入高等：課税仕入高(イ)ー(ロ) */
  readonly profitLossPurchaseProductTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：商品仕入高等：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossSellingExpenseNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：販売費・一般管理費：(イ) 決算額(税込・税抜) */
  readonly profitLossSellingExpenseSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：販売費・一般管理費：課税仕入高(イ)ー(ロ) */
  readonly profitLossSellingExpenseTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossSubtotalNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：小計：(イ) 決算額(税込・税抜) */
  readonly profitLossSubtotalSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 損益科目：小計：課税仕入高(イ)ー(ロ) */
  readonly profitLossSubtotalTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
};

/** 課税仕入れ等に係る事項：仕入れ金額明細：法人 V1 */
export type RefundDeclarationDetailPurchaseDetailCorporate_V1_Input = {
  /** 資産科目：税込・税抜 */
  readonly assetAccountingMethod: RefundDeclarationDetailAccountingMethod;
  /** 資産科目：繰延資産：(ロ) イのうち課税仕入れにならないもの */
  readonly assetDeferredAssetNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：繰延資産：(イ) 決算額(税込・税抜) */
  readonly assetDeferredAssetSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：繰延資産：課税仕入高(イ)ー(ロ) */
  readonly assetDeferredAssetTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：固定資産：(ロ) イのうち課税仕入れにならないもの */
  readonly assetFixedAssetNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：固定資産：(イ) 決算額(税込・税抜) */
  readonly assetFixedAssetSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：固定資産：課税仕入高(イ)ー(ロ) */
  readonly assetFixedAssetTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：その他：(ロ) イのうち課税仕入れにならないもの */
  readonly assetOtherNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：その他：(イ) 決算額(税込・税抜)) */
  readonly assetOtherSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：その他：課税仕入高(イ)ー(ロ) */
  readonly assetOtherTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly assetSubtotalNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：小計：(イ) 決算額(税込・税抜) */
  readonly assetSubtotalSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 資産科目：小計：課税仕入高(イ)ー(ロ) */
  readonly assetSubtotalTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：税込・税抜 */
  readonly profitLossAccountingMethod: RefundDeclarationDetailAccountingMethod;
  /** 損益科目：営業外費用：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossNonOperatingExpensesNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 損益科目：営業外費用：(イ) 決算額(税込・税抜) */
  readonly profitLossNonOperatingExpensesSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：営業外費用：課税仕入高(イ)ー(ロ) */
  readonly profitLossNonOperatingExpensesTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 損益科目：その他：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossOtherNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：その他：(イ) 決算額(税込・税抜) */
  readonly profitLossOtherSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：その他：課税仕入高(イ)ー(ロ) */
  readonly profitLossOtherTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：商品仕入高等：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossPurchaseProductNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 損益科目：商品仕入高等：(イ) 決算額(税込・税抜) */
  readonly profitLossPurchaseProductSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：商品仕入高等：課税仕入高(イ)ー(ロ) */
  readonly profitLossPurchaseProductTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：商品仕入高等：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossSellingExpenseNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：販売費・一般管理費：(イ) 決算額(税込・税抜) */
  readonly profitLossSellingExpenseSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：販売費・一般管理費：課税仕入高(イ)ー(ロ) */
  readonly profitLossSellingExpenseTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly profitLossSubtotalNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：小計：(イ) 決算額(税込・税抜) */
  readonly profitLossSubtotalSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 損益科目：小計：課税仕入高(イ)ー(ロ) */
  readonly profitLossSubtotalTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** 課税仕入れ等に係る事項：仕入れ金額明細：個人 V1 */
export type RefundDeclarationDetailPurchaseDetailIndividual_V1 = {
  readonly __typename: "RefundDeclarationDetailPurchaseDetailIndividual_V1";
  /** 事業所得：固定資産等の取得価格：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomeFixedAssetPriceNonTaxablePurchaseAmount: Maybe<
    Scalars["BigInt"]["output"]
  >;
  /** 事業所得：固定資産等の取得価格：(イ) 決算額(税込・税抜) */
  readonly businessIncomeFixedAssetPriceSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：固定資産等の取得価格：課税仕入高(イ)ー(ロ) */
  readonly businessIncomeFixedAssetPriceTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：必要経費：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomeNecessaryExpensesNonTaxablePurchaseAmount: Maybe<
    Scalars["BigInt"]["output"]
  >;
  /** 事業所得：必要経費：(イ) 決算額(税込・税抜) */
  readonly businessIncomeNecessaryExpensesSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：必要経費：課税仕入高(イ)ー(ロ) */
  readonly businessIncomeNecessaryExpensesTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：仕入金額（製品製造原価）：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomePurchaseAmountNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：仕入金額（製品製造原価）：(イ) 決算額(税込・税抜) */
  readonly businessIncomePurchaseAmountSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：仕入金額（製品製造原価）：課税仕入高(イ)ー(ロ) */
  readonly businessIncomePurchaseAmountTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomeSubtotalNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：小計：(イ) 決算額(税込・税抜) */
  readonly businessIncomeSubtotalSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 事業所得：小計：課税仕入高(イ)ー(ロ) */
  readonly businessIncomeSubtotalTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：税込・税抜 */
  readonly incomeAccountingMethod: RefundDeclarationDetailAccountingMethod;
  /** 所得：固定資産等の取得価格：(ロ) イのうち課税仕入れにならないもの */
  readonly incomeFixedAssetPriceNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：固定資産等の取得価格：(イ) 決算額(税込・税抜) */
  readonly incomeFixedAssetPriceSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：固定資産等の取得価格：課税仕入高(イ)ー(ロ) */
  readonly incomeFixedAssetPriceTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：項目名 */
  readonly incomeName: Scalars["String"]["output"];
  /** 所得：必要経費：(ロ) イのうち課税仕入れにならないもの */
  readonly incomeNecessaryExpensesNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：必要経費：(イ) 決算額(税込・税抜) */
  readonly incomeNecessaryExpensesSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：必要経費：課税仕入高(イ)ー(ロ) */
  readonly incomeNecessaryExpensesTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：仕入金額：(ロ) イのうち課税仕入れにならないもの */
  readonly incomePurchaseAmountNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：仕入金額：(イ) 決算額(税込・税抜) */
  readonly incomePurchaseAmountSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：仕入金額：課税仕入高(イ)ー(ロ) */
  readonly incomePurchaseAmountTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly incomeSubtotalNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：小計：(イ) 決算額(税込・税抜) */
  readonly incomeSubtotalSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：小計：課税仕入高(イ)ー(ロ) */
  readonly incomeSubtotalTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：課税仕入れ等の税額の合計額：合計額 */
  readonly incomeTaxableIncomeTotalAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 所得：課税仕入れ等の税額の合計額：消費税額 */
  readonly incomeTaxableIncomeTotalConsumptionTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 不動産所得：固定資産等の取得価格：(ロ) イのうち課税仕入れにならないもの */
  readonly realEstateIncomeFixedAssetPriceNonTaxablePurchaseAmount: Maybe<
    Scalars["BigInt"]["output"]
  >;
  /** 不動産所得：固定資産等の取得価格：(イ) 決算額(税込・税抜) */
  readonly realEstateIncomeFixedAssetPriceSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 不動産所得：固定資産等の取得価格：課税仕入高(イ)ー(ロ) */
  readonly realEstateIncomeFixedAssetPriceTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 不動産所得：必要経費：(ロ) イのうち課税仕入れにならないもの */
  readonly realEstateIncomeNecessaryExpensesNonTaxablePurchaseAmount: Maybe<
    Scalars["BigInt"]["output"]
  >;
  /** 不動産所得：必要経費：(イ) 決算額(税込・税抜) */
  readonly realEstateIncomeNecessaryExpensesSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 不動産所得：必要経費：課税仕入高(イ)ー(ロ) */
  readonly realEstateIncomeNecessaryExpensesTaxablePurchaseAmount: Maybe<
    Scalars["BigInt"]["output"]
  >;
  /** 不動産所得：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly realEstateIncomeSubtotalNonTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 不動産所得：小計：(イ) 決算額(税込・税抜) */
  readonly realEstateIncomeSubtotalSettlementAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 不動産所得：小計：課税仕入高(イ)ー(ロ) */
  readonly realEstateIncomeSubtotalTaxablePurchaseAmount: Maybe<Scalars["BigInt"]["output"]>;
};

/** 課税仕入れ等に係る事項：仕入れ金額明細：個人 V1 */
export type RefundDeclarationDetailPurchaseDetailIndividual_V1Input = {
  /** 事業所得：固定資産等の取得価格：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomeFixedAssetPriceNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 事業所得：固定資産等の取得価格：(イ) 決算額(税込・税抜) */
  readonly businessIncomeFixedAssetPriceSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 事業所得：固定資産等の取得価格：課税仕入高(イ)ー(ロ) */
  readonly businessIncomeFixedAssetPriceTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 事業所得：必要経費：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomeNecessaryExpensesNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 事業所得：必要経費：(イ) 決算額(税込・税抜) */
  readonly businessIncomeNecessaryExpensesSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 事業所得：必要経費：課税仕入高(イ)ー(ロ) */
  readonly businessIncomeNecessaryExpensesTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 事業所得：仕入金額（製品製造原価）：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomePurchaseAmountNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 事業所得：仕入金額（製品製造原価）：(イ) 決算額(税込・税抜) */
  readonly businessIncomePurchaseAmountSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 事業所得：仕入金額（製品製造原価）：課税仕入高(イ)ー(ロ) */
  readonly businessIncomePurchaseAmountTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 事業所得：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly businessIncomeSubtotalNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 事業所得：小計：(イ) 決算額(税込・税抜) */
  readonly businessIncomeSubtotalSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 事業所得：小計：課税仕入高(イ)ー(ロ) */
  readonly businessIncomeSubtotalTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：税込・税抜 */
  readonly incomeAccountingMethod: RefundDeclarationDetailAccountingMethod;
  /** 所得：固定資産等の取得価格：(ロ) イのうち課税仕入れにならないもの */
  readonly incomeFixedAssetPriceNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：固定資産等の取得価格：(イ) 決算額(税込・税抜) */
  readonly incomeFixedAssetPriceSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：固定資産等の取得価格：課税仕入高(イ)ー(ロ) */
  readonly incomeFixedAssetPriceTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：項目名 */
  readonly incomeName: Scalars["String"]["input"];
  /** 所得：必要経費：(ロ) イのうち課税仕入れにならないもの */
  readonly incomeNecessaryExpensesNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：必要経費：(イ) 決算額(税込・税抜) */
  readonly incomeNecessaryExpensesSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：必要経費：課税仕入高(イ)ー(ロ) */
  readonly incomeNecessaryExpensesTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：仕入金額：(ロ) イのうち課税仕入れにならないもの */
  readonly incomePurchaseAmountNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：仕入金額：(イ) 決算額(税込・税抜) */
  readonly incomePurchaseAmountSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：仕入金額：課税仕入高(イ)ー(ロ) */
  readonly incomePurchaseAmountTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly incomeSubtotalNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：小計：(イ) 決算額(税込・税抜) */
  readonly incomeSubtotalSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：小計：課税仕入高(イ)ー(ロ) */
  readonly incomeSubtotalTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 所得：課税仕入れ等の税額の合計額：合計額 */
  readonly incomeTaxableIncomeTotalAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 不動産所得：固定資産等の取得価格：(ロ) イのうち課税仕入れにならないもの */
  readonly realEstateIncomeFixedAssetPriceNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 不動産所得：固定資産等の取得価格：(イ) 決算額(税込・税抜) */
  readonly realEstateIncomeFixedAssetPriceSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 不動産所得：固定資産等の取得価格：課税仕入高(イ)ー(ロ) */
  readonly realEstateIncomeFixedAssetPriceTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 不動産所得：必要経費：(ロ) イのうち課税仕入れにならないもの */
  readonly realEstateIncomeNecessaryExpensesNonTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 不動産所得：必要経費：(イ) 決算額(税込・税抜) */
  readonly realEstateIncomeNecessaryExpensesSettlementAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 不動産所得：必要経費：課税仕入高(イ)ー(ロ) */
  readonly realEstateIncomeNecessaryExpensesTaxablePurchaseAmount: InputMaybe<
    Scalars["BigInt"]["input"]
  >;
  /** 不動産所得：小計：(ロ) イのうち課税仕入れにならないもの */
  readonly realEstateIncomeSubtotalNonTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 不動産所得：小計：(イ) 決算額(税込・税抜) */
  readonly realEstateIncomeSubtotalSettlementAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 不動産所得：小計：課税仕入高(イ)ー(ロ) */
  readonly realEstateIncomeSubtotalTaxablePurchaseAmount: InputMaybe<Scalars["BigInt"]["input"]>;
};

/** Parameters for updating PurchaseDetail 課税仕入れ等に係る事項 */
export type RefundDeclarationDetailPurchaseDetailInput = {
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["input"];
  /** Accounting method selected by user */
  readonly purchaseDetailCorporate: InputMaybe<RefundDeclarationDetailPurchaseDetailCorporate_V1_Input>;
  /** List of inputted items */
  readonly purchaseDetailIndividual: InputMaybe<RefundDeclarationDetailPurchaseDetailIndividual_V1Input>;
};

/** 主な固定資産等の取得 */
export type RefundDeclarationDetailPurchaseFixedAsset = {
  readonly __typename: "RefundDeclarationDetailPurchaseFixedAsset";
  /** Accounting method inputted by user */
  readonly accountingMethod: RefundDeclarationDetailAccountingMethod;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["output"];
  /** List of fixed asset items */
  readonly items: ReadonlyArray<Maybe<RefundDeclarationDetailPurchaseFixedAssetItem>>;
};

/** Parameters for saving Purchase Fixed Asset information */
export type RefundDeclarationDetailPurchaseFixedAssetInput = {
  /** Accounting method selected by user */
  readonly accountingMethod: RefundDeclarationDetailAccountingMethod;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["input"];
  /** List of inputted purchase fixed asset items */
  readonly items: ReadonlyArray<InputMaybe<RefundDeclarationDetailPurchaseFixedAssetItemInput>>;
};

/** 主な固定資産等の取得 */
export type RefundDeclarationDetailPurchaseFixedAssetItem = {
  readonly __typename: "RefundDeclarationDetailPurchaseFixedAssetItem";
  /** 資産の種類等 */
  readonly assetType: Scalars["String"]["output"];
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["output"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["output"];
  /** 取引先の登録番号 */
  readonly businessPartnerRegistrationNumber: Scalars["String"]["output"];
  /** 取引金額など */
  readonly transactionAmount: Scalars["BigInt"]["output"];
  /** 譲渡年月日 */
  readonly transferDate: Scalars["Date"]["output"];
};

/** 主な固定資産等の取得 */
export type RefundDeclarationDetailPurchaseFixedAssetItemInput = {
  /** 資産の種類等 */
  readonly assetType: Scalars["String"]["input"];
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["input"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["input"];
  /** 取引先の登録番号 */
  readonly businessPartnerRegistrationNumber: Scalars["String"]["input"];
  /** 取引金額など */
  readonly transactionAmount: Scalars["BigInt"]["input"];
  /** 譲渡年月日 */
  readonly transferDate: Scalars["Date"]["input"];
};

/** 主な棚卸資産・原材料等の取得 */
export type RefundDeclarationDetailPurchaseInventory = {
  readonly __typename: "RefundDeclarationDetailPurchaseInventory";
  /** Accounting method inputted by user */
  readonly accountingMethod: RefundDeclarationDetailAccountingMethod;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["output"];
  /** List of inventory items */
  readonly items: ReadonlyArray<Maybe<RefundDeclarationDetailPurchaseInventoryItem>>;
};

/** Parameters for updating PurchaseInventory (主な棚卸資産・原材料等の取得) */
export type RefundDeclarationDetailPurchaseInventoryInput = {
  /** Accounting method selected by user */
  readonly accountingMethod: RefundDeclarationDetailAccountingMethod;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["input"];
  /** List of inputted items */
  readonly items: ReadonlyArray<InputMaybe<RefundDeclarationDetailPurchaseInventoryItemInput>>;
};

/** 主な棚卸資産・原材料等の取得 */
export type RefundDeclarationDetailPurchaseInventoryItem = {
  readonly __typename: "RefundDeclarationDetailPurchaseInventoryItem";
  /** 資産の種類等 */
  readonly assetType: Scalars["String"]["output"];
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["output"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["output"];
  /** 取引先の登録番号 */
  readonly businessPartnerRegistrationNumber: Scalars["String"]["output"];
  /** 取引金額など */
  readonly transactionAmount: Scalars["BigInt"]["output"];
  /** 継続 */
  readonly transferContinued: Scalars["Boolean"]["output"];
  /** 譲渡年月日 */
  readonly transferDate: Maybe<Scalars["Date"]["output"]>;
};

/** 主な棚卸資産・原材料等の取得 */
export type RefundDeclarationDetailPurchaseInventoryItemInput = {
  /** 資産の種類等 */
  readonly assetType: Scalars["String"]["input"];
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["input"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["input"];
  /** 取引先の登録番号 */
  readonly businessPartnerRegistrationNumber: Scalars["String"]["input"];
  /** 取引金額など */
  readonly transactionAmount: Scalars["BigInt"]["input"];
  /** 譲渡年月日 */
  readonly transferDate: InputMaybe<Scalars["Date"]["input"]>;
};

/** 還付申告となった主な理由 */
export type RefundDeclarationDetailReason = {
  readonly __typename: "RefundDeclarationDetailReason";
  /** 設備投資（高額な固定資産の購入等） */
  readonly reasonCapitalInvestment: Scalars["Boolean"]["output"];
  /** 輸出等の免税商品の割合が高い */
  readonly reasonHighPercentageDutyFree: Scalars["Boolean"]["output"];
  /** その他 */
  readonly reasonOther: Scalars["Boolean"]["output"];
  /** その他のコメント */
  readonly reasonOtherComment: Scalars["String"]["output"];
};

/** 還付申告となった主な理由 */
export type RefundDeclarationDetailReasonInput = {
  /** 設備投資（高額な固定資産の購入等） */
  readonly reasonCapitalInvestment: Scalars["Boolean"]["input"];
  /** 輸出等の免税商品の割合が高い */
  readonly reasonHighPercentageDutyFree: Scalars["Boolean"]["input"];
  /** その他のコメント */
  readonly reasonOtherComment: Scalars["String"]["input"];
};

/** 主な輸出取引等の明細情報 */
export type RefundDeclarationDetailSalesExportTransaction = {
  readonly __typename: "RefundDeclarationDetailSalesExportTransaction";
  /** 輸入取引等に利用する主な金融機関 */
  readonly bankAccountInfo: BankAccountInfo;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["output"];
  /** 輸出取引等に利用する主な通関業者 */
  readonly exportCustomsInfo: RefundDeclarationDetailExportCustomsInfo;
  /** List of detail sales export transaction items */
  readonly items: ReadonlyArray<Maybe<RefundDeclarationDetailSalesExportTransactionItem>>;
};

/** Params for updating Refund Declaration Detail Sales Export Transaction information */
export type RefundDeclarationDetailSalesExportTransactionInput = {
  /** 輸入取引等に利用する主な金融機関 */
  readonly bankAccountInfo: InputMaybe<BankAccountInfoInput>;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["input"];
  /** 輸出取引等に利用する主な通関業者 */
  readonly exportCustomsInfo: InputMaybe<RefundDeclarationDetailExportCustomsInfoInput>;
  /** 主な輸出取引等の明細 */
  readonly items: ReadonlyArray<InputMaybe<RefundDeclarationDetailSalesExportTransactionItemInput>>;
};

/** 主な輸出取引等の明細一覧 */
export type RefundDeclarationDetailSalesExportTransactionItem = {
  readonly __typename: "RefundDeclarationDetailSalesExportTransactionItem";
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["output"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["output"];
  /** 所管税関（支署）名 */
  readonly customsJurisdiction: Scalars["String"]["output"];
  /** 主な取引商品等 */
  readonly mainTradingProducts: Scalars["String"]["output"];
  /** 取引金額 */
  readonly transactionAmount: Scalars["BigInt"]["output"];
};

/** 主な輸出取引等の明細 */
export type RefundDeclarationDetailSalesExportTransactionItemInput = {
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["input"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["input"];
  /** 所管税関（支署）名 */
  readonly customsJurisdiction: Scalars["String"]["input"];
  /** 主な取引商品等 */
  readonly mainTradingProducts: Scalars["String"]["input"];
  /** 取引金額 */
  readonly transactionAmount: Scalars["BigInt"]["input"];
};

/** 主な課税資産の譲渡等 */
export type RefundDeclarationDetailSalesTaxableAssetTransfer = {
  readonly __typename: "RefundDeclarationDetailSalesTaxableAssetTransfer";
  /** Accounting method inputted by user */
  readonly accountingMethod: RefundDeclarationDetailAccountingMethod;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["output"];
  /** List of taxable asset transfer items */
  readonly items: ReadonlyArray<Maybe<RefundDeclarationDetailSalesTaxableAssetTransferItem>>;
};

/** 主な課税資産の譲渡等 */
export type RefundDeclarationDetailSalesTaxableAssetTransferInput = {
  /** Accounting Method */
  readonly accountingMethod: RefundDeclarationDetailAccountingMethod;
  /** Exists or not */
  readonly exists: Scalars["Boolean"]["input"];
  /** List of inputted items */
  readonly items: ReadonlyArray<
    InputMaybe<RefundDeclarationDetailSalesTaxableAssetTransferItemInput>
  >;
};

/** 主な課税資産の譲渡等 */
export type RefundDeclarationDetailSalesTaxableAssetTransferItem = {
  readonly __typename: "RefundDeclarationDetailSalesTaxableAssetTransferItem";
  /** 資産の種類等 */
  readonly assetType: Scalars["String"]["output"];
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["output"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["output"];
  /** 取引金額など */
  readonly transactionAmount: Scalars["BigInt"]["output"];
  /** 継続 */
  readonly transferContinued: Scalars["Boolean"]["output"];
  /** 譲渡年月日 */
  readonly transferDate: Maybe<Scalars["Date"]["output"]>;
};

/** 主な課税資産の譲渡等 */
export type RefundDeclarationDetailSalesTaxableAssetTransferItemInput = {
  /** 資産の種類等 */
  readonly assetType: Scalars["String"]["input"];
  /** 取引先の住所（所在地） */
  readonly businessPartnerAddress: Scalars["String"]["input"];
  /** 取引先の氏名（名称） */
  readonly businessPartnerName: Scalars["String"]["input"];
  /** 取引金額など */
  readonly transactionAmount: Scalars["BigInt"]["input"];
  /** 譲渡年月日 */
  readonly transferDate: InputMaybe<Scalars["Date"]["input"]>;
};

/** 還付申告明細書 V1の更新入力 */
export type RefundDeclarationDetailV1Input = {
  /** 課税仕入れ等に係る事項 */
  readonly purchaseDetail: InputMaybe<RefundDeclarationDetailPurchaseDetailInput>;
  /** 主な固定資産等の取得 */
  readonly purchaseFixedAsset: InputMaybe<RefundDeclarationDetailPurchaseFixedAssetInput>;
  /** 主な棚卸資産・原材料等の取得 */
  readonly purchaseInventory: InputMaybe<RefundDeclarationDetailPurchaseInventoryInput>;
  /** 還付申告となった主な理由 */
  readonly reason: InputMaybe<RefundDeclarationDetailReasonInput>;
  /** 主な輸出取引等の明細 */
  readonly salesExportTransaction: InputMaybe<RefundDeclarationDetailSalesExportTransactionInput>;
  /** 主な課税資産の譲渡等 */
  readonly salesTaxableAssetTransfer: InputMaybe<RefundDeclarationDetailSalesTaxableAssetTransferInput>;
  /** この会計期間の特殊事項 */
  readonly specialMatter: InputMaybe<Scalars["String"]["input"]>;
};

/** 還付申告明細書 V1 */
export type RefundDeclarationDetail_V1 = Node & {
  readonly __typename: "RefundDeclarationDetail_V1";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 課税仕入れ等に係る事項 */
  readonly purchaseDetail: RefundDeclarationDetailPurchaseDetail;
  /** 主な固定資産等の取得 */
  readonly purchaseFixedAsset: RefundDeclarationDetailPurchaseFixedAsset;
  /** 主な棚卸資産・原材料等の取得 */
  readonly purchaseInventory: RefundDeclarationDetailPurchaseInventory;
  /** 還付申告となった主な理由 */
  readonly reason: RefundDeclarationDetailReason;
  /** 主な輸出取引等の明細 */
  readonly salesExportTransaction: RefundDeclarationDetailSalesExportTransaction;
  /** 主な課税資産の譲渡等 */
  readonly salesTaxableAssetTransfer: RefundDeclarationDetailSalesTaxableAssetTransfer;
  /** この会計期間の特殊事項 */
  readonly specialMatter: Scalars["String"]["output"];
};

/** XTXダウンロード可能かどうかの判定 */
export type RefundDeclarationDetail_V1_Submittable = {
  readonly __typename: "RefundDeclarationDetail_V1_Submittable";
  /** 「還付申告となった主な理由」を入力しましょう。 */
  readonly isEmptyRefundReason: Scalars["Boolean"]["output"];
};

/** User settings for 該当あり/該当なし of each item list */
export type RefundDeclarationItemListSetting = {
  readonly __typename: "RefundDeclarationItemListSetting";
  /** ３ 課税仕入れに係る事項 (1) 仕入金額等の明細の該当あり・なし */
  readonly existsPurchaseDetail: Scalars["Boolean"]["output"];
  /** (3) 主な固定資産等の取得の該当あり・なし */
  readonly existsPurchaseFixedAsset: Scalars["Boolean"]["output"];
  /** (2) 主な棚卸資産・原材料等の取得の該当あり・なし */
  readonly existsPurchaseInventory: Scalars["Boolean"]["output"];
  /** ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細の該当あり・なし */
  readonly existsSalesExportTransaction: Scalars["Boolean"]["output"];
  /** ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等の該当あり・なし */
  readonly existsSalesTaxableAssetTransfer: Scalars["Boolean"]["output"];
};

/** 消費税及び地方消費税申告(一般・個人) v20_0_0 */
export type Rsh0010_V20_0_0 = Node & {
  readonly __typename: "Rsh0010_V20_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(一般) */
  readonly sha010: Maybe<Sha010_V8_0>;
  /** 付表1-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb013: Maybe<Shb013_V2_0>;
  /** 付表1-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用 */
  readonly shb015: Maybe<Shb015_V4_0>;
  /** 付表1-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
  readonly shb017: Maybe<Shb017_V1_0>;
  /** 付表2-1　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb025: Maybe<Shb025_V2_0>;
  /** 付表2-2　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】V6 */
  readonly shb030: Maybe<Shb030_V6_0>;
  /** 付表2-3　課税売上割合・控除対象仕入税額等の計算表 */
  readonly shb033: Maybe<Shb033_V1_0>;
  /** 消費税の還付申告に関する明細書(個人事業者用) */
  readonly shc025: Maybe<Shc025_V1_0>;
};

/** 消費税及び地方消費税申告(一般・個人) v23_0_0 */
export type Rsh0010_V23_0_0 = Node & {
  readonly __typename: "Rsh0010_V23_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(一般) */
  readonly sha010: Maybe<Sha010_V10_0>;
  /** 付表1-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb013: Maybe<Shb013_V3_0>;
  /** 付表1-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用 */
  readonly shb015: Maybe<Shb015_V5_0>;
  /** 付表1-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
  readonly shb017: Maybe<Shb017_V2_0>;
  /** 付表2-1　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb025: Maybe<Shb025_V3_0>;
  /** 付表2-2　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】V6 */
  readonly shb030: Maybe<Shb030_V7_0>;
  /** 付表2-3　課税売上割合・控除対象仕入税額等の計算表 */
  readonly shb033: Maybe<Shb033_V2_0>;
  /** 付表6 税率別消費税額計算表〔小規模事業者に係る税額控除に関する経過措置を適用する課税期間用〕 */
  readonly shb070: Maybe<Shb070_V1_0>;
  /** 消費税の還付申告に関する明細書(個人事業者用) */
  readonly shc025: Maybe<Shc025_V2_0>;
};

/** 消費税及び地方消費税申告(一般・法人) v20_0_0 */
export type Rsh0020_V20_0_0 = Node & {
  readonly __typename: "Rsh0020_V20_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(一般) */
  readonly sha010: Maybe<Sha010_V8_0>;
  /** 付表1-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb013: Maybe<Shb013_V2_0>;
  /** 付表1-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用 */
  readonly shb015: Maybe<Shb015_V4_0>;
  /** 付表1-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
  readonly shb017: Maybe<Shb017_V1_0>;
  /** 付表2-1　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb025: Maybe<Shb025_V2_0>;
  /** 付表2-2　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】V6 */
  readonly shb030: Maybe<Shb030_V6_0>;
  /** 付表2-3　課税売上割合・控除対象仕入税額等の計算表 */
  readonly shb033: Maybe<Shb033_V1_0>;
  /** 消費税の還付申告に関する明細書(法人用) */
  readonly shc015: Maybe<Shc015_V1_0>;
};

/** 消費税及び地方消費税申告(一般・法人) v23_0_0 */
export type Rsh0020_V23_0_0 = Node & {
  readonly __typename: "Rsh0020_V23_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(一般) */
  readonly sha010: Maybe<Sha010_V10_0>;
  /** 付表1-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb013: Maybe<Shb013_V3_0>;
  /** 付表1-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用 */
  readonly shb015: Maybe<Shb015_V5_0>;
  /** 付表1-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
  readonly shb017: Maybe<Shb017_V2_0>;
  /** 付表2-1　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb025: Maybe<Shb025_V3_0>;
  /** 付表2-2　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】V6 */
  readonly shb030: Maybe<Shb030_V7_0>;
  /** 付表2-3　課税売上割合・控除対象仕入税額等の計算表 */
  readonly shb033: Maybe<Shb033_V2_0>;
  /** 付表6 税率別消費税額計算表〔小規模事業者に係る税額控除に関する経過措置を適用する課税期間用〕 */
  readonly shb070: Maybe<Shb070_V1_0>;
  /** 消費税の還付申告に関する明細書(法人用) */
  readonly shc015: Maybe<Shc015_V2_0>;
};

/** 消費税及び地方消費税申告(簡易課税・個人) v20_0_0 */
export type Rsh0030_V20_0_0 = Node & {
  readonly __typename: "Rsh0030_V20_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(簡易課税) */
  readonly sha020: Maybe<Sha020_V7_0>;
  /** 付表4-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb043: Maybe<Shb043_V2_0>;
  /** 付表4-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb045: Maybe<Shb045_V3_0>;
  /** 付表4-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 v1_0 */
  readonly shb047: Maybe<Shb047_V1_0>;
  /** 付表5-1　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb055: Maybe<Shb055_V2_0>;
  /** 付表5-2　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb065: Maybe<Shb065_V3_0>;
  /** 付表5-3　控除対象仕入税額等の計算表 */
  readonly shb067: Maybe<Shb067_V1_0>;
};

/** 消費税及び地方消費税申告(簡易課税・個人) v23_0_0 */
export type Rsh0030_V23_0_0 = Node & {
  readonly __typename: "Rsh0030_V23_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(簡易課税) */
  readonly sha020: Maybe<Sha020_V9_0>;
  /** 付表4-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb043: Maybe<Shb043_V2_0>;
  /** 付表4-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb045: Maybe<Shb045_V3_0>;
  /** 付表4-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 v1_0 */
  readonly shb047: Maybe<Shb047_V1_0>;
  /** 付表5-1　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb055: Maybe<Shb055_V2_0>;
  /** 付表5-2　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb065: Maybe<Shb065_V3_0>;
  /** 付表5-3　控除対象仕入税額等の計算表 */
  readonly shb067: Maybe<Shb067_V1_0>;
  /** 付表6 税率別消費税額計算表〔小規模事業者に係る税額控除に関する経過措置を適用する課税期間用〕 */
  readonly shb070: Maybe<Shb070_V1_0>;
};

/** 消費税及び地方消費税申告(簡易課税・法人) v20_0_0 */
export type Rsh0040_V20_0_0 = Node & {
  readonly __typename: "Rsh0040_V20_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(簡易課税) */
  readonly sha020: Maybe<Sha020_V7_0>;
  /** 付表4-1　付表4-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb043: Maybe<Shb043_V2_0>;
  /** 付表4-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb045: Maybe<Shb045_V3_0>;
  /** 付表4-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 v1_0 */
  readonly shb047: Maybe<Shb047_V1_0>;
  /** 付表5-1　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb055: Maybe<Shb055_V2_0>;
  /** 付表5-2　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb065: Maybe<Shb065_V3_0>;
  /** 付表5-3　控除対象仕入税額等の計算表 */
  readonly shb067: Maybe<Shb067_V1_0>;
};

/** 消費税及び地方消費税申告(簡易課税・法人) v23_0_0 */
export type Rsh0040_V23_0_0 = Node & {
  readonly __typename: "Rsh0040_V23_0_0";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 消費税及び地方消費税申告(簡易課税) */
  readonly sha020: Maybe<Sha020_V9_0>;
  /** 付表4-1　付表4-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb043: Maybe<Shb043_V2_0>;
  /** 付表4-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb045: Maybe<Shb045_V3_0>;
  /** 付表4-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 v1_0 */
  readonly shb047: Maybe<Shb047_V1_0>;
  /** 付表5-1　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb055: Maybe<Shb055_V2_0>;
  /** 付表5-2　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
  readonly shb065: Maybe<Shb065_V3_0>;
  /** 付表5-3　控除対象仕入税額等の計算表 */
  readonly shb067: Maybe<Shb067_V1_0>;
  /** 付表6 税率別消費税額計算表〔小規模事業者に係る税額控除に関する経過措置を適用する課税期間用〕 */
  readonly shb070: Maybe<Shb070_V1_0>;
};

/** Parameters for saving web access log */
export type SaveWebAccessLogInput = {
  /** アクセス日時 */
  readonly accessedAt: Scalars["DateTime"]["input"];
  /** ユーザーのIPアドレス */
  readonly ipAddress: Scalars["String"]["input"];
  /** アクセス先パス */
  readonly path: Scalars["String"]["input"];
  /** アクセス前のパス */
  readonly referer: Scalars["String"]["input"];
  /** ブラウザ情報 */
  readonly userAgent: Scalars["String"]["input"];
};

/** 消費税及び地方消費税の申告書(一般用) V8_0 */
export type Sha010_V8_0 = Node & {
  readonly __typename: "Sha010_V8_0";
  /**
   * 提出年月日
   * TEISYUTSU_DAY
   */
  readonly aai00010: Maybe<Scalars["Date"]["output"]>;
  /**
   * 税務署区分
   * ZEIMUSHO_CD
   */
  readonly aai00020Code: Maybe<Scalars["String"]["output"]>;
  /**
   * 税務署名
   * ZEIMUSHO_KB
   */
  readonly aai00020Name: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税地
   * NOZEISHA_ADR
   */
  readonly aai00030: Maybe<Scalars["String"]["output"]>;
  /**
   * 電話番号（第一表）
   * NOZEISHA_TEL1
   * NOZEISHA_TEL2
   * NOZEISHA_TEL3
   */
  readonly aai00040: Maybe<TelephoneNumber>;
  /**
   * 名称又は屋号（フリガナ）（第一表）
   * 法人: NOZEISHA_NM_KN
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly aai00060: Maybe<Scalars["String"]["output"]>;
  /**
   * 名称又は屋号（第一表）
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO
   */
  readonly aai00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 個人番号又は法人番号
   * NOZESISHA_BANGO
   */
  readonly aai00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（フリガナ）（第一表）
   * 法人: DAIHYO_NM_KN
   * 個人: NOZEISHA_NM_KN
   */
  readonly aai00100: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（第一表）
   * 法人: DAIHYO_NM
   * 個人: NOZEISHA_NM
   */
  readonly aai00110: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税期間（自）（第一表）
   * KAZEI_KIKAN_FROM
   */
  readonly aai00130: Scalars["Date"]["output"];
  /**
   * 課税期間（至）（第一表）
   * KAZEI_KIKAN_TO
   */
  readonly aai00140: Scalars["Date"]["output"];
  /** 申告区分 */
  readonly aai00150: DeclarationType;
  /** 中間申告の場合の対象期間（自）（第一表） */
  readonly aai00170: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の場合の対象期間（至）（第一表） */
  readonly aai00180: Maybe<Scalars["Date"]["output"]>;
  /** 還付を受けようとする金融機関等 */
  readonly aai00190: Maybe<BankAccountInfo>;
  /**
   * 税理士署名
   * DAIRI_NM
   */
  readonly aai00210: Maybe<Scalars["String"]["output"]>;
  /**
   * 税理士電話番号
   * DAIRI_TEL1
   * DAIRI_TEL2
   * DAIRI_TEL3
   */
  readonly aai00220: Maybe<TelephoneNumber>;
  /**
   * 税理士法第30条の書面提出有
   * True: 有
   * False: 無
   */
  readonly aai00230: Scalars["Boolean"]["output"];
  /**
   * 税理士法第33条の２の書面提出有
   * True: 有
   * False: 無
   */
  readonly aai00240: Scalars["Boolean"]["output"];
  /** （消費税）課税標準額 */
  readonly aaj00010: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）消費税額 */
  readonly aaj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）控除過大調整税額 */
  readonly aaj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）控除対象仕入税額 */
  readonly aaj00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）返還等対価に係る税額 */
  readonly aaj00060: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）貸倒れに係る税額 */
  readonly aaj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）控除税額小計 */
  readonly aaj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）控除不足還付税額 */
  readonly aaj00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）差引税額 */
  readonly aaj00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）中間納付税額 */
  readonly aaj00110: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）納付税額 */
  readonly aaj00120: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）中間納付還付税額 */
  readonly aaj00130: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）既確定税額 */
  readonly aaj00150: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）差引納付税額 */
  readonly aaj00160: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）課税資産の譲渡等の対価の額 */
  readonly aaj00180: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税）資産の譲渡等の対価の額 */
  readonly aaj00190: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）控除不足還付税額 */
  readonly aak00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）差引税額 */
  readonly aak00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）還付額 */
  readonly aak00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）納税額 */
  readonly aak00060: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）中間納付譲渡割額 */
  readonly aak00070: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）納付譲渡割額 */
  readonly aak00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）中間納付還付譲渡割額 */
  readonly aak00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）既確定譲渡割額 */
  readonly aak00110: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）差引納付譲渡割額 */
  readonly aak00120: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）消費税及び地方消費税の合計（納付又は還付）税額 */
  readonly aak00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 割賦基準の適用
   * True:有 False:無
   */
  readonly aal00010: Scalars["Boolean"]["output"];
  /**
   * 延払基準等の適用
   * True:有 False:無
   */
  readonly aal00020: Scalars["Boolean"]["output"];
  /**
   * 工事進行基準の適用
   * True:有 False:無
   */
  readonly aal00030: Scalars["Boolean"]["output"];
  /**
   * 現金主義会計の適用
   * True:有 False:無
   */
  readonly aal00040: Scalars["Boolean"]["output"];
  /**
   * 課税標準額に対する消費税額の計算の特例の適用
   * True:有 False:無
   */
  readonly aam00010: Scalars["Boolean"]["output"];
  /** 控除税額の計算方法 */
  readonly aam00020: Maybe<DeductionCalculation>;
  /** 基準期間の課税売上高 */
  readonly aam00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 納税地
   * NOZEISHA_ADR
   */
  readonly aan00010: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 電話番号
   * NOZEISHA_TEL
   */
  readonly aan00020: Maybe<TelephoneNumber>;
  /**
   * 納税者等部（第二表）
   * 名称又は屋号（フリガナ）
   * 法人: NOZEISHA_NM_KN
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly aan00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 名称又は屋号
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly aan00050: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 代表者氏名又は氏名（フリガナ）
   * 法人: DAIHYO_NM_KN
   * 個人: NOZEISHA_NM_KN
   */
  readonly aan00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 代表者氏名又は氏名
   * 法人: DAIHYO_NM
   * 個人: NOZEISHA_NM
   */
  readonly aan00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly aan00100: Scalars["Date"]["output"];
  /**
   * 納税者等部（第二表）
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly aan00110: Scalars["Date"]["output"];
  /**
   * 納税者等部（第二表）
   * 申告区分
   * SHINKOKU_KBN
   */
  readonly aan00120: DeclarationType;
  /** 中間申告の対象期間の開始日（自）（第二表） */
  readonly aan00140: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の対象期間の終了日（至）（第二表） */
  readonly aan00150: Maybe<Scalars["Date"]["output"]>;
  /**
   * 軽減売上割合（10営業日）
   * True:該当 False:非該当
   */
  readonly aao00010: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 小売等軽減仕入割合
   * True:該当 False:非該当
   */
  readonly aao00020: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 小売等軽減売上割合
   * True:該当 False:非該当
   */
  readonly aao00030: Maybe<Scalars["Boolean"]["output"]>;
  /** 課税標準額 */
  readonly aap00000: Maybe<Scalars["BigInt"]["output"]>;
  /** （課税資産の譲渡等の対価の額）３％適用分 */
  readonly aaq00010: Maybe<Scalars["BigInt"]["output"]>;
  /** （課税資産の譲渡等の対価の額）４％適用分 */
  readonly aaq00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （課税資産の譲渡等の対価の額）６.３％適用分 */
  readonly aaq00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （課税資産の譲渡等の対価の額）６.２４％適用分 */
  readonly aaq00040: Maybe<Scalars["BigInt"]["output"]>;
  /** （課税資産の譲渡等の対価の額）７.８％適用分 */
  readonly aaq00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （課税資産の譲渡等の対価の額）合計額 */
  readonly aaq00060: Maybe<Scalars["BigInt"]["output"]>;
  /** （特定課税仕入れに係る支払対価の額）６.３％適用分 */
  readonly aar00010: Maybe<Scalars["BigInt"]["output"]>;
  /** （特定課税仕入れに係る支払対価の額）７.８％適用分 */
  readonly aar00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （特定課税仕入れに係る支払対価の額）合計額 */
  readonly aar00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額（第二表） */
  readonly aas00000: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税額）３％適用分 */
  readonly aat00010: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税額）４％適用分 */
  readonly aat00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税額）６.３％適用分 */
  readonly aat00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税額）６.２４％適用分 */
  readonly aat00040: Maybe<Scalars["BigInt"]["output"]>;
  /** （消費税額）７.８％適用分 */
  readonly aat00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 還等対価に係る税額 */
  readonly aau00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 上げの返還等対価に係る税額 */
  readonly aav00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 定課税仕入れの返還等対価に係る税額 */
  readonly aav00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税の課税標準）消費税額 */
  readonly aaw00010: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税の課税標準）４％適用分 */
  readonly aaw00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税の課税標準）６.３％適用分 */
  readonly aaw00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税の課税標準）６.２４％及び７.８％適用分 */
  readonly aaw00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税及び地方消費税の申告書(一般用) */
export type Sha010_V10_0 = Node & {
  readonly __typename: "Sha010_V10_0";
  /** 提出年月日 */
  readonly aai00010: Maybe<Scalars["Date"]["output"]>;
  /**
   * 提出先税務署 - 税務署区分
   * ZEIMUSHO
   */
  readonly aai00020Code: Maybe<Scalars["String"]["output"]>;
  /**
   * 税務署名
   * ZEIMUSHO_KB
   */
  readonly aai00020Name: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税地（第一表）
   * NOZEISHA_ADR
   */
  readonly aai00030: Maybe<Scalars["String"]["output"]>;
  /**
   * 電話番号（第一表）
   * NOZEISHA_TEL1
   * NOZEISHA_TEL2
   * NOZEISHA_TEL3
   */
  readonly aai00040: Maybe<TelephoneNumber>;
  /**
   * 名称又は屋号（フリガナ）（第一表）
   * 法人: NOZEISHA_NM_KN
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly aai00060: Maybe<Scalars["String"]["output"]>;
  /**
   * 名称又は屋号（第一表）
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO
   */
  readonly aai00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 個人番号又は法人番号
   * NOZESISHA_BANGO
   */
  readonly aai00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（フリガナ）（第一表）
   * 法人: DAIHYO_NM_KN
   * 個人: NOZEISHA_NM_KN
   */
  readonly aai00100: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（第一表）
   * 法人: DAIHYO_NM
   * 個人: NOZEISHA_NM
   */
  readonly aai00110: Maybe<Scalars["String"]["output"]>;
  /**
   * 振替継続希望区分
   * 1:該当 2:非該当
   * 手続きIDがRSH0020の場合、入力不可
   */
  readonly aai00115: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 課税期間（自）（第一表）
   * KAZEI_KIKAN_FROM
   */
  readonly aai00130: Scalars["Date"]["output"];
  /**
   * 課税期間（至）（第一表）
   * KAZEI_KIKAN_TO
   */
  readonly aai00140: Scalars["Date"]["output"];
  /**
   * 申告区分
   * SHINKOKU_KBN
   */
  readonly aai00150: DeclarationType;
  /** 中間申告の場合の対象期間（自）（第一表） */
  readonly aai00170: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の場合の対象期間（至）（第一表） */
  readonly aai00180: Maybe<Scalars["Date"]["output"]>;
  /** 還付を受けようとする金融機関等 */
  readonly aai00190: Maybe<BankAccountInfo>;
  /**
   * 税理士署名
   * DAIRI_NM
   */
  readonly aai00210: Maybe<Scalars["String"]["output"]>;
  /**
   * 税理士電話番号
   * DAIRI_TEL1
   * DAIRI_TEL2
   * DAIRI_TEL3
   */
  readonly aai00220: Maybe<TelephoneNumber>;
  /**
   * 税理士法第30条の書面提出有
   * 1:有  2:無
   */
  readonly aai00230: Scalars["Boolean"]["output"];
  /**
   * 税理士法第33条の２の書面提出有
   * 1:有 2:無
   */
  readonly aai00240: Scalars["Boolean"]["output"];
  /**
   * 口座情報利用同意区分
   * 1:該当 2:非該当
   * 手続きIDがRSH0020の場合、入力不可 「口座情報利用同意区分」が1：該当の場合、「個人番号又は法人番号」は必須とする
   */
  readonly aai00250: Maybe<Scalars["Boolean"]["output"]>;
  /** (消費税額) 課税標準額（第一表） */
  readonly aaj00010: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 消費税額（第一表） */
  readonly aaj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 控除過大調整税額 */
  readonly aaj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 控除税額 控除対象仕入税額 */
  readonly aaj00050: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 控除税額 返還等対価に係る税額 */
  readonly aaj00060: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 控除税額 貸倒れに係る税額 */
  readonly aaj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 控除税額 控除税額小計 */
  readonly aaj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 控除不足還付税額 */
  readonly aaj00090: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 差引税額 */
  readonly aaj00100: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 中間納付税額 */
  readonly aaj00110: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 納付税額 */
  readonly aaj00120: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 中間納付還付税額 */
  readonly aaj00130: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) この申告書が修正申告である場合 既確定税額 */
  readonly aaj00150: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) この申告書が修正申告である場合 差引納付税額 */
  readonly aaj00160: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 課税売上割合 課税資産の譲渡等の対価の額 */
  readonly aaj00180: Maybe<Scalars["BigInt"]["output"]>;
  /** (消費税額) 課税売上割合 資産の譲渡等の対価の額 */
  readonly aaj00190: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）地方消費税の課税標準となる消費税額 控除不足還付税額 */
  readonly aak00020: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）地方消費税の課税標準となる消費税額 差引税額 */
  readonly aak00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）譲渡割額 還付額 */
  readonly aak00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）譲渡割額 納税額 */
  readonly aak00060: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）中間納付譲渡割額 */
  readonly aak00070: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）納付譲渡割額 */
  readonly aak00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）中間納付還付譲渡割額 */
  readonly aak00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）この申告書が修正申告である場合 既確定譲渡割額 */
  readonly aak00110: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）この申告書が修正申告である場合 差引納付譲渡割額 */
  readonly aak00120: Maybe<Scalars["BigInt"]["output"]>;
  /** （地方消費税）消費税及び地方消費税の合計（納付又は還付）税額 */
  readonly aak00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 付記事項 割賦基準の適用
   * True:有 False:無
   */
  readonly aal00010: Scalars["Boolean"]["output"];
  /**
   * 付記事項 延払基準等の適用
   * True:有 False:無
   */
  readonly aal00020: Scalars["Boolean"]["output"];
  /**
   * 付記事項 工事進行基準の適用
   * True:有 False:無
   */
  readonly aal00030: Scalars["Boolean"]["output"];
  /**
   * 付記事項 現金主義会計の適用
   * True:有 False:無
   */
  readonly aal00040: Scalars["Boolean"]["output"];
  /**
   * 参考事項 課税標準額に対する消費税額の計算の特例の適用
   * True:有 False:無
   */
  readonly aam00010: Scalars["Boolean"]["output"];
  /**
   * 参考事項 控除税額の計算方法
   * 1:個別対応方式 2:一括比例配分方式 3:全額控除 8:税額控除に係る経過措置の適用（２割特例）
   */
  readonly aam00020: Maybe<DeductionCalculation>;
  /**
   * 参考事項 基準期間の課税売上高
   * （千円）
   */
  readonly aam00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 納税地
   * NOZEISHA_ADR
   */
  readonly aan00010: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 電話番号
   * NOZEISHA_TEL
   */
  readonly aan00020: Maybe<TelephoneNumber>;
  /**
   * 納税者等部（第二表）
   * 名称又は屋号（フリガナ）
   * 法人: NOZEISHA_NM_KN
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly aan00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 名称又は屋号
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly aan00050: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 代表者氏名又は氏名（フリガナ）
   * 法人: DAIHYO_NM_KN
   * 個人: NOZEISHA_NM_KN
   */
  readonly aan00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 代表者氏名又は氏名
   * 法人: DAIHYO_NM
   * 個人: NOZEISHA_NM
   */
  readonly aan00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税者等部（第二表）
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly aan00100: Scalars["Date"]["output"];
  /**
   * 納税者等部（第二表）
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly aan00110: Scalars["Date"]["output"];
  /**
   * 納税者等部（第二表）
   * 申告区分
   * SHINKOKU_KBN
   */
  readonly aan00120: DeclarationType;
  /** 中間申告の対象期間の開始日（自）（第二表） */
  readonly aan00140: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の対象期間の終了日（至）（第二表） */
  readonly aan00150: Maybe<Scalars["Date"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 軽減売上割合（10営業日）
   * True:該当 False:非該当
   */
  readonly aao00010: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 小売等軽減仕入割合
   * True:該当 False:非該当
   */
  readonly aao00020: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 課税標準額（第二表）
   * 千円未満切捨て
   */
  readonly aap00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 ３％適用分 */
  readonly aaq00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 ４％適用分 */
  readonly aaq00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 6.3％適用分 */
  readonly aaq00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 6.24％適用分 */
  readonly aaq00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 7.8％適用分 */
  readonly aaq00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 課税資産の譲渡等の対価の額の合計額 */
  readonly aaq00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 6.3％適用分 */
  readonly aar00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 7.8％適用分 */
  readonly aar00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 特定課税仕入れに係る支払対価の額の合計額 */
  readonly aar00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額（第二表） */
  readonly aas00000: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 ３％適用分 */
  readonly aat00010: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 ４％適用分 */
  readonly aat00020: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 6.3％適用分 */
  readonly aat00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 6.24％適用分 */
  readonly aat00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 7.8％適用分 */
  readonly aat00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 返還等対価に係る税額 */
  readonly aau00000: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)の内訳 売上げの返還等対価に係る税額 */
  readonly aav00010: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)の内訳 特定課税仕入れの返還等対価に係る税額 */
  readonly aav00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 地方消費税の課税標準となる消費税額 */
  readonly aaw00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 ４％適用分 */
  readonly aaw00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 6.3％適用分 */
  readonly aaw00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 6.24％及び7.8％適用分 */
  readonly aaw00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 通知希望区分（加算税）
   * True:該当 False:非該当
   */
  readonly aax00000: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 通知希望区分（還付金振込）
   * True:該当 False:非該当
   * 通知希望区分（還付金振込）は適用開始(令和5年6月19日)から使用可能
   */
  readonly aay00000: Maybe<Scalars["Boolean"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税及び地方消費税の申告書(簡易課税用) V7_0 */
export type Sha020_V7_0 = Node & {
  readonly __typename: "Sha020_V7_0";
  /**
   * 提出年月日
   * TEISYUTSU_DAY
   */
  readonly abh00010: Maybe<Scalars["Date"]["output"]>;
  /**
   * 提出先税務署コード
   * ZEIMUSHO
   */
  readonly abh00020Code: Maybe<Scalars["String"]["output"]>;
  /**
   * 提出先税務署
   * ZEIMUSHO
   */
  readonly abh00020Name: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税地（第一表）
   * NOZEISHA_ADR
   */
  readonly abh00030: Maybe<Scalars["String"]["output"]>;
  /**
   * 電話番号（第一表） 市外局番
   * 入力文字数分のみ表示
   * NOZEISHA_TEL1
   * NOZEISHA_TEL2
   * NOZEISHA_TEL3
   */
  readonly abh00040: Maybe<TelephoneNumber>;
  /**
   * 名称又は屋号（フリガナ）（第一表）
   * カタカナ
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO
   */
  readonly abh00060: Maybe<Scalars["String"]["output"]>;
  /**
   * 名称又は屋号（第一表）
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO
   */
  readonly abh00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 個人番号又は法人番号
   * NOZEISHA_BANGO
   */
  readonly abh00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（フリガナ）（第一表）
   * カタカナ
   * 法人: DAIHYO_NM_KN
   * 個人: NOZEISHA_NM_KN
   */
  readonly abh00100: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（第一表）
   * 法人: DAIHYO_NM
   * 個人: NOZEISHA_NM
   */
  readonly abh00110: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税期間（自）（第一表）
   * KAZEI_KIKAN_FROM
   */
  readonly abh00130: Scalars["Date"]["output"];
  /**
   * 課税期間（至）（第一表）
   * KAZEI_KIKAN_TO
   */
  readonly abh00140: Scalars["Date"]["output"];
  /**
   * 申告の種類（第一表）
   * 1:確定 2:中間 3:修正確定 4:修正中間
   * SHINKOKU_KBN
   */
  readonly abh00150: DeclarationType;
  /** 中間申告の場合の対象期間（自）（第一表） */
  readonly abh00170: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の場合の対象期間（至）（第一表） */
  readonly abh00180: Maybe<Scalars["Date"]["output"]>;
  /** 還付を受けようとする金融機関等 */
  readonly abh00190: Maybe<BankAccountInfo>;
  /**
   * 税理士署名
   * DAIRI_NM
   */
  readonly abh00210: Maybe<Scalars["String"]["output"]>;
  /**
   * 税理士電話番号 市外局番
   * DAIRI_TEL1
   * DAIRI_TEL2
   * DAIRI_TEL3
   */
  readonly abh00220: Maybe<TelephoneNumber>;
  /**
   * 税理士法第30条の書面提出有
   * 1:有 2:無
   */
  readonly abh00230: Scalars["Boolean"]["output"];
  /**
   * 税理士法第33条の２の書面提出有
   * 1:有 2:無
   */
  readonly abh00240: Scalars["Boolean"]["output"];
  /**
   * 課税標準額（第一表）
   * 千円未満切捨て
   */
  readonly abi00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額（第一表） */
  readonly abi00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 */
  readonly abi00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除対象仕入税額 */
  readonly abi00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 返還等対価に係る税額 */
  readonly abi00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 貸倒れに係る税額 */
  readonly abi00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除税額小計 */
  readonly abi00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 */
  readonly abi00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 */
  readonly abi00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 中間納付税額
   * 百円未満切捨て
   */
  readonly abi00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 納付税額 */
  readonly abi00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付還付税額 */
  readonly abi00130: Maybe<Scalars["BigInt"]["output"]>;
  /** この申告書が修正申告である場合 既確定税額 */
  readonly abi00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * この申告書が修正申告である場合 差引納付税額
   * 百円未満切捨て
   */
  readonly abi00160: Maybe<Scalars["BigInt"]["output"]>;
  /** この課税期間の課税売上高 */
  readonly abi00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 基準期間の課税売上高 */
  readonly abi00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 控除不足還付税額 */
  readonly abj00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額 差引税額
   * 百円未満切捨て
   */
  readonly abj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額 還付額 */
  readonly abj00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額 納税額
   * 百円未満切捨て
   */
  readonly abj00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 中間納付譲渡割額
   * 百円未満切捨て
   */
  readonly abj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 納付譲渡割額 */
  readonly abj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付還付譲渡割額 */
  readonly abj00090: Maybe<Scalars["BigInt"]["output"]>;
  /** この申告書が修正申告である場合 既確定譲渡割額 */
  readonly abj00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * この申告書が修正申告である場合 差引納付譲渡割額
   * 百円未満切捨て
   */
  readonly abj00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税及び地方消費税の合計（納付又は還付）税額 */
  readonly abj00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 付記事項 割賦基準の適用
   * 1:有 2:無
   */
  readonly abk00010: Scalars["Boolean"]["output"];
  /**
   * 付記事項 延払基準等の適用
   * 1:有 2:無
   */
  readonly abk00020: Scalars["Boolean"]["output"];
  /**
   * 付記事項 工事進行基準の適用
   * 1:有 2:無
   */
  readonly abk00030: Scalars["Boolean"]["output"];
  /**
   * 付記事項 現金主義会計の適用
   * 1:有 2:無
   */
  readonly abk00040: Scalars["Boolean"]["output"];
  /**
   * 参考事項 課税標準額に対する消費税額の計算の特例の適用
   * 1:有 2:無
   */
  readonly abl00010: Scalars["Boolean"]["output"];
  /**
   * 参考事項 第１種　課税売上高
   * （千円）
   */
  readonly abl00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第１種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00050: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 参考事項 第２種　課税売上高
   * （千円）
   */
  readonly abl00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第２種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00080: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 参考事項 第３種　課税売上高
   * （千円）
   */
  readonly abl00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第３種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00110: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 参考事項 第４種　課税売上高
   * （千円）
   */
  readonly abl00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第４種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00140: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 参考事項 第５種　課税売上高
   * （千円）
   */
  readonly abl00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第５種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00170: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 参考事項 第６種　課税売上高
   * （千円）
   */
  readonly abl00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第６種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 特例計算適用（令５７（３））
   * 1:有 2:無
   */
  readonly abl00210: Scalars["Boolean"]["output"];
  /**
   * 納税地（第二表）
   * NOZEISHA_ADR
   */
  readonly abm00010: Maybe<Scalars["String"]["output"]>;
  /**
   * 電話番号（第二表） 市外局番
   * 入力文字数分のみ表示
   * NOZEISHA_TEL
   */
  readonly abm00020: Maybe<TelephoneNumber>;
  /**
   * 名称又は屋号（フリガナ）（第二表）
   * カタカナ
   * NOZEISHA_NM_KN
   */
  readonly abm00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 名称又は屋号（第二表）
   * NOZEISHA_NM
   */
  readonly abm00050: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（フリガナ）（第二表）
   * カタカナ
   * DAIHYO_NM_KN
   */
  readonly abm00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（第二表）
   * DAIHYO_NM
   */
  readonly abm00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税期間（自）（第二表）
   * KAZEI_KIKAN_FROM
   */
  readonly abm00100: Scalars["Date"]["output"];
  /**
   * 課税期間（至）（第二表）
   * KAZEI_KIKAN_TO
   */
  readonly abm00110: Scalars["Date"]["output"];
  /**
   * 申告の種類（第二表）
   * 1:確定 2:中間 3:修正確定 4:修正中間
   * SHINKOKU_KBN
   */
  readonly abm00120: DeclarationType;
  /** 中間申告の場合の対象期間（自）（第二表） */
  readonly abm00140: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の場合の対象期間（至）（第二表） */
  readonly abm00150: Maybe<Scalars["Date"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 軽減売上割合（10営業日）
   * 1:該当 2:非該当
   */
  readonly abn00010: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 小売等軽減仕入割合
   * 1:該当 2:非該当
   */
  readonly abn00020: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 小売等軽減売上割合
   * 1:該当 2:非該当
   */
  readonly abn00030: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 課税標準額（第二表）
   * 千円未満切捨て
   */
  readonly abo00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 ３％適用分 */
  readonly abp00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 ４％適用分 */
  readonly abp00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 6.3％適用分 */
  readonly abp00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 6.24％適用分 */
  readonly abp00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 7.8％適用分 */
  readonly abp00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 課税資産の譲渡等の対価の額の合計額 */
  readonly abp00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 6.3％適用分 */
  readonly abq00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 7.8％適用分 */
  readonly abq00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 特定課税仕入れに係る支払対価の額の合計額 */
  readonly abq00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額（第二表） */
  readonly abr00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑪の内訳 ３％適用分 */
  readonly abs00010: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑪の内訳 ４％適用分 */
  readonly abs00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑪の内訳 6.3％適用分 */
  readonly abs00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑪の内訳 6.24％適用分 */
  readonly abs00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑪の内訳 7.8％適用分 */
  readonly abs00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 返還等対価に係る税額 */
  readonly abt00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑰の内訳 売上げの返還等対価に係る税額 */
  readonly abu00010: Maybe<Scalars["BigInt"]["output"]>;
  /** ⑰の内訳 特定課税仕入れの返還等対価に係る税額 */
  readonly abu00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 地方消費税の課税標準となる消費税額 */
  readonly abv00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 ４％適用分 */
  readonly abv00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 6.3％適用分 */
  readonly abv00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 6.24％及び7.8％適用分 */
  readonly abv00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税及び地方消費税の申告書(簡易課税用) */
export type Sha020_V9_0 = Node & {
  readonly __typename: "Sha020_V9_0";
  /**
   * 提出年月日
   * TEISYUTSU_DAY
   */
  readonly abh00010: Maybe<Scalars["Date"]["output"]>;
  /**
   * 提出先税務署コード
   * ZEIMUSHO
   */
  readonly abh00020Code: Maybe<Scalars["String"]["output"]>;
  /**
   * 提出先税務署
   * ZEIMUSHO
   */
  readonly abh00020Name: Maybe<Scalars["String"]["output"]>;
  /**
   * 納税地（第一表）
   * NOZEISHA_ADR
   */
  readonly abh00030: Maybe<Scalars["String"]["output"]>;
  /**
   * 電話番号（第一表） 市外局番
   * 入力文字数分のみ表示
   * NOZEISHA_TEL1
   * NOZEISHA_TEL2
   * NOZEISHA_TEL3
   */
  readonly abh00040: Maybe<TelephoneNumber>;
  /**
   * 名称又は屋号（フリガナ）（第一表）
   * カタカナ
   * 法人: NOZEISHA_NM_KN
   * 個人: NOZEISHA_YAGO_KN
   */
  readonly abh00060: Maybe<Scalars["String"]["output"]>;
  /**
   * 名称又は屋号（第一表）
   * 法人: NOZEISHA_NM
   * 個人: NOZEISHA_YAGO
   */
  readonly abh00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 個人番号又は法人番号
   * NOZEISHA_BANGO
   */
  readonly abh00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（フリガナ）（第一表）
   * カタカナ
   * 法人: DAIHYO_NM_KN
   * 個人: NOZEISHA_NM_KN
   */
  readonly abh00100: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（第一表）
   * 法人: DAIHYO_NM
   * 個人: NOZEISHA_NM
   */
  readonly abh00110: Maybe<Scalars["String"]["output"]>;
  /**
   * 振替継続希望区分
   * 1:該当 2:非該当
   * 手続きIDがRSH0040の場合、入力不可
   */
  readonly abh00115: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 課税期間（自）（第一表）
   * KAZEI_KIKAN_FROM
   */
  readonly abh00130: Scalars["Date"]["output"];
  /**
   * 課税期間（至）（第一表）
   * KAZEI_KIKAN_TO
   */
  readonly abh00140: Scalars["Date"]["output"];
  /**
   * 申告の種類（第一表）
   * 1:確定 2:中間 3:修正確定 4:修正中間
   * SHINKOKU_KBN
   */
  readonly abh00150: DeclarationType;
  /** 中間申告の場合の対象期間（自）（第一表） */
  readonly abh00170: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の場合の対象期間（至）（第一表） */
  readonly abh00180: Maybe<Scalars["Date"]["output"]>;
  /** 還付を受けようとする金融機関等 */
  readonly abh00190: Maybe<BankAccountInfo>;
  /**
   * 税理士署名
   * DAIRI_NM
   */
  readonly abh00210: Maybe<Scalars["String"]["output"]>;
  /**
   * 税理士電話番号 市外局番
   * DAIRI_TEL1
   * DAIRI_TEL2
   * DAIRI_TEL3
   */
  readonly abh00220: Maybe<TelephoneNumber>;
  /**
   * 税理士法第30条の書面提出有
   * 1:有 2:無
   */
  readonly abh00230: Scalars["Boolean"]["output"];
  /**
   * 税理士法第33条の２の書面提出有
   * 1:有 2:無
   */
  readonly abh00240: Scalars["Boolean"]["output"];
  /**
   * 口座情報利用同意区分
   * 1:該当 2:非該当
   * 手続きIDがRSH0040の場合、入力不可 「口座情報利用同意区分」が1：該当の場合、「個人番号又は法人番号」は必須とする
   */
  readonly abh00250: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 課税標準額（第一表）
   * 千円未満切捨て
   */
  readonly abi00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額（第一表） */
  readonly abi00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 */
  readonly abi00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除対象仕入税額 */
  readonly abi00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 返還等対価に係る税額 */
  readonly abi00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 貸倒れに係る税額 */
  readonly abi00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除税額小計 */
  readonly abi00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 */
  readonly abi00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 */
  readonly abi00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 中間納付税額
   * 百円未満切捨て
   */
  readonly abi00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 納付税額 */
  readonly abi00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付還付税額 */
  readonly abi00130: Maybe<Scalars["BigInt"]["output"]>;
  /** この申告書が修正申告である場合 既確定税額 */
  readonly abi00150: Maybe<Scalars["BigInt"]["output"]>;
  /** この申告書が修正申告である場合 差引納付税額 */
  readonly abi00160: Maybe<Scalars["BigInt"]["output"]>;
  /** この課税期間の課税売上高 */
  readonly abi00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 基準期間の課税売上高 */
  readonly abi00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 控除不足還付税額 */
  readonly abj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 差引税額 */
  readonly abj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額 還付額 */
  readonly abj00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額 納税額 */
  readonly abj00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付譲渡割額 */
  readonly abj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 納付譲渡割額 */
  readonly abj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 中間納付還付譲渡割額 */
  readonly abj00090: Maybe<Scalars["BigInt"]["output"]>;
  /** この申告書が修正申告である場合 既確定譲渡割額 */
  readonly abj00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * この申告書が修正申告である場合 差引納付譲渡割額
   * 百円未満切捨て
   */
  readonly abj00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税及び地方消費税の合計（納付又は還付）税額 */
  readonly abj00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 付記事項 割賦基準の適用
   * 1:有 2:無
   */
  readonly abk00010: Scalars["Boolean"]["output"];
  /**
   * 付記事項 延払基準等の適用
   * 1:有 2:無
   */
  readonly abk00020: Scalars["Boolean"]["output"];
  /**
   * 付記事項 工事進行基準の適用
   * 1:有 2:無
   */
  readonly abk00030: Scalars["Boolean"]["output"];
  /**
   * 付記事項 現金主義会計の適用
   * 1:有 2:無
   */
  readonly abk00040: Scalars["Boolean"]["output"];
  /**
   * 参考事項 課税標準額に対する消費税額の計算の特例の適用
   * 1:有 2:無
   */
  readonly abl00010: Scalars["Boolean"]["output"];
  /**
   * 参考事項 第１種　課税売上高
   * （千円）
   */
  readonly abl00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第１種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第２種　課税売上高
   * （千円）
   */
  readonly abl00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第２種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第３種　課税売上高
   * （千円）
   */
  readonly abl00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第３種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第４種　課税売上高
   * （千円）
   */
  readonly abl00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第４種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第５種　課税売上高
   * （千円）
   */
  readonly abl00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第５種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第６種　課税売上高
   * （千円）
   */
  readonly abl00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 第６種　売上割合
   * 0.0≦x≦100.0
   * （%）
   */
  readonly abl00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 参考事項 特例計算適用（令５７（３））
   * 1:有 2:無
   */
  readonly abl00210: Scalars["Boolean"]["output"];
  /**
   * 納税地（第二表）
   * NOZEISHA_ADR
   */
  readonly abm00010: Maybe<Scalars["String"]["output"]>;
  /**
   * 電話番号（第二表） 市外局番
   * 入力文字数分のみ表示
   * NOZEISHA_TEL
   */
  readonly abm00020: Maybe<TelephoneNumber>;
  /**
   * 名称又は屋号（フリガナ）（第二表）
   * カタカナ
   * NOZEISHA_NM_KN
   */
  readonly abm00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 名称又は屋号（第二表）
   * NOZEISHA_NM
   */
  readonly abm00050: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（フリガナ）（第二表）
   * カタカナ
   * DAIHYO_NM_KN
   */
  readonly abm00070: Maybe<Scalars["String"]["output"]>;
  /**
   * 代表者氏名又は氏名（第二表）
   * DAIHYO_NM
   */
  readonly abm00080: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税期間（自）（第二表）
   * KAZEI_KIKAN_FROM
   */
  readonly abm00100: Scalars["Date"]["output"];
  /**
   * 課税期間（至）（第二表）
   * KAZEI_KIKAN_TO
   */
  readonly abm00110: Scalars["Date"]["output"];
  /**
   * 申告の種類（第二表）
   * 1:確定 2:中間 3:修正確定 4:修正中間
   * SHINKOKU_KBN
   */
  readonly abm00120: DeclarationType;
  /** 中間申告の場合の対象期間（自）（第二表） */
  readonly abm00140: Maybe<Scalars["Date"]["output"]>;
  /** 中間申告の場合の対象期間（至）（第二表） */
  readonly abm00150: Maybe<Scalars["Date"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 軽減売上割合（10営業日）
   * 1:該当 2:非該当
   */
  readonly abn00010: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 改正法附則による税額の特例計算 小売等軽減仕入割合
   * 1:該当 2:非該当
   */
  readonly abn00020: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 課税標準額（第二表）
   * 千円未満切捨て
   */
  readonly abo00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 ３％適用分 */
  readonly abp00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 ４％適用分 */
  readonly abp00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 6.3％適用分 */
  readonly abp00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 6.24％適用分 */
  readonly abp00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 7.8％適用分 */
  readonly abp00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 課税資産の譲渡等の対価の額の合計額 */
  readonly abp00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 6.3％適用分 */
  readonly abq00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 7.8％適用分 */
  readonly abq00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 特定課税仕入れに係る支払対価の額の合計額 */
  readonly abq00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額（第二表） */
  readonly abr00000: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 ３％適用分 */
  readonly abs00010: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 ４％適用分 */
  readonly abs00020: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 6.3％適用分 */
  readonly abs00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 6.24％適用分 */
  readonly abs00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (11)の内訳 7.8％適用分 */
  readonly abs00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 返還等対価に係る税額 */
  readonly abt00000: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)の内訳 売上げの返還等対価に係る税額 */
  readonly abu00010: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)の内訳 特定課税仕入れの返還等対価に係る税額 */
  readonly abu00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 地方消費税の課税標準となる消費税額 */
  readonly abv00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 ４％適用分 */
  readonly abv00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 6.3％適用分 */
  readonly abv00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 6.24％及び7.8％適用分 */
  readonly abv00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 通知希望区分（加算税）
   * 1:該当 2:非該当
   */
  readonly abw00000: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 通知希望区分（還付金振込）
   * 1:該当 2:非該当
   * 通知希望区分（還付金振込）は適用開始(令和5年6月19日)から使用可能
   */
  readonly abx00000: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * 税額控除に係る経過措置の適用（２割特例）
   * 1:該当 2:非該当
   */
  readonly aby00000: Scalars["Boolean"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表1-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb013_V2_0 = Node & {
  readonly __typename: "Shb013_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cqa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cqa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cqa00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税標準額
   * 旧税率分小計
   */
  readonly cqb00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額
   * 税率6.24％適用分
   */
  readonly cqb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額
   * 税率7.8％適用分
   */
  readonly cqb00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額
   * 合計
   */
  readonly cqb00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 課税資産の譲渡等の対価の額
   * 旧税率分小計
   */
  readonly cqc00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 課税資産の譲渡等の対価の額
   * 税率6.24％適用分
   */
  readonly cqc00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 課税資産の譲渡等の対価の額
   * 税率7.8％適用分
   */
  readonly cqc00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 課税資産の譲渡等の対価の額
   * 合計
   */
  readonly cqc00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 特定課税仕入れに係る支払対価の額
   * 旧税率分小計
   */
  readonly cqc00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 特定課税仕入れに係る支払対価の額
   * 税率7.8％適用分
   */
  readonly cqc00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ①の内訳
   * 特定課税仕入れに係る支払対価の額
   * 合計
   */
  readonly cqc00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 旧税率分小計
   */
  readonly cqd00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 税率6.24％適用分
   */
  readonly cqd00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 税率7.8％適用分
   */
  readonly cqd00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 合計
   */
  readonly cqd00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 旧税率分小計
   */
  readonly cqe00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 税率6.24％適用分
   */
  readonly cqe00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 税率7.8％適用分
   */
  readonly cqe00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 合計
   */
  readonly cqe00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 旧税率分小計
   */
  readonly cqf00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 税率6.24％適用分
   */
  readonly cqf00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 税率7.8％適用分
   */
  readonly cqf00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 合計
   */
  readonly cqf00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 旧税率分小計
   */
  readonly cqf00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 税率6.24％適用分
   */
  readonly cqf00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 税率7.8％適用分
   */
  readonly cqf00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 合計
   */
  readonly cqf00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 旧税率分小計
   */
  readonly cqf00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 税率6.24％適用分
   */
  readonly cqf00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 税率7.8％適用分
   */
  readonly cqf00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 合計
   */
  readonly cqf00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 特定課税仕入れの返還等対価に係る税額
   * 旧税率分小計
   */
  readonly cqf00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 特定課税仕入れの返還等対価に係る税額
   * 税率7.8％適用分
   */
  readonly cqf00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 特定課税仕入れの返還等対価に係る税額
   * 合計
   */
  readonly cqf00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 旧税率分小計
   */
  readonly cqf00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 税率6.24％適用分
   */
  readonly cqf00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 税率7.8％適用分
   */
  readonly cqf00240: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 合計
   */
  readonly cqf00250: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 旧税率分小計
   */
  readonly cqf00270: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 税率6.24％適用分
   */
  readonly cqf00280: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 税率7.8％適用分
   */
  readonly cqf00290: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 合計
   */
  readonly cqf00300: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除不足還付税額
   * 旧税率分小計
   */
  readonly cqg00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除不足還付税額
   * 税率6.24％適用分
   */
  readonly cqg00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除不足還付税額
   * 税率7.8％適用分
   */
  readonly cqg00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除不足還付税額
   * 合計
   */
  readonly cqg00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引税額
   * 旧税率分小計
   */
  readonly cqh00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引税額
   * 税率6.24％適用分
   */
  readonly cqh00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引税額
   * 税率7.8％適用分
   */
  readonly cqh00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引税額
   * 合計
   */
  readonly cqh00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引税額 */
  readonly cqi00000: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 控除不足還付税額
   * 旧税率分小計
   */
  readonly cqj00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 控除不足還付税額
   * 税率7.8％適用分
   */
  readonly cqj00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 控除不足還付税額
   * 合計
   */
  readonly cqj00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 差引税額
   * 旧税率分小計
   */
  readonly cqj00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 差引税額
   * 税率7.8％適用分
   */
  readonly cqj00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 差引税額
   * 合計
   */
  readonly cqj00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 合計差引地方消費税の課税標準となる消費税額
   * 旧税率分小計
   */
  readonly cqk00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 合計差引地方消費税の課税標準となる消費税額
   * 税率7.8％適用分
   */
  readonly cqk00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 合計差引地方消費税の課税標準となる消費税額
   * 合計
   */
  readonly cqk00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 還付額
   * 旧税率分小計
   */
  readonly cql00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 還付額
   * 税率7.8％適用分
   */
  readonly cql00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 還付額
   * 合計
   */
  readonly cql00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 納税額
   * 旧税率分小計
   */
  readonly cql00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 納税額
   * 税率7.8％適用分
   */
  readonly cql00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 納税額
   * 合計
   */
  readonly cql00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引譲渡割額 */
  readonly cqm00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表1-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb013_V3_0 = Node & {
  readonly __typename: "Shb013_V3_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cqa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cqa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cqa00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税標準額 旧税率分小計
   * 千円未満切捨て
   */
  readonly cqb00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率6.24％適用分
   * 千円未満切捨て
   */
  readonly cqb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率7.8％適用分
   * 千円未満切捨て
   */
  readonly cqb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 合計 */
  readonly cqb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 旧税率分小計 */
  readonly cqc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率6.24％適用分 */
  readonly cqc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率7.8％適用分 */
  readonly cqc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 合計 */
  readonly cqc00050: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 旧税率分小計 */
  readonly cqc00070: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 税率7.8％適用分 */
  readonly cqc00080: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 合計 */
  readonly cqc00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 旧税率分小計 */
  readonly cqd00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率6.24％適用分 */
  readonly cqd00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率7.8％適用分 */
  readonly cqd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 合計 */
  readonly cqd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 旧税率分小計 */
  readonly cqe00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率6.24％適用分 */
  readonly cqe00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率7.8％適用分 */
  readonly cqe00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額 合計
   * 【計算】CQE00010＋CQE00020＋CQE00030
   */
  readonly cqe00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 旧税率分小計 */
  readonly cqf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率6.24％適用分 */
  readonly cqf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率7.8％適用分 */
  readonly cqf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 合計 */
  readonly cqf00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 旧税率分小計 */
  readonly cqf00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率6.24％適用分 */
  readonly cqf00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率7.8％適用分 */
  readonly cqf00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 合計 */
  readonly cqf00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 旧税率分小計 */
  readonly cqf00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率6.24％適用分 */
  readonly cqf00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率7.8％適用分 */
  readonly cqf00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 合計 */
  readonly cqf00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 旧税率分小計 */
  readonly cqf00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 税率7.8％適用分 */
  readonly cqf00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 合計 */
  readonly cqf00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 旧税率分小計 */
  readonly cqf00220: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率6.24％適用分 */
  readonly cqf00230: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率7.8％適用分 */
  readonly cqf00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 合計 */
  readonly cqf00250: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 旧税率分小計 */
  readonly cqf00270: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率6.24％適用分 */
  readonly cqf00280: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率7.8％適用分 */
  readonly cqf00290: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 合計 */
  readonly cqf00300: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 旧税率分小計 */
  readonly cqg00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率6.24％適用分 */
  readonly cqg00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率7.8％適用分 */
  readonly cqg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 合計 */
  readonly cqg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 旧税率分小計 */
  readonly cqh00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率6.24％適用分 */
  readonly cqh00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率7.8％適用分 */
  readonly cqh00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 合計 */
  readonly cqh00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引税額 */
  readonly cqi00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 旧税率分小計 */
  readonly cqj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 税率7.8％適用分 */
  readonly cqj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 合計 */
  readonly cqj00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 旧税率分小計 */
  readonly cqj00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 税率7.8％適用分 */
  readonly cqj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 合計 */
  readonly cqj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 旧税率分小計 */
  readonly cqk00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 税率7.8％適用分 */
  readonly cqk00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 合計 */
  readonly cqk00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 旧税率分小計 */
  readonly cql00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 税率7.8％適用分 */
  readonly cql00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 合計 */
  readonly cql00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額 旧税率分小計 */
  readonly cql00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額 税率7.8％適用分 */
  readonly cql00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額・納税額 合計
   * 【計算】CQL00060＋CQL00070
   */
  readonly cql00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引譲渡割額 */
  readonly cqm00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表1-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】v4_0 */
export type Shb015_V4_0 = Node & {
  readonly __typename: "Shb015_V4_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly baa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly baa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly baa00040: Maybe<Scalars["String"]["output"]>;
  /** 課税標準額・税率３％適用分 */
  readonly bab00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額・税率４％適用分 */
  readonly bab00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額・税率6.3％適用分 */
  readonly bab00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額・旧税率分小計 */
  readonly bab00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額・税率３％適用分 */
  readonly bac00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額・税率４％適用分 */
  readonly bac00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額・税率6.3％適用分 */
  readonly bac00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額・旧税率分小計 */
  readonly bac00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・税率３％適用分 */
  readonly bad00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・税率４％適用分 */
  readonly bad00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・税率6.3％適用分 */
  readonly bad00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・旧税率分小計 */
  readonly bad00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額・税率３％適用分 */
  readonly bae00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額・税率４％適用分 */
  readonly bae00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額・税率6.3％適用分 */
  readonly bae00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額・旧税率分小計 */
  readonly bae00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額・税率３％適用分 */
  readonly bae00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額・税率４％適用分 */
  readonly bae00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額・税率6.3％適用分 */
  readonly bae00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額・旧税率分小計 */
  readonly bae00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額・税率３％適用分 */
  readonly bae00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額・税率４％適用分 */
  readonly bae00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額・税率6.3％適用分 */
  readonly bae00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額・旧税率分小計 */
  readonly bae00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計・税率３％適用分 */
  readonly bae00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計・税率４％適用分 */
  readonly bae00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計・税率6.3％適用分 */
  readonly bae00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計・旧税率分小計 */
  readonly bae00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・⑤の内訳・売上げの返還等対価に係る税額・税率３％適用分 */
  readonly bae00230: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・⑤の内訳・売上げの返還等対価に係る税額・税率４％適用分 */
  readonly bae00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・⑤の内訳・売上げの返還等対価に係る税額・税率6.3％適用分 */
  readonly bae00250: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・⑤の内訳・売上げの返還等対価に係る税額・旧税率分小計 */
  readonly bae00260: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・⑤の内訳・特定課税仕入れの返還等対価に係る税額・税率6.3％適用分 */
  readonly bae00280: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・⑤の内訳・特定課税仕入れの返還等対価に係る税額・旧税率分小計 */
  readonly bae00290: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額・税率３％適用分 */
  readonly baf00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額・税率４％適用分 */
  readonly baf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額・税率6.3％適用分 */
  readonly baf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額・旧税率分小計 */
  readonly baf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額・税率３％適用分 */
  readonly bag00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額・税率４％適用分 */
  readonly bag00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額・税率6.3％適用分 */
  readonly bag00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額・旧税率分小計 */
  readonly bag00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額・税率４％適用分 */
  readonly bai00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額・税率6.3％適用分 */
  readonly bai00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額・旧税率分小計 */
  readonly bai00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額・税率４％適用分 */
  readonly bai00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額・税率6.3％適用分 */
  readonly bai00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額・旧税率分小計 */
  readonly bai00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額・旧税率分小計 */
  readonly baj00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額・税率４％適用分 */
  readonly bak00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額・税率6.3％適用分 */
  readonly bak00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額・旧税率分小計 */
  readonly bak00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額・税率４％適用分 */
  readonly bak00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額・税率6.3％適用分 */
  readonly bak00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額・旧税率分小計 */
  readonly bak00080: Maybe<Scalars["BigInt"]["output"]>;
  /** ①の内訳・課税資産の譲渡等の対価の額・税率３％適用分 */
  readonly bam00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ①の内訳・課税資産の譲渡等の対価の額・税率４％適用分 */
  readonly bam00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ①の内訳・課税資産の譲渡等の対価の額・税率6.3％適用分 */
  readonly bam00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ①の内訳・課税資産の譲渡等の対価の額・旧税率分小計 */
  readonly bam00050: Maybe<Scalars["BigInt"]["output"]>;
  /** ①の内訳・特定課税仕入れに係る支払対価の額・税率6.3％適用分 */
  readonly bam00070: Maybe<Scalars["BigInt"]["output"]>;
  /** ①の内訳・特定課税仕入れに係る支払対価の額・旧税率分小計 */
  readonly bam00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額・税率４％適用分 */
  readonly ban00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額・税率6.3％適用分 */
  readonly bao00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表1-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb015_V5_0 = Node & {
  readonly __typename: "Shb015_V5_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly baa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly baa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly baa00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税標準額 税率３％適用分
   * 千円未満切捨て
   */
  readonly bab00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率４％適用分
   * 千円未満切捨て
   */
  readonly bab00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率6.3％適用分
   * 千円未満切捨て
   */
  readonly bab00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 旧税率分小計 */
  readonly bab00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率３％適用分 */
  readonly bac00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率４％適用分 */
  readonly bac00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率6.3％適用分 */
  readonly bac00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 旧税率分小計 */
  readonly bac00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率３％適用分 */
  readonly bad00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率４％適用分 */
  readonly bad00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率6.3％適用分 */
  readonly bad00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 旧税率分小計 */
  readonly bad00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率３％適用分 */
  readonly bae00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率４％適用分 */
  readonly bae00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率6.3％適用分 */
  readonly bae00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 旧税率分小計 */
  readonly bae00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率３％適用分 */
  readonly bae00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率４％適用分 */
  readonly bae00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率6.3％適用分 */
  readonly bae00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 旧税率分小計 */
  readonly bae00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率３％適用分 */
  readonly bae00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率４％適用分 */
  readonly bae00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率6.3％適用分 */
  readonly bae00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 旧税率分小計 */
  readonly bae00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率３％適用分 */
  readonly bae00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率４％適用分 */
  readonly bae00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率6.3％適用分 */
  readonly bae00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 旧税率分小計 */
  readonly bae00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率３％適用分 */
  readonly bae00230: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率４％適用分 */
  readonly bae00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率6.3％適用分 */
  readonly bae00250: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 旧税率分小計 */
  readonly bae00260: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 税率6.3％適用分 */
  readonly bae00280: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 旧税率分小計 */
  readonly bae00290: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率３％適用分 */
  readonly baf00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率４％適用分 */
  readonly baf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率6.3％適用分 */
  readonly baf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 旧税率分小計 */
  readonly baf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率３％適用分 */
  readonly bag00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率４％適用分 */
  readonly bag00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率6.3％適用分 */
  readonly bag00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 旧税率分小計 */
  readonly bag00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 税率４％適用分 */
  readonly bai00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 税率6.3％適用分 */
  readonly bai00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 旧税率分小計 */
  readonly bai00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 税率４％適用分 */
  readonly bai00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 税率6.3％適用分 */
  readonly bai00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 旧税率分小計 */
  readonly bai00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 旧税率分小計 */
  readonly baj00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 税率４％適用分 */
  readonly bak00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 税率6.3％適用分 */
  readonly bak00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 旧税率分小計 */
  readonly bak00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額 税率４％適用分 */
  readonly bak00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額 税率6.3％適用分 */
  readonly bak00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額 旧税率分小計 */
  readonly bak00080: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率３％適用分 */
  readonly bam00020: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率４％適用分 */
  readonly bam00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率6.3％適用分 */
  readonly bam00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 旧税率分小計 */
  readonly bam00050: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 税率6.3％適用分 */
  readonly bam00070: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 旧税率分小計 */
  readonly bam00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 税率４％適用分 */
  readonly ban00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 税率6.3％適用分 */
  readonly bao00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表1-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
export type Shb017_V1_0 = Node & {
  readonly __typename: "Shb017_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly dsa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly dsa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly dsa00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税標準額
   * 税率6.24％適用分
   */
  readonly dsb00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額
   * 税率7.8％適用分
   */
  readonly dsb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額
   * 合計
   */
  readonly dsb00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 税率6.24％適用分
   */
  readonly dsc00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 税率7.8％適用分
   */
  readonly dsc00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 合計
   */
  readonly dsc00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 特定課税仕入れに係る支払対価の額
   * 税率7.8％適用分
   */
  readonly dsc00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 特定課税仕入れに係る支払対価の額
   * 合計
   */
  readonly dsc00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 税率6.24％適用分
   */
  readonly dsd00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 税率7.8％適用分
   */
  readonly dsd00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 消費税額
   * 合計
   */
  readonly dsd00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 税率6.24％適用分
   */
  readonly dse00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 税率7.8％適用分
   */
  readonly dse00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除過大調整税額
   * 合計
   */
  readonly dse00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 税率6.24％適用分
   */
  readonly dsf00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 税率7.8％適用分
   */
  readonly dsf00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除対象仕入税額
   * 合計
   */
  readonly dsf00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 税率6.24％適用分
   */
  readonly dsf00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 税率7.8％適用分
   */
  readonly dsf00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 返還等対価に係る税額
   * 合計
   */
  readonly dsf00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 税率6.24％適用分
   */
  readonly dsf00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 税率7.8％適用分
   */
  readonly dsf00120: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 売上げの返還等対価に係る税額
   * 合計
   */
  readonly dsf00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 特定課税仕入れの返還等対価に係る税額
   * 税率7.8％適用分
   */
  readonly dsf00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * ⑤の内訳
   * 特定課税仕入れの返還等対価に係る税額
   * 合計
   */
  readonly dsf00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 税率6.24％適用分
   */
  readonly dsf00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 税率7.8％適用分
   */
  readonly dsf00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 貸倒れに係る税額
   * 合計
   */
  readonly dsf00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 税率6.24％適用分
   */
  readonly dsf00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 税率7.8％適用分
   */
  readonly dsf00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額
   * 控除税額小計
   * 合計
   */
  readonly dsf00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 */
  readonly dsg00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 */
  readonly dsh00000: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 控除不足還付税額
   */
  readonly dsi00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 地方消費税の課税標準となる消費税額
   * 差引税額
   */
  readonly dsi00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 還付額
   */
  readonly dsj00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 譲渡割額
   * 納税額
   */
  readonly dsj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表1-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
export type Shb017_V2_0 = Node & {
  readonly __typename: "Shb017_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly dsa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly dsa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly dsa00040: Maybe<Scalars["String"]["output"]>;
  /** 課税標準額 税率6.24％適用分 */
  readonly dsb00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 税率7.8％適用分 */
  readonly dsb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 合計 */
  readonly dsb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率6.24％適用分 */
  readonly dsc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 税率7.8％適用分 */
  readonly dsc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・課税資産の譲渡等の対価の額 合計 */
  readonly dsc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 税率7.8％適用分 */
  readonly dsc00060: Maybe<Scalars["BigInt"]["output"]>;
  /** (１)の内訳・特定課税仕入れに係る支払対価の額 合計 */
  readonly dsc00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率6.24％適用分 */
  readonly dsd00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率7.8％適用分 */
  readonly dsd00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 合計 */
  readonly dsd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率6.24％適用分 */
  readonly dse00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率7.8％適用分 */
  readonly dse00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 合計 */
  readonly dse00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率6.24％適用分 */
  readonly dsf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 税率7.8％適用分 */
  readonly dsf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除対象仕入税額 合計 */
  readonly dsf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率6.24％適用分 */
  readonly dsf00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 税率7.8％適用分 */
  readonly dsf00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・返還等対価に係る税額 合計 */
  readonly dsf00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率6.24％適用分 */
  readonly dsf00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 税率7.8％適用分 */
  readonly dsf00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・売上げの返還等対価に係る税額 合計 */
  readonly dsf00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 税率7.8％適用分 */
  readonly dsf00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額・(５)の内訳・特定課税仕入れの返還等対価に係る税額 合計
   * 【計算】DSF00150
   */
  readonly dsf00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率6.24％適用分 */
  readonly dsf00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 税率7.8％適用分 */
  readonly dsf00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・貸倒れに係る税額 合計 */
  readonly dsf00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率6.24％適用分 */
  readonly dsf00220: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 税率7.8％適用分 */
  readonly dsf00230: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額・控除税額小計 合計 */
  readonly dsf00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 */
  readonly dsg00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 */
  readonly dsh00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・控除不足還付税額 */
  readonly dsi00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額・差引税額 */
  readonly dsi00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・還付額 */
  readonly dsj00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額・納税額 */
  readonly dsj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表2-1　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb025_V2_0 = Node & {
  readonly __typename: "Shb025_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cra00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cra00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cra00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 旧税率分小計
   */
  readonly crb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 税率6.24％適用分
   */
  readonly crb00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 税率7.8％適用分
   */
  readonly crb00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 合計
   */
  readonly crb00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 免税売上額
   */
  readonly crb00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 非課税資産の輸出等の金額、海外支店等へ移送した資産の価額
   */
  readonly crb00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly crb00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 資産の譲渡等の対価の額
   * 課税資産の譲渡等の対価の額
   */
  readonly crc00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 資産の譲渡等の対価の額
   * 非課税売上額
   */
  readonly crc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産の譲渡等の対価の額 */
  readonly crc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合 */
  readonly crd00000: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 旧税率分小計
   */
  readonly cre00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 税率6.24％適用分
   */
  readonly cre00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 税率7.8％適用分
   */
  readonly cre00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 合計
   */
  readonly cre00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 旧税率分小計
   */
  readonly cre00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 税率6.24％適用分
   */
  readonly cre00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 税率7.8％適用分
   */
  readonly cre00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 合計
   */
  readonly cre00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る支払対価の額
   * 旧税率分小計
   */
  readonly cre00120: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る支払対価の額
   * 税率7.8％適用分
   */
  readonly cre00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る支払対価の額
   * 合計
   */
  readonly cre00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る消費税額
   * 旧税率分小計
   */
  readonly cre00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る消費税額
   * 税率7.8％適用分
   */
  readonly cre00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る消費税額
   * 合計
   */
  readonly cre00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 旧税率分小計
   */
  readonly cre00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 税率6.24％適用分
   */
  readonly cre00210: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 税率7.8％適用分
   */
  readonly cre00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 合計
   */
  readonly cre00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 旧税率分小計
   */
  readonly cre00250: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 税率6.24％適用分
   */
  readonly cre00260: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 税率7.8％適用分
   */
  readonly cre00270: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 合計
   */
  readonly cre00280: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 旧税率分小計
   */
  readonly cre00300: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 税率6.24％適用分
   */
  readonly cre00310: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 税率7.8％適用分
   */
  readonly cre00320: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 合計
   */
  readonly cre00330: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 旧税率分小計
   */
  readonly crf00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 税率6.24％適用分
   */
  readonly crf00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 税率7.8％適用分
   */
  readonly crf00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 合計
   */
  readonly crf00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 旧税率分小計
   */
  readonly crg00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 税率6.24％適用分
   */
  readonly crg00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 税率7.8％適用分
   */
  readonly crg00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 合計
   */
  readonly crg00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 旧税率分小計
   */
  readonly crg00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 税率6.24％適用分
   */
  readonly crg00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 税率7.8％適用分
   */
  readonly crg00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 合計
   */
  readonly crg00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 旧税率分小計
   */
  readonly crg00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 税率6.24％適用分
   */
  readonly crg00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 税率7.8％適用分
   */
  readonly crg00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 合計
   */
  readonly crg00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 旧税率分小計
   */
  readonly crg00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 税率6.24％適用分
   */
  readonly crg00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 税率7.8％適用分
   */
  readonly crg00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 合計
   */
  readonly crg00210: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 旧税率分小計
   */
  readonly crh00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 税率6.24％適用分
   */
  readonly crh00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 税率7.8％適用分
   */
  readonly crh00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 合計
   */
  readonly crh00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 旧税率分小計
   */
  readonly crh00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 税率6.24％適用分
   */
  readonly crh00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 税率7.8％適用分
   */
  readonly crh00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 合計
   */
  readonly crh00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 旧税率分小計
   */
  readonly crh00120: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 税率6.24％適用分
   */
  readonly crh00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 税率7.8％適用分
   */
  readonly crh00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 合計
   */
  readonly crh00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 旧税率分小計
   */
  readonly cri00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 税率6.24％適用分
   */
  readonly cri00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 税率7.8％適用分
   */
  readonly cri00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 合計
   */
  readonly cri00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 旧税率分小計
   */
  readonly cri00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 税率6.24％適用分
   */
  readonly cri00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 税率7.8％適用分
   */
  readonly cri00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 合計
   */
  readonly cri00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 旧税率分小計
   */
  readonly crj00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 税率6.24％適用分
   */
  readonly crj00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 税率7.8％適用分
   */
  readonly crj00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 合計
   */
  readonly crj00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表2-1　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb025_V3_0 = Node & {
  readonly __typename: "Shb025_V3_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cra00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cra00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cra00040: Maybe<Scalars["String"]["output"]>;
  /** 課税売上額（税抜き） 旧税率分小計 */
  readonly crb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 税率6.24％適用分 */
  readonly crb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 税率7.8％適用分 */
  readonly crb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 合計 */
  readonly crb00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 免税売上額 */
  readonly crb00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 非課税資産の輸出等の金額、海外支店等へ移送した資産の価額 */
  readonly crb00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly crb00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly crc00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 非課税売上額 */
  readonly crc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産の譲渡等の対価の額 */
  readonly crc00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上割合
   * 0.00≦x≦100.00
   */
  readonly crd00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 旧税率分小計 */
  readonly cre00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率6.24％適用分 */
  readonly cre00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率7.8％適用分 */
  readonly cre00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 合計 */
  readonly cre00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 旧税率分小計 */
  readonly cre00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率6.24％適用分 */
  readonly cre00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率7.8％適用分 */
  readonly cre00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 合計 */
  readonly cre00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 旧税率分小計 */
  readonly cre00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 税率7.8％適用分 */
  readonly cre00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 合計 */
  readonly cre00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 旧税率分小計 */
  readonly cre00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 税率7.8％適用分 */
  readonly cre00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 合計 */
  readonly cre00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 旧税率分小計 */
  readonly cre00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 税率6.24％適用分 */
  readonly cre00210: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 税率7.8％適用分 */
  readonly cre00220: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 合計 */
  readonly cre00230: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 旧税率分小計 */
  readonly cre00250: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 税率6.24％適用分 */
  readonly cre00260: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 税率7.8％適用分 */
  readonly cre00270: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 合計 */
  readonly cre00280: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 旧税率分小計 */
  readonly cre00300: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率6.24％適用分 */
  readonly cre00310: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率7.8％適用分 */
  readonly cre00320: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 合計 */
  readonly cre00330: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 旧税率分小計 */
  readonly cre00350: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率6.24％適用分 */
  readonly cre00360: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率7.8％適用分 */
  readonly cre00370: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 合計 */
  readonly cre00380: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 旧税率分小計 */
  readonly cre00400: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率6.24％適用分 */
  readonly cre00410: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率7.8％適用分 */
  readonly cre00420: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 合計 */
  readonly cre00430: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 旧税率分小計 */
  readonly crf00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 税率6.24％適用分 */
  readonly crf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 税率7.8％適用分 */
  readonly crf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 合計 */
  readonly crf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 旧税率分小計 */
  readonly crg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率6.24％適用分 */
  readonly crg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率7.8％適用分 */
  readonly crg00050: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 合計 */
  readonly crg00060: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 旧税率分小計 */
  readonly crg00080: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率6.24％適用分 */
  readonly crg00090: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率7.8％適用分 */
  readonly crg00100: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 合計 */
  readonly crg00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 旧税率分小計 */
  readonly crg00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率6.24％適用分 */
  readonly crg00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率7.8％適用分 */
  readonly crg00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 合計 */
  readonly crg00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 旧税率分小計 */
  readonly crg00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率6.24％適用分 */
  readonly crg00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率7.8％適用分 */
  readonly crg00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 合計 */
  readonly crg00210: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 旧税率分小計 */
  readonly crh00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 税率6.24％適用分 */
  readonly crh00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 税率7.8％適用分 */
  readonly crh00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 合計 */
  readonly crh00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 旧税率分小計 */
  readonly crh00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 税率6.24％適用分 */
  readonly crh00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 税率7.8％適用分 */
  readonly crh00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 合計 */
  readonly crh00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 旧税率分小計 */
  readonly crh00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率6.24％適用分 */
  readonly crh00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率7.8％適用分 */
  readonly crh00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 合計 */
  readonly crh00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 旧税率分小計 */
  readonly cri00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率6.24％適用分 */
  readonly cri00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率7.8％適用分 */
  readonly cri00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 合計 */
  readonly cri00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 旧税率分小計 */
  readonly cri00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率6.24％適用分 */
  readonly cri00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率7.8％適用分 */
  readonly cri00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 合計 */
  readonly cri00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 旧税率分小計 */
  readonly crj00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly crj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly crj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 合計 */
  readonly crj00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表2-2　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】V6 */
export type Shb030_V6_0 = Node & {
  readonly __typename: "Shb030_V6_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly aea00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly aea00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly aea00040: Maybe<Scalars["String"]["output"]>;
  /** 課税売上額（税抜き）・税率３％適用分 */
  readonly aeb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き）・税率４％適用分 */
  readonly aeb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き）・税率6.3％適用分 */
  readonly aeb00045: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き）・旧税率分小計 */
  readonly aeb00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly aeb00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産の譲渡等の対価の額 */
  readonly aeb00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合 */
  readonly aeb00130: Maybe<Scalars["Percentage"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み）・税率３％適用分 */
  readonly aec00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み）・税率４％適用分 */
  readonly aec00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み）・税率6.3％適用分 */
  readonly aec00035: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み）・旧税率分小計 */
  readonly aec00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額・税率３％適用分 */
  readonly aec00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額・税率４％適用分 */
  readonly aec00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額・税率6.3％適用分 */
  readonly aec00075: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額・旧税率分小計 */
  readonly aec00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額・税率6.3％適用分 */
  readonly aec00083: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額・旧税率分小計 */
  readonly aec00084: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額・税率6.3％適用分 */
  readonly aec00087: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額・旧税率分小計 */
  readonly aec00088: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額・税率6.3％適用分 */
  readonly aec00115: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額・旧税率分小計 */
  readonly aec00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額・税率３％適用分 */
  readonly aec00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額・税率４％適用分 */
  readonly aec00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額・税率6.3％適用分 */
  readonly aec00165: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額・旧税率分小計 */
  readonly aec00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額・税率３％適用分 */
  readonly aec00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額・税率４％適用分 */
  readonly aec00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額・税率6.3％適用分 */
  readonly aec00205: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額・旧税率分小計 */
  readonly aec00210: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合・税率３％適用分 */
  readonly aed00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合・税率４％適用分 */
  readonly aed00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合・税率6.3％適用分 */
  readonly aed00025: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合・旧税率分小計 */
  readonly aed00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げにのみ要するもの・税率３％適用分 */
  readonly aee00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げにのみ要するもの・税率４％適用分 */
  readonly aee00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げにのみ要するもの・税率6.3％適用分 */
  readonly aee00045: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げにのみ要するもの・旧税率分小計 */
  readonly aee00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げと非課税売上げに共通して要するもの・税率３％適用分 */
  readonly aee00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げと非課税売上げに共通して要するもの・税率４％適用分 */
  readonly aee00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げと非課税売上げに共通して要するもの・税率6.3％適用分 */
  readonly aee00085: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・(15)のうち、課税売上げと非課税売上げに共通して要するもの・旧税率分小計 */
  readonly aee00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・個別対応方式により控除する課税仕入れ等の税額・税率３％適用分 */
  readonly aee00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・個別対応方式により控除する課税仕入れ等の税額・税率４％適用分 */
  readonly aee00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・個別対応方式により控除する課税仕入れ等の税額・税率6.3％適用分 */
  readonly aee00125: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・個別対応方式・個別対応方式により控除する課税仕入れ等の税額・旧税率分小計 */
  readonly aee00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・一括比例配分方式により控除する課税仕入れ等の税額・税率３％適用分 */
  readonly aee00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・一括比例配分方式により控除する課税仕入れ等の税額・税率４％適用分 */
  readonly aee00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・一括比例配分方式により控除する課税仕入れ等の税額・税率6.3％適用分 */
  readonly aee00165: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円超又は課税売上割合が９５％未満の場合・一括比例配分方式により控除する課税仕入れ等の税額・旧税率分小計 */
  readonly aee00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額・税率３％適用分 */
  readonly aef00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額・税率４％適用分 */
  readonly aef00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額・税率6.3％適用分 */
  readonly aef00045: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額・旧税率分小計 */
  readonly aef00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額・税率３％適用分 */
  readonly aef00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額・税率４％適用分 */
  readonly aef00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額・税率6.3％適用分 */
  readonly aef00095: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額・旧税率分小計 */
  readonly aef00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額・税率３％適用分 */
  readonly aef00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額・税率４％適用分 */
  readonly aef00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額・税率6.3％適用分 */
  readonly aef00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額・旧税率分小計 */
  readonly aef00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額・税率３％適用分 */
  readonly aeg00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額・税率４％適用分 */
  readonly aeg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額・税率6.3％適用分 */
  readonly aeg00035: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額・旧税率分小計 */
  readonly aeg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・税率３％適用分 */
  readonly aeg00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・税率４％適用分 */
  readonly aeg00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・税率6.3％適用分 */
  readonly aeg00075: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額・旧税率分小計 */
  readonly aeg00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額・税率３％適用分 */
  readonly aeh00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額・税率４％適用分 */
  readonly aeh00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額・税率6.3％適用分 */
  readonly aeh00025: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額・旧税率分小計 */
  readonly aeh00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表2-2　課税売上割合・控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb030_V7_0 = Node & {
  readonly __typename: "Shb030_V7_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly aea00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly aea00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly aea00040: Maybe<Scalars["String"]["output"]>;
  /** 課税売上額（税抜き） 税率３％適用分 */
  readonly aeb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 税率４％適用分 */
  readonly aeb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 税率6.3％適用分 */
  readonly aeb00045: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 旧税率分小計 */
  readonly aeb00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly aeb00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産の譲渡等の対価の額 */
  readonly aeb00120: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上割合
   * 0.00≦x≦100.00
   */
  readonly aeb00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率３％適用分 */
  readonly aec00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率４％適用分 */
  readonly aec00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率6.3％適用分 */
  readonly aec00035: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 旧税率分小計 */
  readonly aec00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率３％適用分 */
  readonly aec00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率４％適用分 */
  readonly aec00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率6.3％適用分 */
  readonly aec00075: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 旧税率分小計 */
  readonly aec00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 税率6.3％適用分 */
  readonly aec00083: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 特定課税仕入れに係る支払対価の額 旧税率分小計
   * 【計算】AEC00083
   */
  readonly aec00084: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 税率6.3％適用分 */
  readonly aec00087: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 旧税率分小計 */
  readonly aec00088: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 税率6.3％適用分 */
  readonly aec00115: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 旧税率分小計 */
  readonly aec00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額 税率３％適用分 */
  readonly aec00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額 税率４％適用分 */
  readonly aec00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額 税率6.3％適用分 */
  readonly aec00165: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）ことと　なった場合における消費税額の調整(加算又は減算)額 旧税率分小計 */
  readonly aec00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率３％適用分 */
  readonly aec00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率４％適用分 */
  readonly aec00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率6.3％適用分 */
  readonly aec00205: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 旧税率分小計 */
  readonly aec00210: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率３％適用分 */
  readonly aec00230: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率４％適用分 */
  readonly aec00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率6.3％適用分 */
  readonly aec00250: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 旧税率分小計 */
  readonly aec00260: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率３％適用分 */
  readonly aec00280: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率４％適用分 */
  readonly aec00290: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率6.3％適用分 */
  readonly aec00300: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 旧税率分小計 */
  readonly aec00310: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合 税率３％適用分 */
  readonly aed00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合 税率４％適用分 */
  readonly aed00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合 税率6.3％適用分 */
  readonly aed00025: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が９５％以上の場合 旧税率分小計 */
  readonly aed00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率３％適用分 */
  readonly aee00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率４％適用分 */
  readonly aee00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率6.3％適用分 */
  readonly aee00045: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 旧税率分小計 */
  readonly aee00050: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率３％適用分 */
  readonly aee00070: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率４％適用分 */
  readonly aee00080: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率6.3％適用分 */
  readonly aee00085: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 旧税率分小計 */
  readonly aee00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率３％適用分 */
  readonly aee00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率４％適用分 */
  readonly aee00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率6.3％適用分 */
  readonly aee00125: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 旧税率分小計 */
  readonly aee00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率３％適用分 */
  readonly aee00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率４％適用分 */
  readonly aee00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率6.3％適用分 */
  readonly aee00165: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 旧税率分小計 */
  readonly aee00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 税率３％適用分 */
  readonly aef00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 税率４％適用分 */
  readonly aef00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 税率6.3％適用分 */
  readonly aef00045: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整(加算又は減算)額 旧税率分小計 */
  readonly aef00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額 税率３％適用分 */
  readonly aef00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額 税率４％適用分 */
  readonly aef00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額 税率6.3％適用分 */
  readonly aef00095: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用(非課税業務用)に転用した場合の調整(加算又は減算)額 旧税率分小計 */
  readonly aef00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率３％適用分 */
  readonly aef00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率４％適用分 */
  readonly aef00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率6.3％適用分 */
  readonly aef00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 旧税率分小計 */
  readonly aef00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率３％適用分 */
  readonly aeg00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率４％適用分 */
  readonly aeg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率6.3％適用分 */
  readonly aeg00035: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 旧税率分小計 */
  readonly aeg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率３％適用分 */
  readonly aeg00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率４％適用分 */
  readonly aeg00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率6.3％適用分 */
  readonly aeg00075: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 旧税率分小計 */
  readonly aeg00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率３％適用分 */
  readonly aeh00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率４％適用分 */
  readonly aeh00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.3％適用分 */
  readonly aeh00025: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 旧税率分小計 */
  readonly aeh00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表2-3　課税売上割合・控除対象仕入税額等の計算表 */
export type Shb033_V1_0 = Node & {
  readonly __typename: "Shb033_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly dta00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly dta00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly dta00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 税率6.24％適用分
   */
  readonly dtb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 税率7.8％適用分
   */
  readonly dtb00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税売上額（税抜き）
   * 合計
   */
  readonly dtb00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 免税売上額
   */
  readonly dtb00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 非課税資産の輸出等の金額、海外支店等へ移送した資産の価額
   */
  readonly dtb00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額
   * 課税資産の譲渡等の対価の額
   */
  readonly dtb00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 資産の譲渡等の対価の額
   * 課税資産の譲渡等の対価の額
   */
  readonly dtc00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 資産の譲渡等の対価の額
   * 非課税売上額
   */
  readonly dtc00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 資産の譲渡等の対価の額
   * 資産の譲渡等の対価の額
   */
  readonly dtc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合 */
  readonly dtd00000: Maybe<Scalars["Percentage"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 税率6.24％適用分
   */
  readonly dte00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 税率7.8％適用分
   */
  readonly dte00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る支払対価の額（税込み）
   * 合計
   */
  readonly dte00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 税率6.24％適用分
   */
  readonly dte00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 税率7.8％適用分
   */
  readonly dte00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れに係る消費税額
   * 合計
   */
  readonly dte00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る支払対価の額
   * 税率7.8％適用分
   */
  readonly dte00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る支払対価の額
   * 合計
   */
  readonly dte00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る消費税額
   * 税率7.8％適用分
   */
  readonly dte00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 特定課税仕入れに係る消費税額
   * 合計
   */
  readonly dte00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 税率6.24％適用分
   */
  readonly dte00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 税率7.8％適用分
   */
  readonly dte00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税貨物に係る消費税額
   * 合計
   */
  readonly dte00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 税率6.24％適用分
   */
  readonly dte00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 税率7.8％適用分
   */
  readonly dte00210: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額
   * 合計
   */
  readonly dte00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 税率6.24％適用分
   */
  readonly dte00240: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 税率7.8％適用分
   */
  readonly dte00250: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税仕入れ等の税額
   * 課税仕入れ等の税額の合計額
   * 合計
   */
  readonly dte00260: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 税率6.24％適用分
   */
  readonly dtf00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 税率7.8％適用分
   */
  readonly dtf00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合
   * 合計
   */
  readonly dtf00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 税率6.24％適用分
   */
  readonly dtg00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 税率7.8％適用分
   */
  readonly dtg00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げにのみ要するもの
   * 合計
   */
  readonly dtg00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 税率6.24％適用分
   */
  readonly dtg00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 税率7.8％適用分
   */
  readonly dtg00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * (15)のうち、課税売上げと非課税売上げに共通して要するもの
   * 合計
   */
  readonly dtg00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 税率6.24％適用分
   */
  readonly dtg00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 税率7.8％適用分
   */
  readonly dtg00120: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 個別対応方式
   * 個別対応方式により控除する課税仕入れ等の税額
   * 合計
   */
  readonly dtg00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 税率6.24％適用分
   */
  readonly dtg00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 税率7.8％適用分
   */
  readonly dtg00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税売上高が５億円超又は課税売上割合が95％未満の場合
   * 一括比例配分方式により控除する課税仕入れ等の税額
   * 合計
   */
  readonly dtg00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 税率6.24％適用分
   */
  readonly dth00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 税率7.8％適用分
   */
  readonly dth00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額
   * 合計
   */
  readonly dth00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 税率6.24％適用分
   */
  readonly dth00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 税率7.8％適用分
   */
  readonly dth00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額
   * 合計
   */
  readonly dth00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 税率6.24％適用分
   */
  readonly dth00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 税率7.8％適用分
   */
  readonly dth00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除税額の調整
   * 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額
   * 合計
   */
  readonly dth00120: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 税率6.24％適用分
   */
  readonly dti00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 税率7.8％適用分
   */
  readonly dti00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除対象仕入税額
   * 合計
   */
  readonly dti00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 税率6.24％適用分
   */
  readonly dti00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 税率7.8％適用分
   */
  readonly dti00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 差引
   * 控除過大調整税額
   * 合計
   */
  readonly dti00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 税率6.24％適用分
   */
  readonly dtj00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 税率7.8％適用分
   */
  readonly dtj00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額
   * 合計
   */
  readonly dtj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表2-3　課税売上割合・控除対象仕入税額等の計算表 */
export type Shb033_V2_0 = Node & {
  readonly __typename: "Shb033_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly dta00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly dta00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly dta00040: Maybe<Scalars["String"]["output"]>;
  /** 課税売上額（税抜き） 税率6.24％適用分 */
  readonly dtb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 税率7.8％適用分 */
  readonly dtb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上額（税抜き） 合計 */
  readonly dtb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 免税売上額 */
  readonly dtb00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 非課税資産の輸出等の金額、海外支店等へ移送した資産の価額 */
  readonly dtb00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly dtb00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 */
  readonly dtc00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 非課税売上額 */
  readonly dtc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 資産の譲渡等の対価の額 */
  readonly dtc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合 */
  readonly dtd00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率6.24％適用分 */
  readonly dte00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 税率7.8％適用分 */
  readonly dte00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る支払対価の額（税込み） 合計 */
  readonly dte00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率6.24％適用分 */
  readonly dte00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 税率7.8％適用分 */
  readonly dte00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れに係る消費税額 合計 */
  readonly dte00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 税率7.8％適用分 */
  readonly dte00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る支払対価の額 合計 */
  readonly dte00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 税率7.8％適用分 */
  readonly dte00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 特定課税仕入れに係る消費税額 合計 */
  readonly dte00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 税率6.24％適用分 */
  readonly dte00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 税率7.8％適用分 */
  readonly dte00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税貨物に係る消費税額 合計 */
  readonly dte00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 税率6.24％適用分 */
  readonly dte00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 税率7.8％適用分 */
  readonly dte00210: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税義務の免除を受けない（受ける）こととなった場合における消費税額の調整（加算又は減算）額 合計 */
  readonly dte00220: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率6.24％適用分 */
  readonly dte00240: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 税率7.8％適用分 */
  readonly dte00250: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 合計 */
  readonly dte00260: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率6.24％適用分 */
  readonly dte00280: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 税率7.8％適用分 */
  readonly dte00290: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置の適用を受ける課税仕入れに係る支払対価の額（税込み） 合計 */
  readonly dte00300: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率6.24％適用分 */
  readonly dte00320: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 税率7.8％適用分 */
  readonly dte00330: Maybe<Scalars["BigInt"]["output"]>;
  /** 適格請求書発行事業者以外の者から行った課税仕入れに係る経過措置により課税仕入れに係る消費税額とみなされる額 合計 */
  readonly dte00340: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 税率6.24％適用分 */
  readonly dtf00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 税率7.8％適用分 */
  readonly dtf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上高が５億円以下、かつ、課税売上割合が95％以上の場合 合計 */
  readonly dtf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率6.24％適用分 */
  readonly dtg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 税率7.8％適用分 */
  readonly dtg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げにのみ要するもの 合計 */
  readonly dtg00050: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率6.24％適用分 */
  readonly dtg00070: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 税率7.8％適用分 */
  readonly dtg00080: Maybe<Scalars["BigInt"]["output"]>;
  /** (17)のうち、課税売上げと非課税売上げに共通して要するもの 合計 */
  readonly dtg00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率6.24％適用分 */
  readonly dtg00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 税率7.8％適用分 */
  readonly dtg00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 個別対応方式により控除する課税仕入れ等の税額 合計 */
  readonly dtg00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率6.24％適用分 */
  readonly dtg00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 税率7.8％適用分 */
  readonly dtg00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 一括比例配分方式により控除する課税仕入れ等の税額 合計 */
  readonly dtg00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 税率6.24％適用分 */
  readonly dth00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 税率7.8％適用分 */
  readonly dth00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税売上割合変動時の調整対象固定資産に係る消費税額の調整（加算又は減算）額 合計 */
  readonly dth00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 税率6.24％適用分 */
  readonly dth00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 税率7.8％適用分 */
  readonly dth00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 調整対象固定資産を課税業務用（非課税業務用）に転用した場合の調整（加算又は減算）額 合計 */
  readonly dth00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率6.24％適用分 */
  readonly dth00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 税率7.8％適用分 */
  readonly dth00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 居住用賃貸建物を課税賃貸用に供した（譲渡した）場合の加算額 合計 */
  readonly dth00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率6.24％適用分 */
  readonly dti00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 税率7.8％適用分 */
  readonly dti00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額 合計 */
  readonly dti00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率6.24％適用分 */
  readonly dti00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 税率7.8％適用分 */
  readonly dti00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除過大調整税額 合計 */
  readonly dti00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly dtj00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly dtj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 合計 */
  readonly dtj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表4-1　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb043_V2_0 = Node & {
  readonly __typename: "Shb043_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly csa00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly csa00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly csa00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税標準額 旧税率分小計
   * 千円未満切捨て
   */
  readonly csb00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率6.24％適用分
   * 千円未満切捨て
   */
  readonly csb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率7.8％適用分
   * 千円未満切捨て
   */
  readonly csb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 合計 */
  readonly csb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 旧税率分小計 */
  readonly csc00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率6.24％適用分 */
  readonly csc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率7.8％適用分 */
  readonly csc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 合計 */
  readonly csc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 旧税率分小計 */
  readonly csd00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率6.24％適用分 */
  readonly csd00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率7.8％適用分 */
  readonly csd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 合計 */
  readonly csd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 旧税率分小計 */
  readonly cse00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly cse00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly cse00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 合計 */
  readonly cse00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 旧税率分小計 */
  readonly csf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 税率6.24％適用分 */
  readonly csf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 税率7.8％適用分 */
  readonly csf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 合計 */
  readonly csf00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 旧税率分小計 */
  readonly csf00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 税率6.24％適用分 */
  readonly csf00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 税率7.8％適用分 */
  readonly csf00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 合計 */
  readonly csf00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 旧税率分小計 */
  readonly csf00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 税率6.24％適用分 */
  readonly csf00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 税率7.8％適用分 */
  readonly csf00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 合計 */
  readonly csf00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 旧税率分小計 */
  readonly csf00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 税率6.24％適用分 */
  readonly csf00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 税率7.8％適用分 */
  readonly csf00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 合計 */
  readonly csf00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 旧税率分小計 */
  readonly csg00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率6.24％適用分 */
  readonly csg00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率7.8％適用分 */
  readonly csg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 合計 */
  readonly csg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 旧税率分小計 */
  readonly csh00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率6.24％適用分 */
  readonly csh00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率7.8％適用分 */
  readonly csh00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 合計 */
  readonly csh00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引税額 */
  readonly csi00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　控除不足還付税額 旧税率分小計 */
  readonly csj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　控除不足還付税額 税率7.8％適用分 */
  readonly csj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　控除不足還付税額 合計 */
  readonly csj00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　差引税額 旧税率分小計 */
  readonly csj00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　差引税額 税率7.8％適用分 */
  readonly csj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　差引税額 合計 */
  readonly csj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 旧税率分小計 */
  readonly csk00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 税率7.8％適用分 */
  readonly csk00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 合計 */
  readonly csk00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　還付額 旧税率分小計 */
  readonly csl00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　還付額 税率7.8％適用分 */
  readonly csl00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　還付額 合計 */
  readonly csl00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　納税額 旧税率分小計 */
  readonly csl00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　納税額 税率7.8％適用分 */
  readonly csl00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　納税額 合計 */
  readonly csl00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引譲渡割額 */
  readonly csm00000: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表4-2　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb045_V3_0 = Node & {
  readonly __typename: "Shb045_V3_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly bba00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly bba00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly bba00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 課税標準額 税率3％適用分
   * 千円未満切捨て
   */
  readonly bbb00010: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率4％適用分
   * 千円未満切捨て
   */
  readonly bbb00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率6.3％適用分
   * 千円未満切捨て
   */
  readonly bbb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 旧税率分小計 */
  readonly bbb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率3％適用分 */
  readonly bbc00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率4％適用分 */
  readonly bbc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率6.3％適用分 */
  readonly bbc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 旧税率分小計 */
  readonly bbc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率3％適用分 */
  readonly bbd00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率4％適用分 */
  readonly bbd00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.3％適用分 */
  readonly bbd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 旧税率分小計 */
  readonly bbd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 税率3％適用分 */
  readonly bbe00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 税率4％適用分 */
  readonly bbe00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 税率6.3％適用分 */
  readonly bbe00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除対象仕入税額 旧税率分小計 */
  readonly bbe00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 税率3％適用分 */
  readonly bbe00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 税率4％適用分 */
  readonly bbe00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 税率6.3％適用分 */
  readonly bbe00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　返還等対価に係る税額 旧税率分小計 */
  readonly bbe00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 税率3％適用分 */
  readonly bbe00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 税率4％適用分 */
  readonly bbe00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 税率6.3％適用分 */
  readonly bbe00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　貸倒れに係る税額 旧税率分小計 */
  readonly bbe00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 税率3％適用分 */
  readonly bbe00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 税率4％適用分 */
  readonly bbe00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 税率6.3％適用分 */
  readonly bbe00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額　控除税額小計 旧税率分小計 */
  readonly bbe00200: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率3％適用分 */
  readonly bbf00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率4％適用分 */
  readonly bbf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 税率6.3％適用分 */
  readonly bbf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 旧税率分小計 */
  readonly bbf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率3％適用分 */
  readonly bbg00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率4％適用分 */
  readonly bbg00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 税率6.3％適用分 */
  readonly bbg00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 旧税率分小計 */
  readonly bbg00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　控除不足還付税額 税率4％適用分 */
  readonly bbi00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　控除不足還付税額 税率6.3％適用分 */
  readonly bbi00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　控除不足還付税額 旧税率分小計 */
  readonly bbi00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　差引税額 税率4％適用分 */
  readonly bbi00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　差引税額 税率6.3％適用分 */
  readonly bbi00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額　差引税額 旧税率分小計 */
  readonly bbi00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　還付額 税率4％適用分 */
  readonly bbj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　還付額 税率6.3％適用分 */
  readonly bbj00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　還付額 旧税率分小計 */
  readonly bbj00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　納税額 税率4％適用分 */
  readonly bbj00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　納税額 税率6.3％適用分 */
  readonly bbj00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額　納税額 旧税率分小計 */
  readonly bbj00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率3％適用分 */
  readonly bbl00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率4％適用分 */
  readonly bbl00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率6.3％適用分 */
  readonly bbl00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 旧税率分小計 */
  readonly bbl00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 税率4％適用分 */
  readonly bbm00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 税率6.3％適用分 */
  readonly bbm00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 合計差引地方消費税の課税標準となる消費税額 旧税率分小計 */
  readonly bbm00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表4-3　税率別消費税額計算表兼地方消費税の課税標準となる消費税額計算表 */
export type Shb047_V1_0 = Node & {
  readonly __typename: "Shb047_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly dua00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly dua00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly dua00040: Maybe<Scalars["String"]["output"]>;
  /** 課税標準額 税率6.24％適用分 */
  readonly dub00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 税率7.8％適用分 */
  readonly dub00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額 合計 */
  readonly dub00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率6.24％適用分 */
  readonly duc00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率7.8％適用分 */
  readonly duc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 合計 */
  readonly duc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率6.24％適用分 */
  readonly dud00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 税率7.8％適用分 */
  readonly dud00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 合計 */
  readonly dud00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly due00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly due00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 合計 */
  readonly due00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除対象仕入税額 税率6.24％適用分 */
  readonly duf00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除対象仕入税額 税率7.8％適用分 */
  readonly duf00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除対象仕入税額 合計 */
  readonly duf00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 返還等対価に係る税額 税率6.24％適用分 */
  readonly duf00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 返還等対価に係る税額 税率7.8％適用分 */
  readonly duf00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 返還等対価に係る税額 合計 */
  readonly duf00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 貸倒れに係る税額 税率6.24％適用分 */
  readonly duf00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 貸倒れに係る税額 税率7.8％適用分 */
  readonly duf00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 貸倒れに係る税額 合計 */
  readonly duf00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除税額小計 税率6.24％適用分 */
  readonly duf00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除税額小計 税率7.8％適用分 */
  readonly duf00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除税額 控除税額小計 合計 */
  readonly duf00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除不足還付税額 */
  readonly dug00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 差引税額 */
  readonly duh00000: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 控除不足還付税額 */
  readonly dui00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 地方消費税の課税標準となる消費税額 差引税額計 */
  readonly dui00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額 還付額 */
  readonly duj00010: Maybe<Scalars["BigInt"]["output"]>;
  /** 譲渡割額 納税額 */
  readonly duj00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表5-1　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb055_V2_0 = Node & {
  readonly __typename: "Shb055_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cta00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cta00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cta00040: Maybe<Scalars["String"]["output"]>;
  /** 課税標準額に対する消費税額 旧税率分小計 */
  readonly ctb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 税率6.24％適用分 */
  readonly ctb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 税率7.8％適用分 */
  readonly ctb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 合計 */
  readonly ctb00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 旧税率分小計 */
  readonly ctb00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly ctb00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly ctb00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 合計 */
  readonly ctb00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 旧税率分小計 */
  readonly ctb00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率6.24％適用分 */
  readonly ctb00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率7.8％適用分 */
  readonly ctb00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 合計 */
  readonly ctb00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 旧税率分小計 */
  readonly ctb00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率6.24％適用分 */
  readonly ctb00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率7.8％適用分 */
  readonly ctb00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 合計 */
  readonly ctb00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ２　１種類の事業の専業者の場合の控除対象仕入税額 区分
   * 1:90％ 2:80％ 3:70％ 4:60％ 5:50％ 6:40％
   */
  readonly ctc00010: Maybe<SimpleTaxationPurchasingRate>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 旧税率分小計 */
  readonly ctc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率6.24％適用分 */
  readonly ctc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率7.8％適用分 */
  readonly ctc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 合計 */
  readonly ctc00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 旧税率分小計 */
  readonly ctd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率6.24％適用分 */
  readonly ctd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率7.8％適用分 */
  readonly ctd00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 合計 */
  readonly ctd00060: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 旧税率分小計 */
  readonly ctd00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率6.24％適用分 */
  readonly ctd00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率7.8％適用分 */
  readonly ctd00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 合計　金額 */
  readonly ctd00120: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 合計　売上割合 0.0≦x≦100.0 */
  readonly ctd00130: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 旧税率分小計 */
  readonly ctd00150: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率6.24％適用分 */
  readonly ctd00160: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率7.8％適用分 */
  readonly ctd00170: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 合計　金額 */
  readonly ctd00190: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 合計　売上割合 0.0≦x≦100.0 */
  readonly ctd00200: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 旧税率分小計 */
  readonly ctd00220: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率6.24％適用分 */
  readonly ctd00230: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率7.8％適用分 */
  readonly ctd00240: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 合計　金額 */
  readonly ctd00260: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 合計　売上割合 0.0≦x≦100.0 */
  readonly ctd00270: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 旧税率分小計 */
  readonly ctd00290: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率6.24％適用分 */
  readonly ctd00300: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率7.8％適用分 */
  readonly ctd00310: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 合計　金額 */
  readonly ctd00330: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 合計　売上割合 0.0≦x≦100.0 */
  readonly ctd00340: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 旧税率分小計 */
  readonly ctd00360: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率6.24％適用分 */
  readonly ctd00370: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率7.8％適用分 */
  readonly ctd00380: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 合計　金額 */
  readonly ctd00400: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 合計　売上割合 0.0≦x≦100.0 */
  readonly ctd00410: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 旧税率分小計 */
  readonly ctd00430: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率6.24％適用分 */
  readonly ctd00440: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率7.8％適用分 */
  readonly ctd00450: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 合計　金額 */
  readonly ctd00470: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 合計　売上割合 0.0≦x≦100.0 */
  readonly ctd00480: Maybe<Scalars["Percentage"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 旧税率分小計 */
  readonly ctd00510: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率6.24％適用分 */
  readonly ctd00520: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率7.8％適用分 */
  readonly ctd00530: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 合計 */
  readonly ctd00540: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 旧税率分小計 */
  readonly ctd00560: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率6.24％適用分 */
  readonly ctd00570: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率7.8％適用分 */
  readonly ctd00580: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 合計 */
  readonly ctd00590: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 旧税率分小計 */
  readonly ctd00610: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率6.24％適用分 */
  readonly ctd00620: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率7.8％適用分 */
  readonly ctd00630: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 合計 */
  readonly ctd00640: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 旧税率分小計 */
  readonly ctd00660: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率6.24％適用分 */
  readonly ctd00670: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率7.8％適用分 */
  readonly ctd00680: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 合計 */
  readonly ctd00690: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 旧税率分小計 */
  readonly ctd00710: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率6.24％適用分 */
  readonly ctd00720: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率7.8％適用分 */
  readonly ctd00730: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 合計 */
  readonly ctd00740: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 旧税率分小計 */
  readonly ctd00760: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率6.24％適用分 */
  readonly ctd00770: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率7.8％適用分 */
  readonly ctd00780: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 合計 */
  readonly ctd00790: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 旧税率分小計 */
  readonly ctd00810: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率6.24％適用分 */
  readonly ctd00820: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率7.8％適用分 */
  readonly ctd00830: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 合計 */
  readonly ctd00840: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 旧税率分小計 */
  readonly cte00020: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率6.24％適用分 */
  readonly cte00030: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率7.8％適用分 */
  readonly cte00040: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 合計 */
  readonly cte00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * （イ）　１種類の事業で75％以上 区分
   * 1:90％ 2:80％ 3:70％ 4:60％ 5:50％ 6:40％
   */
  readonly cte00080: Maybe<SimpleTaxationPurchasingRate>;
  /** （イ）　１種類の事業で75％以上 旧税率分小計 */
  readonly cte00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 税率6.24％適用分 */
  readonly cte00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 税率7.8％適用分 */
  readonly cte00110: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 合計 */
  readonly cte00120: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 旧税率分小計 */
  readonly cte00150: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率6.24％適用分 */
  readonly cte00160: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率7.8％適用分 */
  readonly cte00170: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 合計 */
  readonly cte00180: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 旧税率分小計 */
  readonly cte00200: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率6.24％適用分 */
  readonly cte00210: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率7.8％適用分 */
  readonly cte00220: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 合計 */
  readonly cte00230: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 旧税率分小計 */
  readonly cte00250: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率6.24％適用分 */
  readonly cte00260: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率7.8％適用分 */
  readonly cte00270: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 合計 */
  readonly cte00280: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 旧税率分小計 */
  readonly cte00300: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率6.24％適用分 */
  readonly cte00310: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率7.8％適用分 */
  readonly cte00320: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 合計 */
  readonly cte00330: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 旧税率分小計 */
  readonly cte00350: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率6.24％適用分 */
  readonly cte00360: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率7.8％適用分 */
  readonly cte00370: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 合計 */
  readonly cte00380: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 旧税率分小計 */
  readonly cte00400: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率6.24％適用分 */
  readonly cte00410: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率7.8％適用分 */
  readonly cte00420: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 合計 */
  readonly cte00430: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 旧税率分小計 */
  readonly cte00450: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率6.24％適用分 */
  readonly cte00460: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率7.8％適用分 */
  readonly cte00470: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 合計 */
  readonly cte00480: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 旧税率分小計 */
  readonly cte00500: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率6.24％適用分 */
  readonly cte00510: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率7.8％適用分 */
  readonly cte00520: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 合計 */
  readonly cte00530: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 旧税率分小計 */
  readonly cte00550: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率6.24％適用分 */
  readonly cte00560: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率7.8％適用分 */
  readonly cte00570: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 合計 */
  readonly cte00580: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 旧税率分小計 */
  readonly cte00600: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率6.24％適用分 */
  readonly cte00610: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率7.8％適用分 */
  readonly cte00620: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 合計 */
  readonly cte00630: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 旧税率分小計 */
  readonly cte00650: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率6.24％適用分 */
  readonly cte00660: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率7.8％適用分 */
  readonly cte00670: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 合計 */
  readonly cte00680: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 旧税率分小計 */
  readonly cte00700: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率6.24％適用分 */
  readonly cte00710: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率7.8％適用分 */
  readonly cte00720: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 合計 */
  readonly cte00730: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 旧税率分小計 */
  readonly cte00750: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率6.24％適用分 */
  readonly cte00760: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率7.8％適用分 */
  readonly cte00770: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 合計 */
  readonly cte00780: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 旧税率分小計 */
  readonly cte00800: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率6.24％適用分 */
  readonly cte00810: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率7.8％適用分 */
  readonly cte00820: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 合計 */
  readonly cte00830: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 旧税率分小計 */
  readonly cte00850: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率6.24％適用分 */
  readonly cte00860: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率7.8％適用分 */
  readonly cte00870: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 合計 */
  readonly cte00880: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 旧税率分小計 */
  readonly cte00900: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率6.24％適用分 */
  readonly cte00910: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率7.8％適用分 */
  readonly cte00920: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 合計 */
  readonly cte00930: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表5-2　控除対象仕入税額等の計算表【経過措置対象課税資産の譲渡等を含む課税期間用】 */
export type Shb065_V3_0 = Node & {
  readonly __typename: "Shb065_V3_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly bca00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly bca00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly bca00040: Maybe<Scalars["String"]["output"]>;
  /** 課税標準額に対する消費税額 税率３％適用分 */
  readonly bcb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 税率４％適用分 */
  readonly bcb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 税率6.3％適用分 */
  readonly bcb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 旧税率分小計 */
  readonly bcb00050: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率３％適用分 */
  readonly bcb00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率４％適用分 */
  readonly bcb00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.3％適用分 */
  readonly bcb00090: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 旧税率分小計 */
  readonly bcb00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率３％適用分 */
  readonly bcb00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率４％適用分 */
  readonly bcb00130: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率6.3％適用分 */
  readonly bcb00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 旧税率分小計 */
  readonly bcb00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率３％適用分 */
  readonly bcb00170: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率４％適用分 */
  readonly bcb00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率6.3％適用分 */
  readonly bcb00190: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 旧税率分小計 */
  readonly bcb00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ２　１種類の事業の専業者の場合の控除対象仕入税額 区分
   * 1:90％ 2:80％ 3:70％ 4:60％ 5:50％ 6:40％
   */
  readonly bcc00010: Maybe<SimpleTaxationPurchasingRate>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率３％適用分 */
  readonly bcc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率４％適用分 */
  readonly bcc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率6.3％適用分 */
  readonly bcc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 旧税率分小計 */
  readonly bcc00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率３％適用分 */
  readonly bcd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率４％適用分 */
  readonly bcd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率6.3％適用分 */
  readonly bcd00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 旧税率分小計 */
  readonly bcd00060: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率３％適用分 */
  readonly bcd00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率４％適用分 */
  readonly bcd00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率6.3％適用分 */
  readonly bcd00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 旧税率分小計 */
  readonly bcd00110: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率３％適用分 */
  readonly bcd00140: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率４％適用分 */
  readonly bcd00150: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率6.3％適用分 */
  readonly bcd00160: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 旧税率分小計 */
  readonly bcd00170: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率３％適用分 */
  readonly bcd00200: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率４％適用分 */
  readonly bcd00210: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率6.3％適用分 */
  readonly bcd00220: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 旧税率分小計 */
  readonly bcd00230: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率３％適用分 */
  readonly bcd00260: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率４％適用分 */
  readonly bcd00270: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率6.3％適用分 */
  readonly bcd00280: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 旧税率分小計 */
  readonly bcd00290: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率３％適用分 */
  readonly bcd00320: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率４％適用分 */
  readonly bcd00330: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率6.3％適用分 */
  readonly bcd00340: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 旧税率分小計 */
  readonly bcd00350: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率３％適用分 */
  readonly bcd00390: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率４％適用分 */
  readonly bcd00400: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率6.3％適用分 */
  readonly bcd00410: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 旧税率分小計 */
  readonly bcd00420: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率３％適用分 */
  readonly bcd00440: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率４％適用分 */
  readonly bcd00450: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率6.3％適用分 */
  readonly bcd00460: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 旧税率分小計 */
  readonly bcd00470: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率３％適用分 */
  readonly bcd00490: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率４％適用分 */
  readonly bcd00500: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率6.3％適用分 */
  readonly bcd00510: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 旧税率分小計 */
  readonly bcd00520: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率３％適用分 */
  readonly bcd00540: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率４％適用分 */
  readonly bcd00550: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率6.3％適用分 */
  readonly bcd00560: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 旧税率分小計 */
  readonly bcd00570: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率３％適用分 */
  readonly bcd00590: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率４％適用分 */
  readonly bcd00600: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率6.3％適用分 */
  readonly bcd00610: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 旧税率分小計 */
  readonly bcd00620: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率３％適用分 */
  readonly bcd00640: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率４％適用分 */
  readonly bcd00650: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率6.3％適用分 */
  readonly bcd00660: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 旧税率分小計 */
  readonly bcd00670: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率３％適用分 */
  readonly bcd00690: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率４％適用分 */
  readonly bcd00700: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率6.3％適用分 */
  readonly bcd00710: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 旧税率分小計 */
  readonly bcd00720: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率３％適用分 */
  readonly bcd00750: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率４％適用分 */
  readonly bcd00760: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率6.3％適用分 */
  readonly bcd00770: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 旧税率分小計 */
  readonly bcd00780: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率３％適用分 */
  readonly bce00020: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率４％適用分 */
  readonly bce00030: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率6.3％適用分 */
  readonly bce00040: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 旧税率分小計 */
  readonly bce00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * （イ）　１種類の事業で75％以上 区分
   * 1:90％ 2:80％ 3:70％ 4:60％ 5:50％ 6:40％
   */
  readonly bce00080: Maybe<SimpleTaxationPurchasingRate>;
  /** （イ）　１種類の事業で75％以上 税率３％適用分 */
  readonly bce00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 税率４％適用分 */
  readonly bce00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 税率6.3％適用分 */
  readonly bce00110: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 旧税率分小計 */
  readonly bce00120: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率３％適用分 */
  readonly bce00150: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率４％適用分 */
  readonly bce00160: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率6.3％適用分 */
  readonly bce00170: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 旧税率分小計 */
  readonly bce00180: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率３％適用分 */
  readonly bce00200: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率４％適用分 */
  readonly bce00210: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率6.3％適用分 */
  readonly bce00220: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 旧税率分小計 */
  readonly bce00230: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率３％適用分 */
  readonly bce00250: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率４％適用分 */
  readonly bce00260: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率6.3％適用分 */
  readonly bce00270: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 旧税率分小計 */
  readonly bce00280: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率３％適用分 */
  readonly bce00300: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率４％適用分 */
  readonly bce00310: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率6.3％適用分 */
  readonly bce00320: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 旧税率分小計 */
  readonly bce00330: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率３％適用分 */
  readonly bce00350: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率４％適用分 */
  readonly bce00360: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率6.3％適用分 */
  readonly bce00370: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 旧税率分小計 */
  readonly bce00380: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率３％適用分 */
  readonly bce00400: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率４％適用分 */
  readonly bce00410: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率6.3％適用分 */
  readonly bce00420: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 旧税率分小計 */
  readonly bce00430: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率３％適用分 */
  readonly bce00450: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率４％適用分 */
  readonly bce00460: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率6.3％適用分 */
  readonly bce00470: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 旧税率分小計 */
  readonly bce00480: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率３％適用分 */
  readonly bce00500: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率４％適用分 */
  readonly bce00510: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率6.3％適用分 */
  readonly bce00520: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 旧税率分小計 */
  readonly bce00530: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率３％適用分 */
  readonly bce00550: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率４％適用分 */
  readonly bce00560: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率6.3％適用分 */
  readonly bce00570: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 旧税率分小計 */
  readonly bce00580: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率３％適用分 */
  readonly bce00600: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率４％適用分 */
  readonly bce00610: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率6.3％適用分 */
  readonly bce00620: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 旧税率分小計 */
  readonly bce00630: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率３％適用分 */
  readonly bce00650: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率４％適用分 */
  readonly bce00660: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率6.3％適用分 */
  readonly bce00670: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 旧税率分小計 */
  readonly bce00680: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率３％適用分 */
  readonly bce00700: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率４％適用分 */
  readonly bce00710: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率6.3％適用分 */
  readonly bce00720: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 旧税率分小計 */
  readonly bce00730: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率３％適用分 */
  readonly bce00750: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率４％適用分 */
  readonly bce00760: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率6.3％適用分 */
  readonly bce00770: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 旧税率分小計 */
  readonly bce00780: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率３％適用分 */
  readonly bce00800: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率４％適用分 */
  readonly bce00810: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率6.3％適用分 */
  readonly bce00820: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 旧税率分小計 */
  readonly bce00830: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率３％適用分 */
  readonly bce00850: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率４％適用分 */
  readonly bce00860: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率6.3％適用分 */
  readonly bce00870: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 旧税率分小計 */
  readonly bce00880: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率３％適用分 */
  readonly bce00900: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率４％適用分 */
  readonly bce00910: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率6.3％適用分 */
  readonly bce00920: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 旧税率分小計 */
  readonly bce00930: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表5-3　控除対象仕入税額等の計算表 */
export type Shb067_V1_0 = Node & {
  readonly __typename: "Shb067_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly dva00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly dva00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly dva00040: Maybe<Scalars["String"]["output"]>;
  /** 課税標準額に対する消費税額 税率6.24％適用分 */
  readonly dvb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 税率7.8％適用分 */
  readonly dvb00030: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税標準額に対する消費税額 合計 */
  readonly dvb00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly dvb00060: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly dvb00070: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 合計 */
  readonly dvb00080: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率6.24％適用分 */
  readonly dvb00100: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率7.8％適用分 */
  readonly dvb00110: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 合計 */
  readonly dvb00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率6.24％適用分 */
  readonly dvb00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 税率7.8％適用分 */
  readonly dvb00150: Maybe<Scalars["BigInt"]["output"]>;
  /** 控除対象仕入税額の計算の基礎となる消費税額 合計 */
  readonly dvb00160: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ２　１種類の事業の専業者の場合の控除対象仕入税額 区分
   * 1:90％ 2:80％ 3:70％ 4:60％ 5:50％ 6:40％
   */
  readonly dvc00010: Maybe<SimpleTaxationPurchasingRate>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率6.24％適用分 */
  readonly dvc00020: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 税率7.8％適用分 */
  readonly dvc00030: Maybe<Scalars["BigInt"]["output"]>;
  /** ２　１種類の事業の専業者の場合の控除対象仕入税額 合計 */
  readonly dvc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率6.24％適用分 */
  readonly dvd00030: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 税率7.8％適用分 */
  readonly dvd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　事業区分別の合計額 合計 */
  readonly dvd00050: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率6.24％適用分 */
  readonly dvd00070: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 税率7.8％適用分 */
  readonly dvd00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 合計　金額 */
  readonly dvd00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第一種事業 合計　売上割合 */
  readonly dvd00110: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率6.24％適用分 */
  readonly dvd00130: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 税率7.8％適用分 */
  readonly dvd00140: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 合計　金額 */
  readonly dvd00160: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第二種事業 合計　売上割合 */
  readonly dvd00170: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率6.24％適用分 */
  readonly dvd00190: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 税率7.8％適用分 */
  readonly dvd00200: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 合計　金額 */
  readonly dvd00220: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第三種事業 合計　売上割合 */
  readonly dvd00230: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率6.24％適用分 */
  readonly dvd00250: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 税率7.8％適用分 */
  readonly dvd00260: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 合計　金額 */
  readonly dvd00280: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第四種事業 合計　売上割合 */
  readonly dvd00290: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率6.24％適用分 */
  readonly dvd00310: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 税率7.8％適用分 */
  readonly dvd00320: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 合計　金額 */
  readonly dvd00340: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第五種事業 合計　売上割合 */
  readonly dvd00350: Maybe<Scalars["Percentage"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率6.24％適用分 */
  readonly dvd00370: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 税率7.8％適用分 */
  readonly dvd00380: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 合計　金額 */
  readonly dvd00400: Maybe<Scalars["BigInt"]["output"]>;
  /** （１）　事業区分別の課税売上高（税抜き）の明細　第六種事業 合計　売上割合 */
  readonly dvd00410: Maybe<Scalars["Percentage"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率6.24％適用分 */
  readonly dvd00440: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 税率7.8％適用分 */
  readonly dvd00450: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　事業区分別の合計額 合計 */
  readonly dvd00460: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率6.24％適用分 */
  readonly dvd00480: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 税率7.8％適用分 */
  readonly dvd00490: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第一種事業 合計 */
  readonly dvd00500: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率6.24％適用分 */
  readonly dvd00520: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 税率7.8％適用分 */
  readonly dvd00530: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第二種事業 合計 */
  readonly dvd00540: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率6.24％適用分 */
  readonly dvd00560: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 税率7.8％適用分 */
  readonly dvd00570: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第三種事業 合計 */
  readonly dvd00580: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率6.24％適用分 */
  readonly dvd00600: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 税率7.8％適用分 */
  readonly dvd00610: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第四種事業 合計 */
  readonly dvd00620: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率6.24％適用分 */
  readonly dvd00640: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 税率7.8％適用分 */
  readonly dvd00650: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第五種事業 合計 */
  readonly dvd00660: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率6.24％適用分 */
  readonly dvd00680: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 税率7.8％適用分 */
  readonly dvd00690: Maybe<Scalars["BigInt"]["output"]>;
  /** （２）　（１）の事業区分別の課税売上高に係る消費税額の明細　第六種事業 合計 */
  readonly dvd00700: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率6.24％適用分 */
  readonly dve00020: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 税率7.8％適用分 */
  readonly dve00030: Maybe<Scalars["BigInt"]["output"]>;
  /** イ　原則計算を適用する場合 合計 */
  readonly dve00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * （イ）　１種類の事業で75％以上 区分
   * 1:90％ 2:80％ 3:70％ 4:60％ 5:50％ 6:40％
   */
  readonly dve00070: Maybe<SimpleTaxationPurchasingRate>;
  /** （イ）　１種類の事業で75％以上 税率6.24％適用分 */
  readonly dve00080: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 税率7.8％適用分 */
  readonly dve00090: Maybe<Scalars["BigInt"]["output"]>;
  /** （イ）　１種類の事業で75％以上 合計 */
  readonly dve00100: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率6.24％適用分 */
  readonly dve00130: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 税率7.8％適用分 */
  readonly dve00140: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第二種事業 合計 */
  readonly dve00150: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率6.24％適用分 */
  readonly dve00170: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 税率7.8％適用分 */
  readonly dve00180: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第三種事業 合計 */
  readonly dve00190: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率6.24％適用分 */
  readonly dve00210: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 税率7.8％適用分 */
  readonly dve00220: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第四種事業 合計 */
  readonly dve00230: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率6.24％適用分 */
  readonly dve00250: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 税率7.8％適用分 */
  readonly dve00260: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第五種事業 合計 */
  readonly dve00270: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率6.24％適用分 */
  readonly dve00290: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 税率7.8％適用分 */
  readonly dve00300: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第一種事業及び第六種事業 合計 */
  readonly dve00310: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率6.24％適用分 */
  readonly dve00330: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 税率7.8％適用分 */
  readonly dve00340: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第三種事業 合計 */
  readonly dve00350: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率6.24％適用分 */
  readonly dve00370: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 税率7.8％適用分 */
  readonly dve00380: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第四種事業 合計 */
  readonly dve00390: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率6.24％適用分 */
  readonly dve00410: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 税率7.8％適用分 */
  readonly dve00420: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第五種事業 合計 */
  readonly dve00430: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率6.24％適用分 */
  readonly dve00450: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 税率7.8％適用分 */
  readonly dve00460: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第二種事業及び第六種事業 合計 */
  readonly dve00470: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率6.24％適用分 */
  readonly dve00490: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 税率7.8％適用分 */
  readonly dve00500: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第四種事業 合計 */
  readonly dve00510: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率6.24％適用分 */
  readonly dve00530: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 税率7.8％適用分 */
  readonly dve00540: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第五種事業 合計 */
  readonly dve00550: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率6.24％適用分 */
  readonly dve00570: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 税率7.8％適用分 */
  readonly dve00580: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第三種事業及び第六種事業 合計 */
  readonly dve00590: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率6.24％適用分 */
  readonly dve00610: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 税率7.8％適用分 */
  readonly dve00620: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第五種事業 合計 */
  readonly dve00630: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率6.24％適用分 */
  readonly dve00650: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 税率7.8％適用分 */
  readonly dve00660: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第四種事業及び第六種事業 合計 */
  readonly dve00670: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率6.24％適用分 */
  readonly dve00690: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 税率7.8％適用分 */
  readonly dve00700: Maybe<Scalars["BigInt"]["output"]>;
  /** （ロ）　２種類の事業で75％以上　第五種事業及び第六種事業 合計 */
  readonly dve00710: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率6.24％適用分 */
  readonly dve00730: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 税率7.8％適用分 */
  readonly dve00740: Maybe<Scalars["BigInt"]["output"]>;
  /** ハ　上記の計算式区分から選択した控除対象仕入税額 合計 */
  readonly dve00750: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 付表6　税率別消費税額計算表【小規模事業者に係る税額控除に関する経過措置を適用する課税期間用】 */
export type Shb070_V1_0 = Node & {
  readonly __typename: "Shb070_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly aya00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly aya00030: Scalars["Date"]["output"];
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly aya00040: Maybe<Scalars["String"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率6.24％適用分 */
  readonly ayb00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税資産の譲渡等の対価の額 税率7.8％適用分 */
  readonly ayb00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税資産の譲渡等の対価の額 合計
   * 【計算】AYB00020＋AYB00030
   */
  readonly ayb00040: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率6.24％適用分
   * 千円未満切捨て
   */
  readonly ayb00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 税率7.8％適用分
   * 千円未満切捨て
   */
  readonly ayb00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額 合計
   * 【計算】AYB00060＋AYB00070(千円未満切捨て)
   */
  readonly ayb00080: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額に対する消費税額 税率6.24％適用分
   * 【計算】AYB00060×6.24／100(小数点以下切り捨て)
   */
  readonly ayb00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額に対する消費税額 税率7.8％適用分
   * 【計算】AYB00070×7.8／100(小数点以下切り捨て)
   */
  readonly ayb00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 課税標準額に対する消費税額 合計
   * 【計算】AYB00100＋AYB00110
   */
  readonly ayb00120: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率6.24％適用分 */
  readonly ayb00140: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒回収に係る消費税額 税率7.8％適用分 */
  readonly ayb00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒回収に係る消費税額 合計
   * 【計算】AYB00140＋AYB00150
   */
  readonly ayb00160: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率6.24％適用分 */
  readonly ayb00180: Maybe<Scalars["BigInt"]["output"]>;
  /** 売上対価の返還等に係る消費税額 税率7.8％適用分 */
  readonly ayb00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 売上対価の返還等に係る消費税額 合計
   * 【計算】AYB00180＋AYB00190
   */
  readonly ayb00200: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除対象仕入税額の計算の基礎となる消費税額 税率6.24％適用分
   * 【計算】AYB00100＋AYB00140－AYB00180(マイナスは「0」表示)
   */
  readonly ayb00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除対象仕入税額の計算の基礎となる消費税額 税率7.8％適用分
   * 【計算】AYB00110＋AYB00150－AYB00190(マイナスは「0」表示)
   */
  readonly ayb00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 控除対象仕入税額の計算の基礎となる消費税額 合計
   * 【計算】AYB00220＋AYB00230
   */
  readonly ayb00240: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 特別控除税額 税率6.24％適用分
   * 【計算】AYB00220×80／100(小数点以下切り捨て)
   */
  readonly ayc00020: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 特別控除税額 税率7.8％適用分
   * 【計算】AYB00230×80／100(小数点以下切り捨て)
   */
  readonly ayc00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 特別控除税額 合計
   * 【計算】AYC00020＋AYC00030
   */
  readonly ayc00040: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒れに係る税額 税率6.24％適用分 */
  readonly ayd00020: Maybe<Scalars["BigInt"]["output"]>;
  /** 貸倒れに係る税額 税率7.8％適用分 */
  readonly ayd00030: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * 貸倒れに係る税額 合計
   * 【計算】AYD00020＋AYD00030
   */
  readonly ayd00040: Maybe<Scalars["BigInt"]["output"]>;
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税の還付申告に関する明細書(法人用) */
export type Shc015_V1_0 = Node & {
  readonly __typename: "Shc015_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cia00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cia00030: Scalars["Date"]["output"];
  /**
   * 所在地
   * NOZEISHA_ADR
   */
  readonly cia00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cia00050: Maybe<Scalars["String"]["output"]>;
  /**
   * １ 還付申告となった主な理由
   * 輸出等の免税取引の割合が高い
   * kubun
   */
  readonly cib00010: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * 設備投資
   * kubun
   */
  readonly cib00020: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 該当区分
   * kubun
   */
  readonly cib00040: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 その他内容
   * string(60)
   */
  readonly cib00050: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 取引金額等(税込・税抜)
   * kubun
   */
  readonly cic00020: Maybe<SimpleAccountingMethod>;
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 明細　繰り返し 10
   */
  readonly cic00030: Maybe<ReadonlyArray<Shc015_V1_0_Cic00030>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細
   * 明細　繰り返し 10
   */
  readonly cic00110: Maybe<ReadonlyArray<Shc015_V1_0_Cic00110>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関名 string(15)
   */
  readonly cic00190: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関区分 kubun
   */
  readonly cic00200: Maybe<BankType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 支店名 string(15)
   */
  readonly cic00210: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 本支店区分 kubun
   */
  readonly cic00220: Maybe<BankBranchType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 預金種類 kubun
   */
  readonly cic00230: Maybe<BankAccountType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 口座番号 string(14)
   */
  readonly cic00240: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 氏名(名称) name
   */
  readonly cic00260: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 住所(所在地) address
   */
  readonly cic00270: Scalars["String"]["output"];
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * (イ) 決算額(税込・税抜) kubun
   */
  readonly cid00020: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 商品仕入高等
   * (イ) 決算額 kingaku
   */
  readonly cid00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 商品仕入高等
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 商品仕入高等
   * 課税仕入高 kingaku
   */
  readonly cid00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 販売費・一般管理費
   * (イ) 決算額 kingaku
   */
  readonly cid00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 販売費・一般管理費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 販売費・一般管理費
   * 課税仕入高 kingaku
   */
  readonly cid00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 営業外費用
   * (イ) 決算額 kingaku
   */
  readonly cid00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 営業外費用
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 営業外費用
   * 課税仕入高 kingaku
   */
  readonly cid00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 その他
   * (イ) 決算額 kingaku
   */
  readonly cid00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 その他
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 その他
   * 課税仕入高 kingaku
   */
  readonly cid00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 小計
   * (イ) 決算額 kingaku
   */
  readonly cid00210: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 小計
   * 課税仕入高 kingaku
   */
  readonly cid00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * (イ) 資産の取得価額(税込・税抜) kubun
   */
  readonly cid00240: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 固定資産
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00270: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 固定資産
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00280: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 固定資産
   * 課税仕入高 kingaku
   */
  readonly cid00290: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 繰延資産
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00310: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 繰延資産
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00320: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 繰延資産
   * 課税仕入高 kingaku
   */
  readonly cid00330: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 その他
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00350: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 その他
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00360: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 その他
   * 課税仕入高 kingaku
   */
  readonly cid00370: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 小計
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00390: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00400: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 小計
   * 課税仕入高 kingaku
   */
  readonly cid00410: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 */
  readonly cid00420: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * (2) 主な棚卸資産・原材料等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cid00440: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (2) 主な棚卸資産・原材料等の取得
   * 明細　繰り返し
   */
  readonly cid00450: Maybe<ReadonlyArray<Shc015_V1_0_Cid00450>>;
  /**
   * (3) 主な固定資産等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cid00530: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (3) 主な固定資産等の取得
   * 明細　繰り返し
   */
  readonly cid00540: Maybe<ReadonlyArray<Shc015_V1_0_Cid00540>>;
  /**
   * ４ 当課税期間中の特殊事情
   * string(200)
   */
  readonly cie00000: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** User settings for 該当あり/該当なし of each item list */
  readonly itemListSetting: RefundDeclarationItemListSetting;
};

/**
 * ２ 課税売上げ等に係る事項
 * (1) 主な課税資産の譲渡等
 * 明細　繰り返し
 */
export type Shc015_V1_0_Cic00030 = Node & {
  readonly __typename: "Shc015_V1_0_Cic00030";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cic00040: Scalars["String"]["output"];
  /**
   * 譲渡年月日等
   * yymmdd
   */
  readonly cic00050: Maybe<Scalars["Date"]["output"]>;
  /**
   * 継続欄
   * string(2)
   */
  readonly cic00060: Scalars["String"]["output"];
  /**
   * 取引金額等
   * kingaku
   */
  readonly cic00070: Scalars["BigInt"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cic00080: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cic00090: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ２ 課税売上げ等に係る事項
 * (2) 主な輸出取引等の明細
 * 明細　繰り返し
 */
export type Shc015_V1_0_Cic00110 = Node & {
  readonly __typename: "Shc015_V1_0_Cic00110";
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cic00120: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cic00130: Scalars["String"]["output"];
  /**
   * 取引金額
   * kingaku
   */
  readonly cic00140: Scalars["BigInt"]["output"];
  /**
   * 主な取引商品等
   * string(40)
   */
  readonly cic00150: Scalars["String"]["output"];
  /**
   * 所轄税関(支署)名
   * string(20)
   */
  readonly cic00160: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (2) 主な棚卸資産・原材料等の取得
 * 明細　繰り返し
 */
export type Shc015_V1_0_Cid00450 = Node & {
  readonly __typename: "Shc015_V1_0_Cid00450";
  /** 資産の種類等 */
  readonly cid00460: Scalars["String"]["output"];
  /** 取得年月日等 */
  readonly cid00470: Maybe<Scalars["Date"]["output"]>;
  /** 継続欄 */
  readonly cid00480: Scalars["String"]["output"];
  /** 取引金額等 */
  readonly cid00490: Scalars["BigInt"]["output"];
  /** 取引先の氏名(名称) */
  readonly cid00500: Scalars["String"]["output"];
  /** 取引先の住所(所在地) */
  readonly cid00510: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (3) 主な固定資産等の取得
 * 明細　繰り返し
 */
export type Shc015_V1_0_Cid00540 = Node & {
  readonly __typename: "Shc015_V1_0_Cid00540";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cid00550: Scalars["String"]["output"];
  /**
   * 取得年月日等
   * yymmdd
   */
  readonly cid00560: Maybe<Scalars["Date"]["output"]>;
  /**
   * 取引金額等
   * kingaku
   */
  readonly cid00570: Scalars["BigInt"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cid00580: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cid00590: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税の還付申告に関する明細書(法人用) */
export type Shc015_V2_0 = Node & {
  readonly __typename: "Shc015_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cia00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cia00030: Scalars["Date"]["output"];
  /**
   * 所在地
   * NOZEISHA_ADR
   */
  readonly cia00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cia00050: Maybe<Scalars["String"]["output"]>;
  /**
   * １ 還付申告となった主な理由
   * 輸出等の免税取引の割合が高い
   * kubun
   */
  readonly cib00010: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * 設備投資
   * kubun
   */
  readonly cib00020: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 該当区分
   * kubun
   */
  readonly cib00040: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 その他内容
   * string(60)
   */
  readonly cib00050: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 取引金額等(税込・税抜)
   * kubun
   */
  readonly cic00020: Maybe<SimpleAccountingMethod>;
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 明細　繰り返し 10
   */
  readonly cic00030: Maybe<ReadonlyArray<Shc015_V2_0_Cic00030>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細
   * 明細　繰り返し 10
   */
  readonly cic00110: Maybe<ReadonlyArray<Shc015_V2_0_Cic00110>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関名 string(15)
   */
  readonly cic00190: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関区分 kubun
   */
  readonly cic00200: Maybe<BankType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 支店名 string(15)
   */
  readonly cic00210: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 本支店区分 kubun
   */
  readonly cic00220: Maybe<BankBranchType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 預金種類 kubun
   */
  readonly cic00230: Maybe<BankAccountType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 口座番号 string(14)
   */
  readonly cic00240: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 氏名(名称) name
   */
  readonly cic00260: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 住所(所在地) address
   */
  readonly cic00270: Scalars["String"]["output"];
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * (イ) 決算額(税込・税抜) kubun
   */
  readonly cid00020: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 商品仕入高等
   * (イ) 決算額 kingaku
   */
  readonly cid00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 商品仕入高等
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 商品仕入高等
   * 課税仕入高 kingaku
   */
  readonly cid00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 販売費・一般管理費
   * (イ) 決算額 kingaku
   */
  readonly cid00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 販売費・一般管理費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 販売費・一般管理費
   * 課税仕入高 kingaku
   */
  readonly cid00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 営業外費用
   * (イ) 決算額 kingaku
   */
  readonly cid00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 営業外費用
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 営業外費用
   * 課税仕入高 kingaku
   */
  readonly cid00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 その他
   * (イ) 決算額 kingaku
   */
  readonly cid00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 その他
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 その他
   * 課税仕入高 kingaku
   */
  readonly cid00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 小計
   * (イ) 決算額 kingaku
   */
  readonly cid00210: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 損益科目 小計
   * 課税仕入高 kingaku
   */
  readonly cid00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * (イ) 資産の取得価額(税込・税抜) kubun
   */
  readonly cid00240: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 固定資産
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00270: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 固定資産
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00280: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 固定資産
   * 課税仕入高 kingaku
   */
  readonly cid00290: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 繰延資産
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00310: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 繰延資産
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00320: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 繰延資産
   * 課税仕入高 kingaku
   */
  readonly cid00330: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 その他
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00350: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 その他
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00360: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 その他
   * 課税仕入高 kingaku
   */
  readonly cid00370: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 小計
   * (イ) 資産の取得価額 kingaku
   */
  readonly cid00390: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cid00400: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 資産科目 小計
   * 課税仕入高 kingaku
   */
  readonly cid00410: Maybe<Scalars["BigInt"]["output"]>;
  /** 課税仕入れ等の税額の合計額 */
  readonly cid00420: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * (2) 主な棚卸資産・原材料等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cid00440: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (2) 主な棚卸資産・原材料等の取得
   * 明細　繰り返し
   */
  readonly cid00450: Maybe<ReadonlyArray<Shc015_V2_0_Cid00450>>;
  /**
   * (3) 主な固定資産等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cid00530: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (3) 主な固定資産等の取得
   * 明細　繰り返し
   */
  readonly cid00540: Maybe<ReadonlyArray<Shc015_V2_0_Cid00540>>;
  /**
   * ４ 当課税期間中の特殊事情
   * string(200)
   */
  readonly cie00000: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** User settings for 該当あり/該当なし of each item list */
  readonly itemListSetting: RefundDeclarationItemListSetting;
};

/**
 * ２ 課税売上げ等に係る事項
 * (1) 主な課税資産の譲渡等
 * 明細　繰り返し
 */
export type Shc015_V2_0_Cic00030 = Node & {
  readonly __typename: "Shc015_V2_0_Cic00030";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cic00040: Scalars["String"]["output"];
  /**
   * 譲渡年月日等
   * yymmdd
   */
  readonly cic00050: Maybe<Scalars["Date"]["output"]>;
  /**
   * 継続欄
   * string(2)
   */
  readonly cic00060: Scalars["String"]["output"];
  /**
   * 取引金額等
   * kingaku
   */
  readonly cic00070: Scalars["BigInt"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cic00080: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cic00090: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ２ 課税売上げ等に係る事項
 * (2) 主な輸出取引等の明細
 * 明細　繰り返し
 */
export type Shc015_V2_0_Cic00110 = Node & {
  readonly __typename: "Shc015_V2_0_Cic00110";
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cic00120: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cic00130: Scalars["String"]["output"];
  /**
   * 取引金額
   * kingaku
   */
  readonly cic00140: Scalars["BigInt"]["output"];
  /**
   * 主な取引商品等
   * string(40)
   */
  readonly cic00150: Scalars["String"]["output"];
  /**
   * 所轄税関(支署)名
   * string(20)
   */
  readonly cic00160: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (2) 主な棚卸資産・原材料等の取得
 * 明細　繰り返し
 */
export type Shc015_V2_0_Cid00450 = Node & {
  readonly __typename: "Shc015_V2_0_Cid00450";
  /** 資産の種類等 */
  readonly cid00460: Scalars["String"]["output"];
  /** 取得年月日等 */
  readonly cid00470: Maybe<Scalars["Date"]["output"]>;
  /** 継続欄 */
  readonly cid00480: Scalars["String"]["output"];
  /** 取引金額等 */
  readonly cid00490: Scalars["BigInt"]["output"];
  /** 取引先の登録番号 */
  readonly cid00495: Scalars["String"]["output"];
  /** 取引先の氏名(名称) */
  readonly cid00500: Scalars["String"]["output"];
  /** 取引先の住所(所在地) */
  readonly cid00510: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (3) 主な固定資産等の取得
 * 明細　繰り返し
 */
export type Shc015_V2_0_Cid00540 = Node & {
  readonly __typename: "Shc015_V2_0_Cid00540";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cid00550: Scalars["String"]["output"];
  /**
   * 取得年月日等
   * yymmdd
   */
  readonly cid00560: Maybe<Scalars["Date"]["output"]>;
  /**
   * 取引金額等
   * kingaku
   */
  readonly cid00570: Scalars["BigInt"]["output"];
  /** 取引先の登録番号 */
  readonly cid00575: Scalars["String"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cid00580: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cid00590: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税の還付申告に関する明細書(個人事業者用) */
export type Shc025_V1_0 = Node & {
  readonly __typename: "Shc025_V1_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cja00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cja00030: Scalars["Date"]["output"];
  /**
   * 所在地
   * NOZEISHA_ADR
   */
  readonly cja00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cja00050: Maybe<Scalars["String"]["output"]>;
  /**
   * １ 還付申告となった主な理由
   * 輸出等の免税取引の割合が高い
   * kubun
   */
  readonly cjb00010: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * 設備投資
   * kubun
   */
  readonly cjb00020: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 該当区分
   * kubun
   */
  readonly cjb00040: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 その他内容
   * string(60)
   */
  readonly cjb00050: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 取引金額等(税込・税抜)
   * kubun
   */
  readonly cjc00020: Maybe<SimpleAccountingMethod>;
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 明細　繰り返し 10
   */
  readonly cjc00030: Maybe<ReadonlyArray<Shc025_V1_0_Cjc00030>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細
   * 明細　繰り返し 10
   */
  readonly cjc00110: Maybe<ReadonlyArray<Shc025_V1_0_Cjc00110>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関名 string(15)
   */
  readonly cjc00190: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関区分 kubun
   */
  readonly cjc00200: Maybe<BankType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 支店名 string(15)
   */
  readonly cjc00210: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 本支店区分 kubun
   */
  readonly cjc00220: Maybe<BankBranchType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 預金種類 kubun
   */
  readonly cjc00230: Maybe<BankAccountType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 口座番号 string(14)
   */
  readonly cjc00240: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 氏名(名称) name
   */
  readonly cjc00260: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 住所(所在地) address
   */
  readonly cjc00270: Scalars["String"]["output"];
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * (イ) 決算額(税込・税抜) kubun
   */
  readonly cjd00020: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 仕入金額
   * (イ) 決算額 kingaku
   */
  readonly cjd00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 仕入金額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 仕入金額
   * 課税仕入高 kingaku
   */
  readonly cjd00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 必要経費
   * (イ) 決算額 kingaku
   */
  readonly cjd00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 必要経費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 必要経費
   * 課税仕入高 kingaku
   */
  readonly cjd00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 固定資産等の取得価額
   * (イ) 決算額 kingaku
   */
  readonly cjd00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 固定資産等の取得価額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 固定資産等の取得価額
   * 課税仕入高 kingaku
   */
  readonly cjd00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 小計
   * (イ) 決算額 kingaku
   */
  readonly cjd00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 小計
   * 課税仕入高 kingaku
   */
  readonly cjd00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 必要経費
   * (イ) 決算額 kingaku
   */
  readonly cjd00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 必要経費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 必要経費
   * 課税仕入高 kingaku
   */
  readonly cjd00240: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 固定資産等の取得価額
   * (イ) 決算額 kingaku
   */
  readonly cjd00260: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 固定資産等の取得価額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00270: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 固定資産等の取得価額
   * 課税仕入高 kingaku
   */
  readonly cjd00280: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 小計
   * (イ) 決算額 kingaku
   */
  readonly cjd00300: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00310: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 小計
   * 課税仕入高 kingaku
   */
  readonly cjd00320: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得
   * 所得項目名 string(8)
   */
  readonly cjd00340: Scalars["String"]["output"];
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 仕入金額
   * (イ) 決算額 kingaku
   */
  readonly cjd00360: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 仕入金額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00370: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 仕入金額
   * 課税仕入高 kingaku
   */
  readonly cjd00380: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 必要経費
   * (イ) 決算額 kingaku
   */
  readonly cjd00400: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 必要経費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00410: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 必要経費
   * 課税仕入高 kingaku
   */
  readonly cjd00420: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 固定資産等の取得価額
   * (イ) 決算額 kingaku
   */
  readonly cjd00440: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 固定資産等の取得価額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00450: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 固定資産等の取得価額
   * 課税仕入高 kingaku
   */
  readonly cjd00460: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * ＿所得 小計
   * (イ) 決算額 kingaku
   */
  readonly cjd00480: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * ＿所得 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00490: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * ＿所得 小計
   * 課税仕入高 kingaku
   */
  readonly cjd00500: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 課税仕入高の合計額 kingaku
   */
  readonly cjd00510: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 課税仕入れ等の税額の合計額 kingaku
   */
  readonly cjd00520: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * (2) 主な棚卸資産・原材料等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cjd00540: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (2) 主な棚卸資産・原材料等の取得
   * 明細　繰り返し
   */
  readonly cjd00550: Maybe<ReadonlyArray<Shc025_V1_0_Cjd00550>>;
  /**
   * ３ 課税仕入れに係る事項
   * (3) 主な固定資産等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cjd00630: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (3) 主な固定資産等の取得
   * 明細　繰り返し
   */
  readonly cjd00640: Maybe<ReadonlyArray<Shc025_V1_0_Cjd00640>>;
  /**
   * ４ ＿年中の特殊事情
   * ＿年 yy
   */
  readonly cje00010: Scalars["Date"]["output"];
  /**
   * ４ ＿年中の特殊事情
   * ＿年中の特殊事情 string(200)
   */
  readonly cje00020: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** User settings for 該当あり/該当なし of each item list */
  readonly itemListSetting: RefundDeclarationItemListSetting;
};

/**
 * ２ 課税売上げ等に係る事項
 * 明細　繰り返し
 */
export type Shc025_V1_0_Cjc00030 = Node & {
  readonly __typename: "Shc025_V1_0_Cjc00030";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cjc00040: Scalars["String"]["output"];
  /**
   * 譲渡年月日等
   * yymmdd
   */
  readonly cjc00050: Maybe<Scalars["Date"]["output"]>;
  /**
   * 継続欄
   * string(2)
   */
  readonly cjc00060: Scalars["String"]["output"];
  /**
   * 取引金額等
   * kingaku
   */
  readonly cjc00070: Scalars["BigInt"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cjc00080: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cjc00090: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ２ 課税売上げ等に係る事項
 * 明細　繰り返し
 */
export type Shc025_V1_0_Cjc00110 = Node & {
  readonly __typename: "Shc025_V1_0_Cjc00110";
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cjc00120: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cjc00130: Scalars["String"]["output"];
  /**
   * 取引金額
   * kingaku
   */
  readonly cjc00140: Scalars["BigInt"]["output"];
  /**
   * 主な取引商品等
   * string(40)
   */
  readonly cjc00150: Scalars["String"]["output"];
  /**
   * 所轄税関(支署)名
   * string(20)
   */
  readonly cjc00160: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (2) 主な棚卸資産・原材料等の取得
 * 明細　繰り返し
 */
export type Shc025_V1_0_Cjd00550 = Node & {
  readonly __typename: "Shc025_V1_0_Cjd00550";
  /** 資産の種類等 */
  readonly cjd00560: Scalars["String"]["output"];
  /** 取得年月日等 */
  readonly cjd00570: Maybe<Scalars["Date"]["output"]>;
  /** 継続欄 */
  readonly cjd00580: Scalars["String"]["output"];
  /** 取引金額等 */
  readonly cjd00590: Scalars["BigInt"]["output"];
  /** 取引先の氏名(名称) */
  readonly cjd00600: Scalars["String"]["output"];
  /** 取引先の住所(所在地) */
  readonly cjd00610: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (3) 主な固定資産等の取得
 * 明細　繰り返し
 */
export type Shc025_V1_0_Cjd00640 = Node & {
  readonly __typename: "Shc025_V1_0_Cjd00640";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cjd00650: Scalars["String"]["output"];
  /**
   * 取得年月日等
   * yymmdd
   */
  readonly cjd00660: Maybe<Scalars["Date"]["output"]>;
  /**
   * 取引金額等
   * kingaku
   */
  readonly cjd00670: Scalars["BigInt"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cjd00680: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cjd00690: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 消費税の還付申告に関する明細書(個人事業者用) */
export type Shc025_V2_0 = Node & {
  readonly __typename: "Shc025_V2_0";
  /**
   * 課税期間（自）
   * KAZEI_KIKAN_FROM
   */
  readonly cja00020: Scalars["Date"]["output"];
  /**
   * 課税期間（至）
   * KAZEI_KIKAN_TO
   */
  readonly cja00030: Scalars["Date"]["output"];
  /**
   * 所在地
   * NOZEISHA_ADR
   */
  readonly cja00040: Maybe<Scalars["String"]["output"]>;
  /**
   * 氏名又は名称
   * NOZEISHA_NM
   */
  readonly cja00050: Maybe<Scalars["String"]["output"]>;
  /**
   * １ 還付申告となった主な理由
   * 輸出等の免税取引の割合が高い
   * kubun
   */
  readonly cjb00010: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * 設備投資
   * kubun
   */
  readonly cjb00020: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 該当区分
   * kubun
   */
  readonly cjb00040: Scalars["Boolean"]["output"];
  /**
   * １ 還付申告となった主な理由
   * その他 その他内容
   * string(60)
   */
  readonly cjb00050: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 取引金額等(税込・税抜)
   * kubun
   */
  readonly cjc00020: Maybe<SimpleAccountingMethod>;
  /**
   * ２ 課税売上げ等に係る事項 (1) 主な課税資産の譲渡等
   * 明細　繰り返し 10
   */
  readonly cjc00030: Maybe<ReadonlyArray<Shc025_V2_0_Cjc00030>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細
   * 明細　繰り返し 10
   */
  readonly cjc00110: Maybe<ReadonlyArray<Shc025_V2_0_Cjc00110>>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関名 string(15)
   */
  readonly cjc00190: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 金融機関区分 kubun
   */
  readonly cjc00200: Maybe<BankType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 支店名 string(15)
   */
  readonly cjc00210: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 本支店区分 kubun
   */
  readonly cjc00220: Maybe<BankBranchType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 預金種類 kubun
   */
  readonly cjc00230: Maybe<BankAccountType>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な金融機関
   * 口座番号 string(14)
   */
  readonly cjc00240: Maybe<Scalars["String"]["output"]>;
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 氏名(名称) name
   */
  readonly cjc00260: Scalars["String"]["output"];
  /**
   * ２ 課税売上げ等に係る事項 (2) 主な輸出取引等の明細 輸出取引等に利用する
   * 主な通関業者
   * 住所(所在地) address
   */
  readonly cjc00270: Scalars["String"]["output"];
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * (イ) 決算額(税込・税抜) kubun
   */
  readonly cjd00020: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 仕入金額
   * (イ) 決算額 kingaku
   */
  readonly cjd00050: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 仕入金額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00060: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 仕入金額
   * 課税仕入高 kingaku
   */
  readonly cjd00070: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 必要経費
   * (イ) 決算額 kingaku
   */
  readonly cjd00090: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 必要経費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00100: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 必要経費
   * 課税仕入高 kingaku
   */
  readonly cjd00110: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 固定資産等の取得価額
   * (イ) 決算額 kingaku
   */
  readonly cjd00130: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 固定資産等の取得価額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00140: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 固定資産等の取得価額
   * 課税仕入高 kingaku
   */
  readonly cjd00150: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 小計
   * (イ) 決算額 kingaku
   */
  readonly cjd00170: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00180: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 事業所得 小計
   * 課税仕入高 kingaku
   */
  readonly cjd00190: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 必要経費
   * (イ) 決算額 kingaku
   */
  readonly cjd00220: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 必要経費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00230: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 必要経費
   * 課税仕入高 kingaku
   */
  readonly cjd00240: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 固定資産等の取得価額
   * (イ) 決算額 kingaku
   */
  readonly cjd00260: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 固定資産等の取得価額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00270: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 固定資産等の取得価額
   * 課税仕入高 kingaku
   */
  readonly cjd00280: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 小計
   * (イ) 決算額 kingaku
   */
  readonly cjd00300: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00310: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 不動産所得 小計
   * 課税仕入高 kingaku
   */
  readonly cjd00320: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得
   * 所得項目名 string(8)
   */
  readonly cjd00340: Scalars["String"]["output"];
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 仕入金額
   * (イ) 決算額 kingaku
   */
  readonly cjd00360: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 仕入金額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00370: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 仕入金額
   * 課税仕入高 kingaku
   */
  readonly cjd00380: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 必要経費
   * (イ) 決算額 kingaku
   */
  readonly cjd00400: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 必要経費
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00410: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 必要経費
   * 課税仕入高 kingaku
   */
  readonly cjd00420: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 固定資産等の取得価額
   * (イ) 決算額 kingaku
   */
  readonly cjd00440: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 固定資産等の取得価額
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00450: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * ＿所得 固定資産等の取得価額
   * 課税仕入高 kingaku
   */
  readonly cjd00460: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * ＿所得 小計
   * (イ) 決算額 kingaku
   */
  readonly cjd00480: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * ＿所得 小計
   * (ロ) (イ)のうち課税仕入れにならないもの kingaku
   */
  readonly cjd00490: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * ＿所得 小計
   * 課税仕入高 kingaku
   */
  readonly cjd00500: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 課税仕入高の合計額 kingaku
   */
  readonly cjd00510: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項 (1) 仕入金額等の明細
   * 課税仕入れ等の税額の合計額 kingaku
   */
  readonly cjd00520: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * ３ 課税仕入れに係る事項
   * (2) 主な棚卸資産・原材料等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cjd00540: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (2) 主な棚卸資産・原材料等の取得
   * 明細　繰り返し
   */
  readonly cjd00550: Maybe<ReadonlyArray<Shc025_V2_0_Cjd00550>>;
  /**
   * ３ 課税仕入れに係る事項
   * (3) 主な固定資産等の取得
   * 取引金額等(税込・税抜) kubun
   */
  readonly cjd00630: Maybe<SimpleAccountingMethod>;
  /**
   * ３ 課税仕入れに係る事項
   * (3) 主な固定資産等の取得
   * 明細　繰り返し
   */
  readonly cjd00640: Maybe<ReadonlyArray<Shc025_V2_0_Cjd00640>>;
  /**
   * ４ ＿年中の特殊事情
   * ＿年 yy
   */
  readonly cje00010: Scalars["Date"]["output"];
  /**
   * ４ ＿年中の特殊事情
   * ＿年中の特殊事情 string(200)
   */
  readonly cje00020: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** User settings for 該当あり/該当なし of each item list */
  readonly itemListSetting: RefundDeclarationItemListSetting;
};

/**
 * ２ 課税売上げ等に係る事項
 * 明細　繰り返し
 */
export type Shc025_V2_0_Cjc00030 = Node & {
  readonly __typename: "Shc025_V2_0_Cjc00030";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cjc00040: Scalars["String"]["output"];
  /**
   * 譲渡年月日等
   * yymmdd
   */
  readonly cjc00050: Maybe<Scalars["Date"]["output"]>;
  /**
   * 継続欄
   * string(2)
   */
  readonly cjc00060: Scalars["String"]["output"];
  /**
   * 取引金額等
   * kingaku
   */
  readonly cjc00070: Scalars["BigInt"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cjc00080: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cjc00090: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ２ 課税売上げ等に係る事項
 * 明細　繰り返し
 */
export type Shc025_V2_0_Cjc00110 = Node & {
  readonly __typename: "Shc025_V2_0_Cjc00110";
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cjc00120: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cjc00130: Scalars["String"]["output"];
  /**
   * 取引金額
   * kingaku
   */
  readonly cjc00140: Scalars["BigInt"]["output"];
  /**
   * 主な取引商品等
   * string(40)
   */
  readonly cjc00150: Scalars["String"]["output"];
  /**
   * 所轄税関(支署)名
   * string(20)
   */
  readonly cjc00160: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (2) 主な棚卸資産・原材料等の取得
 * 明細　繰り返し
 */
export type Shc025_V2_0_Cjd00550 = Node & {
  readonly __typename: "Shc025_V2_0_Cjd00550";
  /** 資産の種類等 */
  readonly cjd00560: Scalars["String"]["output"];
  /** 取得年月日等 */
  readonly cjd00570: Maybe<Scalars["Date"]["output"]>;
  /** 継続欄 */
  readonly cjd00580: Scalars["String"]["output"];
  /** 取引金額等 */
  readonly cjd00590: Scalars["BigInt"]["output"];
  /** 取引先の登録番号 */
  readonly cjd00595: Scalars["String"]["output"];
  /** 取引先の氏名(名称) */
  readonly cjd00600: Scalars["String"]["output"];
  /** 取引先の住所(所在地) */
  readonly cjd00610: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/**
 * ３ 課税仕入れに係る事項
 * (3) 主な固定資産等の取得
 * 明細　繰り返し
 */
export type Shc025_V2_0_Cjd00640 = Node & {
  readonly __typename: "Shc025_V2_0_Cjd00640";
  /**
   * 資産の種類等
   * string(10)
   */
  readonly cjd00650: Scalars["String"]["output"];
  /**
   * 取得年月日等
   * yymmdd
   */
  readonly cjd00660: Maybe<Scalars["Date"]["output"]>;
  /**
   * 取引金額等
   * kingaku
   */
  readonly cjd00670: Scalars["BigInt"]["output"];
  /** 取引先の登録番号 */
  readonly cjd00675: Scalars["String"]["output"];
  /**
   * 取引先の氏名(名称)
   * name
   */
  readonly cjd00680: Scalars["String"]["output"];
  /**
   * 取引先の住所(所在地)
   * address
   */
  readonly cjd00690: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 経理方式2択版 */
export const enum SimpleAccountingMethod {
  /** 税抜 */
  TaxExcluded = "TAX_EXCLUDED",
  /** 税込 */
  TaxIncluded = "TAX_INCLUDED",
}

/** 簡易課税 事業区分ごとのみなし仕入れ率 */
export const enum SimpleTaxationPurchasingRate {
  /** 第一種事業 90% */
  PurchasingRate_1St_90 = "PURCHASING_RATE_1ST_90",
  /** 第二種事業 80% */
  PurchasingRate_2Nd_80 = "PURCHASING_RATE_2ND_80",
  /** 第三種事業 70% */
  PurchasingRate_3Rd_70 = "PURCHASING_RATE_3RD_70",
  /** 第四種事業 60% */
  PurchasingRate_4Th_60 = "PURCHASING_RATE_4TH_60",
  /** 第五種事業 50% */
  PurchasingRate_5Th_50 = "PURCHASING_RATE_5TH_50",
  /** 第六種事業 40% */
  PurchasingRate_6Th_40 = "PURCHASING_RATE_6TH_40",
}

/** CAデータ取得タスク */
export type SyncBasicDataTask = {
  readonly __typename: "SyncBasicDataTask";
  /** Estimated time to complete the task in seconds */
  readonly estimetedTimeSecond: Scalars["Int"]["output"];
  /** Status of the task */
  readonly status: SyncBasicDataTaskStatus;
  /** Task finish time */
  readonly taskFinishTime: Maybe<Scalars["DateTime"]["output"]>;
  /** Task start time */
  readonly taskStartTime: Scalars["DateTime"]["output"];
};

/** CAデータ取得タスク状態 */
export const enum SyncBasicDataTaskStatus {
  /** キャンセル */
  Canceled = "CANCELED",
  /** 完了 */
  Done = "DONE",
  /** ネットワークエラーが発生 */
  ErrorNetwork = "ERROR_NETWORK",
  /** その他が発生 */
  ErrorOther = "ERROR_OTHER",
  /** タイムアウトが発生 */
  ErrorTimeout = "ERROR_TIMEOUT",
  /** 未実行 */
  None = "NONE",
  /** 処理中 */
  Processing = "PROCESSING",
}

/** 税務署 */
export type TaxOffice = Node & {
  readonly __typename: "TaxOffice";
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** Official name */
  readonly name: Scalars["String"]["output"];
  /** Unique code of the tax office */
  readonly number: Scalars["String"]["output"];
};

/** 課税期間 */
export type TaxationPeriod = Node & {
  readonly __typename: "TaxationPeriod";
  /** 経理方式 */
  readonly accountingMethod: AccountingMethod;
  /** バックグランドタスク状態 */
  readonly backgroundTasks: BackgroundTasks;
  /** 基本情報データ */
  readonly basicInformationV1: Maybe<BasicInformation_V1>;
  /** 今期の確定消費税額（国税・地方消費税・納付（還付）税額と納付期限） */
  readonly confirmedConsumptionTax: ConfirmedConsumptionTax;
  /** 今期の中間納付予定税額 */
  readonly currentTermInterimPaymentInfo: InterimPaymentInfo;
  /** 申告基礎データ_V1 */
  readonly declarationBasicDataV1: Maybe<DeclarationBasicData_V1>;
  /** 申告基礎データ_V2 */
  readonly declarationBasicDataV2: Maybe<DeclarationBasicData_V2>;
  /** 申告情報データ */
  readonly declarationInformationV1: Maybe<DeclarationInformation_V1>;
  /** 申告種類 */
  readonly declarationType: DeclarationType;
  /** 課税期間が持つデータの最終更新日時 */
  readonly documentUpdate: DocumentUpdate;
  /** 終了日 */
  readonly endDate: Scalars["Date"]["output"];
  /** E-Tax用XTXファイルのダウンロード可否情報 */
  readonly etaxFileDownloadable: EtaxFileDownloadable;
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** Information about whether it is invalid or not. */
  readonly invalidInfo: InvalidInfo;
  /** 旧税率の取引が入っているかどうかフラグを変更できるか */
  readonly isAbleToChangeIncludeOldExciseTransactionsFlag: Scalars["Boolean"]["output"];
  /** 旧税率の取引が入っているかどうか */
  readonly isIncludeOldExciseTransactions: Scalars["Boolean"]["output"];
  /** インボイス制度対応の課税期間かどうか */
  readonly isInvoiceSupported: Scalars["Boolean"]["output"];
  /** 課税期間末日から1ヶ月が過ぎたかどうか */
  readonly isOutdated: Scalars["Boolean"]["output"];
  /** 短縮課税期間かどうか(作成時に短縮を選んだかではなく、実際に1年未満の課税期間かどうか) */
  readonly isPeriodShortened: Scalars["Boolean"]["output"];
  /** 所属している会計期間からはみ出ていないかどうか */
  readonly isWithinParentTerm: Scalars["Boolean"]["output"];
  /** 還付申告を行う必要があるか */
  readonly needRefundDeclaration: Scalars["Boolean"]["output"];
  /** 来期の中間納付予定税額 */
  readonly nextTermInterimPaymentInfo: InterimPaymentInfo;
  /** 課税方式 */
  readonly officeExciseType: OfficeExciseType;
  /** 会計側の選択中の事業者のID */
  readonly officeID: Scalars["String"]["output"];
  /** 事業者の種類 */
  readonly officeType: OfficeType;
  /** 適用されている手続きの種類 */
  readonly procedureType: ProcedureType;
  /** 適用されている手続きのバージョン */
  readonly procedureVersion: ProcedureVersions;
  /** 消費税の還付申告に関する明細書 */
  readonly refundDeclarationDetailV1: Maybe<RefundDeclarationDetail_V1>;
  /** 還付申告明細書の候補を取得する */
  readonly refundDeclarationDetailV1Candidate: Maybe<RefundDeclarationDetail_V1>;
  /** 消費税の還付申告に関する明細書の必須項目チェック */
  readonly refundDeclarationDetailV1Submittable: Maybe<RefundDeclarationDetail_V1_Submittable>;
  /** 消費税及び地方消費税申告(一般・個人) v20_0_0 */
  readonly rsh0010V20_0_0: Maybe<Rsh0010_V20_0_0>;
  /** 消費税及び地方消費税申告(一般・個人) v23_0_0 */
  readonly rsh0010V23_0_0: Maybe<Rsh0010_V23_0_0>;
  /** 消費税及び地方消費税申告(一般・法人) v20_0_0 */
  readonly rsh0020V20_0_0: Maybe<Rsh0020_V20_0_0>;
  /** 消費税及び地方消費税申告(一般・法人) v23_0_0 */
  readonly rsh0020V23_0_0: Maybe<Rsh0020_V23_0_0>;
  /** 消費税及び地方消費税申告(簡易課税・個人) v20_0_0 */
  readonly rsh0030V20_0_0: Maybe<Rsh0030_V20_0_0>;
  /** 消費税及び地方消費税申告(簡易課税・個人) v23_0_0 */
  readonly rsh0030V23_0_0: Maybe<Rsh0030_V23_0_0>;
  /** 消費税及び地方消費税申告(簡易課税・法人) v20_0_0 */
  readonly rsh0040V20_0_0: Maybe<Rsh0040_V20_0_0>;
  /** 消費税及び地方消費税申告(簡易課税・法人) v23_0_0 */
  readonly rsh0040V23_0_0: Maybe<Rsh0040_V23_0_0>;
  /** 特例の利用 */
  readonly specialException20Percent: Maybe<TaxationPeriodSpecialException20Percent>;
  /** 開始日 */
  readonly startDate: Scalars["Date"]["output"];
  /** 申告書のステータス */
  readonly status: TaxationPeriodStatus;
  /** 差引税額 */
  readonly subtractedTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 納税額また還付額 */
  readonly taxPaymentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 最終更新日 */
  readonly updatedAt: Scalars["DateTime"]["output"];
  /** 課税期間バージョン */
  readonly version: TaxationPeriodVersion;
};

/** 課税期間 */
export type TaxationPeriodCurrentTermInterimPaymentInfoArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** 課税期間 */
export type TaxationPeriodRefundDeclarationDetailV1CandidateArgs = {
  taxationPeriodID: Scalars["ID"]["input"];
};

/** 課税期間の特例を表す */
export type TaxationPeriodSpecialException20Percent = {
  readonly __typename: "TaxationPeriodSpecialException20Percent";
  /** 2割特例を使わないときの納税額 */
  readonly taxPaymentAmount: Scalars["BigInt"]["output"];
  /** 2割特例時の納税額 */
  readonly taxPaymentAmountSpecialException20Percent: Maybe<Scalars["BigInt"]["output"]>;
  /** 2割特例を現在設定しているかを表すフラグ */
  readonly useSpecialException20Percent: Scalars["Boolean"]["output"];
};

/** 課税期間のステータス */
export const enum TaxationPeriodStatus {
  /** 作成中 */
  Creating = "CREATING",
  /** 作成完了 */
  Done = "DONE",
  /** 提出済み */
  Submitted = "SUBMITTED",
}

/** 課税期間バージョン */
export type TaxationPeriodVersion = {
  readonly __typename: "TaxationPeriodVersion";
  /** 基本情報バージョン */
  readonly basicInformationVersion: Version;
  /** 申告基礎データバージョン */
  readonly declarationBasicDataVersion: Version;
  /** 申告情報バージョン */
  readonly declarationInformationVersion: Version;
  /** E-Taxバージョン */
  readonly procedureVersion: ProcedureVersions;
  /** 還付申告明細書バージョン */
  readonly refundDeclarationDetailVersion: Version;
  /** 課税期間バージョン */
  readonly taxationPeriodVersion: Version;
};

/** 電話番号 */
export type TelephoneNumber = {
  readonly __typename: "TelephoneNumber";
  /** 市外局番 */
  readonly area: Scalars["String"]["output"];
  /** 市内局番 */
  readonly local: Scalars["String"]["output"];
  /** 加入者番号 */
  readonly number: Scalars["String"]["output"];
};

/** Parameter for updating telephone number */
export type TelephoneNumberInput = {
  /** 市外局番 */
  readonly area: Scalars["String"]["input"];
  /** 市内局番 */
  readonly local: Scalars["String"]["input"];
  /** 加入者番号 */
  readonly number: Scalars["String"]["input"];
};

/** 会計期間 */
export type Term = Node & {
  readonly __typename: "Term";
  /** 経理方式 */
  readonly accountingMethod: AccountingMethod;
  /** 課税期間を新規作成可能かどうか */
  readonly canCreateMoreTaxationPeriod: Scalars["Boolean"]["output"];
  /** 所属している課税期間一覧(すべてのDeclarationType) */
  readonly dateAggregatedTaxationPeriods: ReadonlyArray<ReadonlyArray<TaxationPeriod>>;
  /** 期末日 */
  readonly endDate: Scalars["Date"]["output"];
  /** TermのGraphQLID */
  readonly graphqlID: Scalars["ID"]["output"];
  /** 短縮課税期間を持っているか */
  readonly haveShortTaxationPeriod: Scalars["Boolean"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** 課税方式 */
  readonly officeExciseType: OfficeExciseType;
  /** 仕入の端数処理方法 */
  readonly purchasesTaxFraction: FractionType;
  /** 仕入処理方法 */
  readonly purchasingProcessingMethod: ProcessingMethod;
  /** 売上処理方法 */
  readonly salesProcessingMethod: ProcessingMethod;
  /** 売上の端数処理方法 */
  readonly salesTaxFraction: FractionType;
  /** 期首日 */
  readonly startDate: Scalars["Date"]["output"];
  /** 所属している課税期間一覧(DeclarationType=確定のみ) */
  readonly taxationPeriods: ReadonlyArray<Maybe<TaxationPeriod>>;
};

/** The item which represents the total of one basic data item group */
export type TotalItem = {
  readonly __typename: "TotalItem";
  /** 入力された調整額 */
  readonly adjustmentAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 本体価格 */
  readonly basePrice: Maybe<Scalars["BigInt"]["output"]>;
  /** 消費税額 */
  readonly consumptionTaxAmount: Maybe<Scalars["BigInt"]["output"]>;
  /** 表示順番（グループ内） */
  readonly displayOrder: Scalars["Int"]["output"];
  /** 表示設定 */
  readonly displaySetting: ExciseDisplaySetting;
  /** 税込価格 */
  readonly taxIncludedPrice: Maybe<Scalars["BigInt"]["output"]>;
};

/** Parameters for updating basic information V1 */
export type UpdateBasicInformationV1Input = {
  /** 金融機関情報 */
  readonly bankAccountInfo: InputMaybe<BankAccountInfoInput>;
  /** 代表者氏名 */
  readonly daihyoNm: Scalars["String"]["input"];
  /** 代表者フリガナ */
  readonly daihyoNmKn: Scalars["String"]["input"];
  /** 税理士/氏名 */
  readonly dairiNm: InputMaybe<Scalars["String"]["input"]>;
  /** 税理士/電話番号 */
  readonly dairiTel: InputMaybe<TelephoneNumberInput>;
  /** 電子申告情報/利用者識別番号 */
  readonly etaxIdentificationNumber: InputMaybe<Scalars["String"]["input"]>;
  /** 口座情報利用同意 */
  readonly hasAccountInformationUsageAgreement: Scalars["Boolean"]["input"];
  /** 振替継続希望 */
  readonly hasContinuedTransferRequest: Scalars["Boolean"]["input"];
  /** 税理士法第30条の書面提出 */
  readonly isDairiLaw30: Scalars["Boolean"]["input"];
  /** 税理士法第33条の2の書面提出 */
  readonly isDairiLaw33_2: Scalars["Boolean"]["input"];
  /** 住所 */
  readonly nozeishaAdr: Scalars["String"]["input"];
  /** 法人番号/個人番号 */
  readonly nozeishaBango: Scalars["String"]["input"];
  /** 法人名称/屋号 */
  readonly nozeishaNm: Scalars["String"]["input"];
  /** 法人名称/屋号 フリガナ */
  readonly nozeishaNmKn: Scalars["String"]["input"];
  /** 電話番号 */
  readonly nozeishaTel: TelephoneNumberInput;
  /** 郵便番号 */
  readonly nozeishaZip: ZipCodeInput;
  /** 課税期間ID */
  readonly taxationPeriodID: Scalars["ID"]["input"];
  /**
   * 所轄税務署コード
   * MEMO: 所轄税務署名はサーバ側で補完する
   */
  readonly zeimushoCd: Scalars["String"]["input"];
};

/** Parameters for updating declaration information data V1 */
export type UpdateDeclarationInformationV1Input = {
  /** 仕入れ税額・固定資産関連の調整額 */
  readonly adjustAmountV1: InputMaybe<DeclarationInformationAdjustAmountV1Input>;
  /** 現金主義会計の適用 */
  readonly applyCashBasisAccounting: Scalars["Boolean"]["input"];
  /** 工事進行基準の適用 */
  readonly applyConstructionProgressCriteria: Scalars["Boolean"]["input"];
  /** 延払基準の適用 */
  readonly applyDeferredPaymentCriteria: Scalars["Boolean"]["input"];
  /** 割賦基準の適用 */
  readonly applyInstallmentCriteria: Scalars["Boolean"]["input"];
  /** 基準期間の課税売上高 */
  readonly basePeriodTaxableSales: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 基準期間の課税売上高の取得情報 */
  readonly basePeriodTaxableSalesInfo: InputMaybe<BasePeriodTaxableSalesInfoInput>;
  /** 既確定税額 */
  readonly establishedTaxAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 既確譲渡割額 */
  readonly establishedTransferDiscountAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 中間納付税額 */
  readonly midTermTaxAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 中間納付譲渡割額 */
  readonly midTermTransferDiscountAmount: InputMaybe<Scalars["BigInt"]["input"]>;
  /** 仕入処理方法 */
  readonly purchasingProcessingMethod: ProcessingMethod;
  /** 売上処理方法 */
  readonly salesProcessingMethod: ProcessingMethod;
  /** 課税期間ID */
  readonly taxationPeriodID: Scalars["ID"]["input"];
  /** 提出日 */
  readonly teisyutsuDay: InputMaybe<Scalars["Date"]["input"]>;
};

/** Parameters for updating etax identification number */
export type UpdateEtaxIdentificationNumberInput = {
  /** 利用者識別番号 */
  readonly etaxIdentificationNumber: Scalars["String"]["input"];
};

/** updateTaxationPeriodSpecialException20Percentの更新パラメーター */
export type UpdateTaxationPeriodSpecialException20PercentParam = {
  /** 2割特例を利用するかどうか */
  readonly useSpecialException20Percent: Scalars["Boolean"]["input"];
};

/** ユーザー */
export type User = Node & {
  readonly __typename: "User";
  /** ユーザーの基本情報 */
  readonly basicSetting: BasicSetting;
  /** 選択している課税期間 */
  readonly currentTaxationPeriod: Maybe<TaxationPeriod>;
  /**
   * 現在の会計期間（Term）
   * 選択している課税期間がある場合：その課税期間が所属しているTerm
   * 選択している課税期間がない場合: ca_webのUserのCurrentTerm
   */
  readonly currentTerm: Term;
  /** カスタマーサポート（チャットウィジェット）利用情報 */
  readonly customerSupport: CustomerSupport;
  /** ID */
  readonly id: Scalars["ID"]["output"];
  /** MFID側のユーザーの識別子 */
  readonly identificationCode: Scalars["String"]["output"];
  /** 代行ログインかどうか */
  readonly isProxy: Scalars["Boolean"]["output"];
  /** 事業者設定 */
  readonly officeSetting: OfficeSetting;
};

/** バージョン定義 */
export const enum Version {
  /** バージョン1 */
  V1 = "V1",
  /** バージョン2 */
  V2 = "V2",
}

/** XTXダウンロード情報 */
export type XtxDownload = Node & {
  readonly __typename: "XTXDownload";
  /** XTXファイルの内容 */
  readonly XTXContent: Scalars["String"]["output"];
  /** ファイル名：会社名_消費税申告書(課税期間) */
  readonly fileName: Scalars["String"]["output"];
  /** ID */
  readonly id: Scalars["ID"]["output"];
};

/** 郵便番号 */
export type ZipCode = {
  readonly __typename: "ZipCode";
  /** 上3桁 */
  readonly zip1: Scalars["String"]["output"];
  /** 下4桁 */
  readonly zip2: Scalars["String"]["output"];
};

/** Parameter for updating zip code */
export type ZipCodeInput = {
  /** 上3桁 */
  readonly zip1: Scalars["String"]["input"];
  /** 下4桁 */
  readonly zip2: Scalars["String"]["input"];
};

/**
 * Constants for Wording
 *
 * Please define wording constants to unify among pages here.
 * Currently we didn't take account of i18n.
 * Please define constant values and types individually if you need i18n.
 */
export const lang = {
  /** error messages */
  error: {
    officeExciseTypeNotMatch:
      "課税形式が変更されました。基本情報画面で課税形式の再取得をしてください。",
    accountingMethodNotMatch:
      "経理方式が変更されました。基本情報画面で経理方式の再取得をしてください。",
    currentTaxationPeriodNotWithinParentTerm:
      "会計期間が変更されたためこの課税期間が対象外となっています。一度この課税期間を削除してから再作成を行って下さい。",
    clientSideValidationFailed: "入力または修正が必要な項目があります。",
    processingMethodCombinationWithCaIsNotProper:
      "マネーフォワード クラウド会計・確定申告で「割戻し」が選択されているため、消費税申告で「積上げ」を選択することはできません。",
  },
  /** success messages */
  success: {
    /**
     * @description
     * this message is used in the following situations.
     * - `/declaration-basic-data` form is successfully saved.
     * - data retrieving from ca_web on `/declaration-basic-data` is succeeded.
     */
    updateDeclarationBasicData: "納税額の算出に成功しました。",
  },
} as const;

import { useRouter } from "next/router";

/** pathnames that uses "x-ctax-current-ca-term" HTTP header */
const PATHNAMES: ReadonlySet<string> = new Set(["/taxation-periods"]);

/**
 * @returns whether GraphQL operation uses "x-ctax-current-ca-term" HTTP header or not
 */
export const useCurrentCaTerm = (): boolean => {
  const router = useRouter();
  return PATHNAMES.has(router.pathname);
};

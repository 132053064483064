/** PC での画面横幅 */
export const PC_SCREEN_WIDTH = "1280px";

/** content width that is regularly used */
export const REGULAR_CONTENT_WIDTH = "928px";

/** ヘッダーの高さ */
export const LAYOUT_HEADER_HEIGHT = "56px";

/** デフォルトの input の高さ */
export const DEFAULT_INPUT_HEIGHT = "40px";

/** z-indexes */
export const Z_INDEX = {
  SIDE_MENU: 800,
  FOOTER: 800,
  HEADER: 1000,
  // the chat widget has `z-index: 5000;`
  SIDE_MODAL: 6000,
  TOAST: 7000,
  MODAL: 8000,
  LOADING_INDICATOR: 9000,
} as const;

import { useMutation } from "@apollo/client";
import { formatISO } from "date-fns";
import { useRouter } from "next/router";
import * as React from "react";
import { isUserError } from "../../utils/error-handling";
import {
  SaveWebAccessLog,
  SaveWebAccessLogVariables,
  SaveWebAccessLogDocument,
} from "./graphql/save-web-access-log-mutation";

export const SaveWebAccessLogger: React.FC = () => {
  const router = useRouter();
  const [sendWebAccessLog] = useMutation<SaveWebAccessLog, SaveWebAccessLogVariables>(
    SaveWebAccessLogDocument
  );

  React.useEffect(() => {
    sendWebAccessLog({
      variables: {
        input: {
          path: router.asPath,
          referer: document.referrer,
          userAgent: window.navigator.userAgent,
          ipAddress: "", // TODO: IP アドレスが取得可能になったらそちらを代入する
          accessedAt: formatISO(new Date()),
        },
      },
    }).catch((error: unknown) => {
      if (isUserError(error)) {
        return; // ignore errors
      }
      throw error;
    });
  }, [sendWebAccessLog, router.asPath]);

  return null;
};

import { setAutoFreeze, enableMapSet } from "immer";

export const setupImmer = (): void => {
  /**
   * Avoiding `TypeError: Cannot assign to read only property xxx`.
   * @see https://immerjs.github.io/immer/freezing
   */
  setAutoFreeze(false);
  /**
   * Enable support of ES6 Map and Set.
   * @see https://immerjs.github.io/immer/map-set
   */
  enableMapSet();
};

/* eslint-disable */
// This file was automatically generated and should not be edited.
// @ts-nocheck
import * as Types from "../../../generated/graphql-types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type FetchGtmParamsVariables = Types.Exact<{ [key: string]: never }>;

export type FetchGtmParams = {
  readonly viewer: {
    readonly __typename: "User";
    readonly identificationCode: string;
    readonly isProxy: boolean;
    readonly basicSetting: {
      readonly __typename: "BasicSetting";
      readonly officeType: Types.OfficeType;
      readonly officeIdentificationCode: string;
    };
  };
};

export const FetchGtmParamsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchGtmParams" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "identificationCode" } },
                { kind: "Field", name: { kind: "Name", value: "isProxy" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "basicSetting" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "officeType" } },
                      { kind: "Field", name: { kind: "Name", value: "officeIdentificationCode" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchGtmParams, FetchGtmParamsVariables>;

import Rollbar from "rollbar";
import { UAParser } from "ua-parser-js";

type RollbarPayloadFrame = Readonly<{
  filename: string;
  method: string;
  lineno: number | null;
  colno: number | null;
}>;

type RollbarPayload = Readonly<{
  body?: Readonly<{
    trace?: Readonly<{
      frames?: readonly RollbarPayloadFrame[];
    }>;
  }>;
}>;

/**
 * @see https://github.com/moneyforward/tax_return_web/blob/eb1c306ae12baf209ced614af5cb89cd8ccf14cb/src/lib/rollbarCreator.tsx#L48-L63
 * @param payload
 * @returns true = スタックトレースを全く取れていない
 */
const hasNoValidFrames = (payload: RollbarPayload): boolean => {
  const isInvalidFrame = (frame: RollbarPayloadFrame): boolean =>
    frame.filename === "(unknown)" ||
    frame.filename === "Unknown script code" ||
    (frame.method === "[anonymous]" && frame.lineno == null);
  const frames = payload.body?.trace?.frames;
  return frames?.every(isInvalidFrame) ?? false;
};

const supportedBrowsers: ReadonlySet<string> = new Set([
  "Chrome",
  "Chrome Headless",
  "Chromium",
  "Firefox",
  "Edge",
  "Safari",
]);

/**
 * @returns true = サポートされていないブラウザ
 */
export const isUnsupportedBrowser = (uaString?: string | undefined): boolean => {
  if (!process.browser) {
    // ブラウザではない
    return false;
  }
  const uaParser = new UAParser(uaString);
  const { name } = uaParser.getBrowser();
  if (name == null) {
    return false;
  }
  return !supportedBrowsers.has(name);
};

/**
 * @see https://docs.rollbar.com/docs/reduce-noisy-javascript-errors#ignore-noisy-errors-on-the-client-side
 * @param _isUncaught true if the error being reported is from the window.onerror hook.
 * @param _args The arguments to Rollbar.log/debug/info/warning/error/critical().
 * @param payload The javascript object that is about to be sent to Rollbar.
 * @returns true if the error should be ignored.
 */
export const checkIgnore = (
  _isUncaught: boolean,
  _args: Rollbar.LogArgument[],
  payload: RollbarPayload
): boolean => {
  return hasNoValidFrames(payload) || isUnsupportedBrowser();
};

import Rollbar from "rollbar";
import { publicRuntimeConfig, serverRuntimeConfig } from "../next-helpers/runtime-config";
import { checkIgnore } from "./check-ignore";

// https://docs.rollbar.com/docs/reduce-noisy-javascript-errors#ignore-certain-types-of-messages
const ignoredMessages: readonly (string | RegExp)[] = [
  // クライアントサイドルーティングを中断するために throw している文字列
  "Abort route change. Please ignore this error.",
  // Safariでネットワーク接続が切れた場合のエラー
  "ネットワーク接続が切れました",
  // Safariでリクエストがキャンセルされた場合のエラー
  "キャンセルしました",
  // Chromeでリクエストがキャンセルされた場合のエラー
  /^Failed to fetch$/,
  // Firefoxでリクエストがキャンセルされた場合のエラー
  "NetworkError when attempting to fetch resource.",
];

export const createRollbar = (): Rollbar | null => {
  const accessToken = process.browser
    ? publicRuntimeConfig.getString("NEXT_PUBLIC_ROLLBAR_TOKEN")
    : serverRuntimeConfig.getString("SERVER_ROLLBAR_TOKEN");
  const environment = publicRuntimeConfig.getString("NEXT_PUBLIC_ROLLBAR_ENVIRONMENT");

  if (!accessToken || !environment) {
    return null;
  }
  return new Rollbar({
    accessToken,
    environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment,
      javascript: {
        source_map_enabled: true,
        code_version: process.env.BUILD_ID,
        guess_uncaught_frames: true,
      },
    },
    // ドキュメント上 文字列 または 正規表現 が指定可能と明記されており、機能もするが
    // 型定義上では string[] しか受け付けないのでアサーションしている
    // @see https://docs.rollbar.com/docs/javascript#ignoring-specific-exception-messages
    ignoredMessages: ignoredMessages.slice() as string[],
    checkIgnore,
  });
};

import { ApolloError } from "@apollo/client";
import { ErrorType } from "../../generated/graphql-types";
import { GraphQLErrorParser } from "../../utils/error-handling";
import { lang } from "../../utils/lang";
import { RedirectRulesByErrorType } from "../../utils/server-side-redirects/match-redirect-rules";
import { ToastParam } from "./types";

/** a kind type of error notification modal */
export type ErrorModalKind =
  | "NoPermission"
  | "SyncCaTaskProcessing"
  | "InvalidTaxationPeriodVersionForV2"
  | "XtxValidationError";

export type ExternalApi = Readonly<{
  addToast: (params: ToastParam) => void;
  showErrorModal: (kind: ErrorModalKind) => Promise<void>;
}>;

/** error toast messages paired with GraphQL Error Code */
const errorToastMessages: readonly (readonly [ErrorType, string])[] = [
  // 処理方法と経理方式の設定が不正の場合
  [
    ErrorType.InvalidProcessingMethodSetting,
    "利用できない経理方式、仕入端数処理または計算方法の組み合わせが設定されています。【申告情報画面】で詳細を確認してください。",
  ],
  [
    ErrorType.ProcessingMethodValidationFailure,
    "「税込 × 売上積上げ」、「税込 × 仕入積上げ」または「仕入積上げ × 仕入端数処理切り上げ」の組み合わせは選択できません。計算方法を確認し、マネーフォワード クラウド会計・確定申告の事業者設定から設定を変更してください。",
  ],
  [
    ErrorType.CurrentTermNotMatch,
    "別のPCやタブでログイン中の会計期間もしくは事業者が変更された可能性があります。画面を更新してください。",
  ],
  [ErrorType.OfficeExciseTypeNotMatch, lang.error.officeExciseTypeNotMatch],
  [ErrorType.AccountingMethodNotMatch, lang.error.accountingMethodNotMatch],
  [
    ErrorType.TaxationPeriodNotSelected,
    "別のPCやタブで操作中の課税期間が変更された可能性があります。画面を更新してから再度操作をしてください。",
  ],
  [
    ErrorType.CurrentTaxationPeriodNotMatch,
    "別のPCやタブで操作中の課税期間が変更された可能性があります。画面を更新してから再度操作をしてください。",
  ],
  [
    ErrorType.TaxationPeriodOutsideOfCaTerm,
    "課税期間が会計期間の対象外です。当課税期間を削除してから再作成を行って下さい。",
  ],
  [ErrorType.RefundDeclarationUnnecessary, "還付申告明細書の作成対象外のため保存できません。"],
  [
    ErrorType.DeclarationAdjustmentTotalAmountOverflow,
    "入力されている調整額の合計値が上限を超えています。",
  ],
  [
    ErrorType.TaxableSettingRequired,
    "免税設定の場合は消費税申告機能をご利用いただくことはできません。",
  ],
  [ErrorType.OutsourcingClientMemberInaccessible, "利用できない機能です。"],
  [ErrorType.PremiumRequired, "消費税申告機能が利用できない料金プランの事業者です。"],
  [ErrorType.ProxyLoginTaxationPeriodNotOperable, "代行ログイン中は課税期間の操作はできません。"],
  [ErrorType.ProxyLoginSessionExpired, "代行ログインのセッションが切れました。"],
  [ErrorType.ProxyLoginIdMismatch, "代行ログインのIDが違います。"],
  [
    ErrorType.InvalidTaxationPeriodStatus,
    "作成ステータスが「作成中」ではないため保存できません。編集・保存する場合は『課税期間一覧』画面から変更が必要です。",
  ],
  [ErrorType.EtaxValidationFailed, "画面をリロードして再度操作をしてください。"],
  [
    ErrorType.CombinationOfSalesTsumiageAndPurchasingWarimodoshiValidationFailure,
    "売上処理方法「積上げ」かつ仕入処理方法「割戻し」(2023年10月1日以降の日付が含まれている課税期間の場合)の組み合わせは選択できません。「申告情報」画面で設定を変更してください。",
  ],
  [
    // This error code is returned when you run PDF/XTX file download under urgent maintenance mode.
    ErrorType.EtaxDeclarationDisabled,
    "メンテナンス中のためこちらの機能はご利用いただけません。しばらく時間をおいてから再度お試しください。",
  ],
  [
    ErrorType.ProcessingMethodCombinationWithCaIsNotProper,
    "マネーフォワード クラウド会計・確定申告で「割戻し」が選択されているため、消費税申告で「積上げ」を選択することはできません。",
  ],
  [ErrorType.TaxationPeriodDateRangeOverlap, "指定された期間の申告書は既に作成されています。"],
  [
    ErrorType.TargetedTaxationPeriodIsOlderThanExisting,
    "この課税期間を元に作成された修正申告書があるため削除できません。削除するためには、その修正申告書を削除する必要があります。",
  ],
  [
    ErrorType.TaxationPeriodCantSetSpecialException_20Percent,
    "操作を完了できませんでした。別のPCやタブで内容が変更された可能性があるため、画面を更新してから再度操作を行ってください。",
  ],
];

/** error modal kind paired with GraphQL Error Code */
const errorModalKinds: readonly (readonly [ErrorType, ErrorModalKind])[] = [
  [ErrorType.WritePermissionRequired, "NoPermission"],
  [ErrorType.NoPermission, "NoPermission"],
  [ErrorType.SyncCaTaskProcessing, "SyncCaTaskProcessing"],
  [ErrorType.InvalidTaxationPeriodVersionForV2, "InvalidTaxationPeriodVersionForV2"],
  [ErrorType.XtxValidationError, "XtxValidationError"],
];

/**
 * @param error catch された Error オブジェクト
 * @param defaultMessage デフォルトでフラッシュメッセージに表示する文言
 * @param options
 */
type HandleActionError = (error: unknown, defaultMessage: string) => void;

export const handleActionErrorFactory = (externals: ExternalApi): HandleActionError => {
  return (error, defaultMessage) => {
    /** @see https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort */
    if (error instanceof Error && error.name === "AbortError") {
      return;
    }
    if (!(error instanceof ApolloError)) {
      externals.addToast({ theme: "error", text: defaultMessage });
      return;
    }
    const parsed = GraphQLErrorParser.fromApolloError(error);

    for (const graphQLError of parsed.errors) {
      const errCode: string = graphQLError.extensions?.code as string;
      if (errCode != null) {
        const redirect = RedirectRulesByErrorType.get(errCode);
        if (redirect != null && errCode === (ErrorType.OfficeMemberNotFound as string)) {
          window.open(redirect.destination, "_self");
          return;
        }
      }
    }

    // show an error toast.
    for (const [errorCode, message] of errorToastMessages) {
      if (parsed.hasErrorCode(errorCode)) {
        externals.addToast({ theme: "error", text: message });
        return;
      }
    }

    // show an error notification modal.
    for (const [errorCode, modalKind] of errorModalKinds) {
      if (parsed.hasErrorCode(errorCode)) {
        void externals.showErrorModal(modalKind);
        return;
      }
    }

    // other than the above.
    const text: string = parsed.displayMessages()[0] ?? defaultMessage;
    externals.addToast({ theme: "error", text });
  };
};

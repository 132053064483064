import { ApolloProvider } from "@apollo/client";
import { Provider as JotaiStoreProvider } from "jotai/react";
import App, { AppProps, AppContext } from "next/app";
import { ReactElement } from "react";
import { GtmNoScript, GtmScript } from "../containers/gtm-script";
import { EventHubProvider } from "../contexts/event-hub";
import { SideEffectViewProvider } from "../contexts/side-effect-view";
import { SaveWebAccessLogger } from "../hooks/access-log/save-web-access-log";
import { getApolloClient } from "../utils/apollo/client";
import { setupLibraries } from "../utils/setup";
import "modern-css-reset";
import "../utils/styles/global.scss";

setupLibraries();

const MyApp = ({ Component, pageProps }: AppProps): ReactElement => {
  const apolloClient = getApolloClient();
  return (
    <ApolloProvider client={apolloClient}>
      <JotaiStoreProvider>
        <GtmScript />
        <GtmNoScript />
        <SaveWebAccessLogger />
        <EventHubProvider>
          <SideEffectViewProvider>
            <Component {...pageProps} />
          </SideEffectViewProvider>
        </EventHubProvider>
      </JotaiStoreProvider>
    </ApolloProvider>
  );
};

/**
 * Automatic Static Optimization を無効化するために getInitialProps を定義している。
 * Runtime Config が Automatic Static Optimization と競合してしまい、
 * Static Generation の画面で Runtime Config で参照した設定がビルド時の環境変数になる不具合が発生してしまう。
 * 上記を阻止するために全ての画面で一律に Automatic Static Optimization を無効化させる必要がある。
 *
 * @see https://nextjs.org/docs/advanced-features/custom-app
 * @see https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 * @see https://nextjs.org/docs/advanced-features/automatic-static-optimization
 */
MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  return appProps;
};

export default MyApp;

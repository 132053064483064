import { useMemo, createContext, useContext } from "react";
import { EventName, Emitter } from "./emitter";

export type EventHub = Readonly<{
  off: (event: EventName, listener: () => void) => void;
  on: (event: EventName, listener: () => void) => () => void;
  emit: (event: EventName) => void;
}>;

const context = createContext<EventHub | null>(null);

const createContextValue = (): EventHub => {
  const emitter = new Emitter();
  return {
    off: emitter.off.bind(emitter),
    on: emitter.on.bind(emitter),
    emit: emitter.emit.bind(emitter),
  };
};

export const EventHubProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const value = useMemo(() => createContextValue(), []);

  return <context.Provider value={value}>{children}</context.Provider>;
};

export const useEventHub = (): EventHub => {
  const value = useContext(context);
  if (value == null) {
    throw new Error("Component must be wrapped with <context.Provider>");
  }
  return value;
};

import { Redirect } from "next";
import { ErrorType } from "../../generated/graphql-types";
import { getCaWebMessageUrl, getCaWebOfficeMemberNotFound, getCaWebTopUrl } from "../external-url";
import { GraphQLError } from "../graphql-client";
import { InspectAppRedirectCondition } from "./graphql/inspect-app-redirect-condition-query";

const PAGE_PATHNAMES = {
  auth: "/auth",
  taxationPeriods: "/taxation-periods",
} as const;

export const RedirectRulesByErrorType: ReadonlyMap<string, Redirect> = new Map([
  [ErrorType.Unauthorized, { statusCode: 302, destination: PAGE_PATHNAMES.auth }],
  [ErrorType.CaTermsAgreementRequired, { statusCode: 302, destination: getCaWebTopUrl() }],
  [ErrorType.NoPermission, { statusCode: 302, destination: getCaWebMessageUrl("permission") }],
  [ErrorType.PremiumRequired, { statusCode: 302, destination: getCaWebMessageUrl("premium") }],
  [
    ErrorType.OfficeMemberNotFound,
    { statusCode: 302, destination: getCaWebOfficeMemberNotFound() },
  ],
  [
    ErrorType.TaxableSettingRequired,
    { statusCode: 302, destination: getCaWebMessageUrl("office_excise") },
  ],
  [
    ErrorType.OutsourcingClientMemberInaccessible,
    { statusCode: 302, destination: getCaWebMessageUrl("outsourcing_client_member") },
  ],
  [
    ErrorType.CaTermNotFound,
    { statusCode: 302, destination: getCaWebMessageUrl("office_term_deleted") },
  ],
  [ErrorType.CurrentCaOfficeOrTermNotFound, { statusCode: 302, destination: getCaWebTopUrl() }],
]);

/**
 * @param data GraphQL query data
 * @param graphQLErrors GraphQL errors
 * @param ignoreErrorTypes
 * @returns matched redirect
 */
const redirectIfErrorCodeMatches = (
  data: InspectAppRedirectCondition | undefined,
  graphQLErrors: readonly GraphQLError[],
  ignoreErrorTypes: readonly ErrorType[] = []
): Redirect | null => {
  const ignoreErrorTypeSet: ReadonlySet<string> = new Set([
    ...ignoreErrorTypes,
    /** this error code needs to be ignored for executing later redirect checks */
    ErrorType.CurrentTermNotMatch,
  ]);

  for (const graphQLError of graphQLErrors) {
    const errorCode = graphQLError.extensions?.code;
    if (errorCode != null) {
      if (ignoreErrorTypeSet.has(errorCode)) {
        continue;
      }
      const redirect = RedirectRulesByErrorType.get(errorCode);
      if (redirect != null) {
        return redirect;
      }
    }
    throw new RangeError(
      `An unexpected graphQL error code "${errorCode ?? ""}" is passed.\n\n` +
        `graphQLError: ${JSON.stringify(graphQLError)}\n` +
        `query data: ${JSON.stringify(data)}\n`
    );
  }
  return null;
};

/** options for matching redirect rule */
export type MatchOption = Readonly<{
  ignoreErrorTypes?: readonly ErrorType[];
  currentCaTerm?: boolean;
  skip?: Readonly<{
    hasCurrentTaxationPeriod?: boolean;
    sameOfficeIdWithCa?: boolean;
    sameCurrentTermWithCa?: boolean;
  }>;
}>;

/**
 * @param data GraphQL query data
 * @param graphQLErrors GraphQL errors
 * @param option matching option
 * @returns matched redirect
 */
export const matchRedirectRules = (
  data: InspectAppRedirectCondition | undefined,
  graphQLErrors: readonly GraphQLError[] | undefined,
  option: MatchOption = {}
): Redirect | null => {
  if (graphQLErrors != null) {
    const redirect = redirectIfErrorCodeMatches(data, graphQLErrors, option.ignoreErrorTypes);
    if (redirect != null) {
      return redirect;
    }
  }

  const currentTaxationPeriod = data?.viewer.currentTaxationPeriod;
  const basicSettingOfficeID = data?.viewer.basicSetting?.officeID;
  const currentTermId = data?.viewer.currentTerm.id;
  const currentCaTermId = data?.viewer.basicSetting.currentCaTerm.id;
  if (!option.skip?.hasCurrentTaxationPeriod) {
    if (currentTaxationPeriod == null) {
      return {
        statusCode: 302,
        destination: PAGE_PATHNAMES.taxationPeriods,
      };
    }
  }
  if (!option.skip?.sameOfficeIdWithCa) {
    if (currentTaxationPeriod != null && basicSettingOfficeID !== currentTaxationPeriod.officeID) {
      return {
        statusCode: 302,
        destination: `${PAGE_PATHNAMES.taxationPeriods}?redirect_reason=different_office`,
      };
    }
  }
  if (!option.skip?.sameCurrentTermWithCa) {
    if (currentTermId !== currentCaTermId) {
      return {
        statusCode: 302,
        destination: `${PAGE_PATHNAMES.taxationPeriods}?redirect_reason=different_term`,
      };
    }
  }
  return null;
};

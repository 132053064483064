import { Button } from "../../components/button";
import { Modal, ModalHeader, ModalFooter, ModalBody } from "../../components/modal";
import { ErrorModalKind } from "./handle-action-error";

/** modal content paired with error kind */
const modalContents: Readonly<Record<ErrorModalKind, () => React.ReactNode>> = {
  NoPermission: () => <ModalHeader>消費税申告書の編集権限がありません</ModalHeader>,
  SyncCaTaskProcessing: () => (
    <>
      <ModalHeader>編集できません</ModalHeader>
      <ModalBody>
        「申告基礎データ」画面でデータを取得中のため、編集・保存ができません。取得が完了してから再度お試しください。
      </ModalBody>
    </>
  ),
  InvalidTaxationPeriodVersionForV2: () => (
    <>
      <ModalHeader>
        この課税期間は2023年9月30日以前の様式で作成されているため、納税額が正しくない可能性があります。
      </ModalHeader>
      <ModalBody>
        「課税期間一覧」画面からこの課税期間を作成し直してください。最新の様式に自動で再作成されます。
      </ModalBody>
    </>
  ),
  XtxValidationError: () => (
    <>
      <ModalHeader>未入力・不備のある項目があるため出力できません</ModalHeader>
      <ModalBody>
        e-Taxによる電子申告を利用する場合は、必須項目と「基本情報」の利用者識別番号が必要です。入力後、「e-Tax用ファイル出力」をクリックしてください。
      </ModalBody>
    </>
  ),
};

export const ErrorNotificationModal: React.FC<{
  /** error modal kind */
  kind: ErrorModalKind;
  /** callback for clicking close button */
  onClose: () => void;
}> = (props) => (
  <Modal>
    {modalContents[props.kind]()}
    <ModalFooter>
      <Button theme="contained" onClick={props.onClose}>
        OK
      </Button>
    </ModalFooter>
  </Modal>
);

import Script from "next/script";
import { GTM_CONTAINER_TAG } from "./gtm-container-tag";
import { useDefineDataLayer } from "./use-define-data-layer";

/**
 * GTM のスクリプトを読み込むスクリプト
 * @returns
 * @see https://moneyforward.kibe.la/notes/151283
 */
export const GtmScript: React.FC = () => {
  const defined = useDefineDataLayer();

  if (!defined) {
    return null;
  }

  return (
    <Script id="gtm-script" strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${GTM_CONTAINER_TAG}');`}
    </Script>
  );
};

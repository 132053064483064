export type EventName =
  // ミューテーションを通して現在の課税期間のデータが更新されるかも知れない時
  "mutateCurrentTaxationPeriod";

export class Emitter {
  private listenersByEventName: { [_ in EventName]?: Set<() => void> } = {};

  off(event: EventName, listener: () => void): void {
    const listeners = this.listenersByEventName[event];
    if (listeners == null) {
      return;
    }
    listeners.delete(listener);
  }

  on(event: EventName, listener: () => void): () => void {
    let listeners = this.listenersByEventName[event];
    if (listeners == null) {
      listeners = new Set();
      this.listenersByEventName[event] = listeners;
    }
    listeners.add(listener);
    return () => this.off(event, listener);
  }

  emit(event: EventName): void {
    const listeners = this.listenersByEventName[event];
    if (listeners == null) {
      return;
    }
    listeners.forEach((listener) => listener());
  }
}

import immer, { Draft } from "immer";
import React from "react";
import { v4 as uuidV4 } from "uuid";
import create, { UseBoundStore, StoreApi } from "zustand";
import { ErrorNotificationModal } from "./error-notification-modal";
import { handleActionErrorFactory } from "./handle-action-error";
import { ToastParam, SideEffectViewState } from "./types";

const TOAST_AUTO_CLOSE_MILLISECONDS = 5000;

const initialState: SideEffectViewState = {
  toastItems: [],
  modals: [],
};

const createActions = ({ getState, setState }: StoreApi<SideEffectViewState>) => {
  const updateState = (f: (_: Draft<SideEffectViewState>) => void) => {
    setState(immer(getState(), f));
  };

  /**
   * Remove toast
   * @param id toast id
   */
  const removeToast = (id: string): void => {
    updateState((draft) => {
      draft.toastItems = draft.toastItems.filter((el) => el.id !== id);
    });
  };

  /**
   * Add toast
   * @param params
   */
  const addToast = (params: ToastParam): void => {
    const id = uuidV4();
    updateState((draft) => {
      draft.toastItems.push({ ...params, id });
    });
    // set timer to close toast automatically.
    setTimeout((): void => removeToast(id), TOAST_AUTO_CLOSE_MILLISECONDS);
  };

  const modal = <T = void,>(render: (done: (result: T) => void) => React.ReactNode): Promise<T> => {
    return new Promise<T>((resolve) => {
      const id = uuidV4();
      const done = (result: T) => {
        updateState((draft) => {
          draft.modals = draft.modals.filter((el) => el.id !== id);
        });
        resolve(result);
      };
      updateState((draft) => {
        draft.modals.push({ id, node: render(done) });
      });
    });
  };

  return {
    removeToast: removeToast,
    addToast: addToast,
    modal: modal,
    handleActionError: handleActionErrorFactory({
      addToast,
      showErrorModal: (kind) =>
        modal((done) => <ErrorNotificationModal kind={kind} onClose={done} />),
    }),
    clearModalStack: (): void => {
      updateState((draft) => {
        draft.modals = [];
      });
    },
  } as const;
};

export type SideEffectViewActions = ReturnType<typeof createActions>;

export type SideEffectViewStore = Readonly<{
  useStore: UseBoundStore<StoreApi<SideEffectViewState>>;
  actions: SideEffectViewActions;
}>;

export const createStore = (): SideEffectViewStore => {
  const useStore = create<SideEffectViewState>(() => initialState);
  const actions = createActions(useStore);

  return { useStore, actions };
};

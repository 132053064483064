import { ApolloError } from "@apollo/client";
import { ErrorType } from "../../generated/graphql-types";
import { BadRequestError } from "./bad-request-error";
import { GraphQLErrorParser } from "./graphql-error-parser";

/** GraphQL error codes that are regarded as user errors, not internal errors */
const userErrorCodes: readonly string[] = [
  ErrorType.Unauthorized,
  ErrorType.CaTermsAgreementRequired,
  ErrorType.NoPermission,
  ErrorType.PremiumRequired,
  ErrorType.OfficeMemberNotFound,
  ErrorType.TaxableSettingRequired,
  ErrorType.OutsourcingClientMemberInaccessible,
  ErrorType.CaTermNotFound,
  ErrorType.CurrentCaOfficeOrTermNotFound,
  ErrorType.CurrentTermNotMatch,
  "CurrentTermNotFound",
  "CurrentOfficeNotFound",
];

/**
 * detect if error is user error that should not be reported to Rollbar or not
 * @param error thrown thing
 * @returns if error is user error or not
 */
export const isUserError = (error: unknown): boolean => {
  if (error instanceof BadRequestError) {
    return true;
  }
  if (error instanceof ApolloError) {
    // network error is client side problem
    if (error.networkError != null) {
      return true;
    }
    const parsed = GraphQLErrorParser.fromApolloError(error);
    for (const code of userErrorCodes) {
      if (parsed.hasErrorCode(code)) {
        return true;
      }
    }
  }
  return false;
};

type RuntimeConfigAccessor = Readonly<{
  /**
   * Next.js の Runtime Config から値を取得します
   * @param key Runtime Config のキー
   * @returns "next.config.js" で定義されている Runtime Config のキーに紐づく値
   *          null = キーが存在しない、または、文字列ではない
   */
  getString: (key: string) => string | null;
  /**
   * Next.js の Runtime Config から値を取得します
   * @param key Runtime Config のキー
   * @returns "next.config.js" で定義されている Runtime Config のキーに紐づく値
   * @throws キーが存在しない、または、文字列ではない場合
   */
  ensureString: (key: string) => string;
}>;

type GetConfig = () => Readonly<Record<string, unknown>>;

/**
 * 戻り値の型が any になっている "next/config" の getConfig() の型安全なアクセサを生成します。
 * @param getConfig "next/config" の getConfig()
 * @returns Runtime Configuration のアクセサ
 */
export const createAccessor = (getConfig: GetConfig): RuntimeConfigAccessor => {
  const getString = (key: string): string | null => {
    const value = getConfig()[key];
    return typeof value === "string" ? value : null;
  };

  return {
    getString,
    ensureString: (key) => {
      const value = getString(key);
      if (value == null) {
        throw new Error(`Runtime Configuration "${key}" is expected to be string.`);
      }
      return value;
    },
  };
};

import { GTM_CONTAINER_TAG } from "./gtm-container-tag";

/**
 * スクリプトの実行が許可されていない場合を考慮した GTM のスクリプト読み込み
 * @returns
 * @see https://moneyforward.kibe.la/notes/151283
 */
export const GtmNoScript: React.FC = () => (
  <noscript>
    <iframe
      src={`https://www.googletagmanager.com/ns.html?id=${GTM_CONTAINER_TAG}`}
      height="0"
      width="0"
      style={{ display: "none", visibility: "hidden" }}
    />
  </noscript>
);

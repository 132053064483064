import React from "react";
import { createStoreContext } from "../../utils/store-helpers";
import { createStore, SideEffectViewStore, SideEffectViewActions } from "./create-store";
import { SideEffectViewState } from "./types";

const storeContext = createStoreContext<SideEffectViewState, SideEffectViewActions>();

export const SideEffectViewProvider: React.FC<{
  store?: SideEffectViewStore;
  children?: React.ReactNode;
}> = (props) => {
  const storeRef = React.useRef<SideEffectViewStore>();
  if (storeRef.current == null) {
    storeRef.current = props.store ?? createStore();
  }
  return (
    <storeContext.StoreProvider store={storeRef.current}>
      {props.children}
    </storeContext.StoreProvider>
  );
};

export const useSideEffectViewStore = storeContext.useStore;

export const useSideEffectViewActions = storeContext.useActions;

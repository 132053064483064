import getConfig from "next/config";
import { createAccessor } from "./create-accessor";

/**
 * getConfig().serverRuntimeConfig の型安全なアクセサ
 * @see https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
export const serverRuntimeConfig = createAccessor(() => getConfig()?.serverRuntimeConfig ?? {});

/**
 * getConfig().publicRuntimeConfig の型安全なアクセサ
 * @see https://nextjs.org/docs/api-reference/next.config.js/runtime-configuration
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
export const publicRuntimeConfig = createAccessor(() => getConfig()?.publicRuntimeConfig ?? {});

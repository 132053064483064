import { CookieSerializeOptions } from "cookie";
import { NextPageContext } from "next";
import nookies from "nookies";
import { v4 as uuidV4 } from "uuid";

const COOKIE_NAMES = {
  ACCESS_TOKEN: "consumption_tax_web_access_token",
  OAUTH_STATE: "consumption_tax_web_oauth_state",
} as const;

const DEFUALT_COOKIE_SERIALIZE_OPTIONS: CookieSerializeOptions = {
  // 開発環境では https ではないため
  secure: process.env.NODE_ENV !== "development",
  sameSite: "lax",
} as const;

export const getAccessToken = (ctx?: Pick<NextPageContext, "req">): string | undefined => {
  return nookies.get(ctx)[COOKIE_NAMES.ACCESS_TOKEN];
};
export const setAccessToken = (token: string, ctx?: Pick<NextPageContext, "res">): void => {
  nookies.set(ctx, COOKIE_NAMES.ACCESS_TOKEN, token, DEFUALT_COOKIE_SERIALIZE_OPTIONS);
};
export const removeAccessToken = (ctx?: Pick<NextPageContext, "res">): void => {
  nookies.destroy(ctx, COOKIE_NAMES.ACCESS_TOKEN);
};

export const getOAuthState = (ctx?: Pick<NextPageContext, "req">): string | undefined => {
  return nookies.get(ctx)[COOKIE_NAMES.OAUTH_STATE];
};
export const setOAuthState = (state: string, ctx?: Pick<NextPageContext, "res">): void => {
  nookies.set(ctx, COOKIE_NAMES.OAUTH_STATE, state, DEFUALT_COOKIE_SERIALIZE_OPTIONS);
};
export const removeOAuthState = (ctx?: Pick<NextPageContext, "res">): void => {
  nookies.destroy(ctx, COOKIE_NAMES.OAUTH_STATE);
};
export const createOAuthState = (): string => {
  return uuidV4();
};

import { publicRuntimeConfig } from "../next-helpers/runtime-config";

type MessageType =
  | "permission"
  | "premium"
  | "office_excise"
  | "outsourcing_client_member"
  | "office_term_deleted"
  | "term_not_match_summary_table";

/**
 * @returns URL to ca_web's / (root path)
 */
export const getCaWebTopUrl = (): string => {
  return publicRuntimeConfig.ensureString("NEXT_PUBLIC_CA_WEB_TOP_URL");
};

/**
 * @param type message type
 * @returns URL to ca_web with error message
 */
export const getCaWebMessageUrl = (type: MessageType): string => {
  const url = new URL(getCaWebTopUrl());
  url.pathname = "/consumption_taxs/message";
  const search = new URLSearchParams();
  search.append("type", type);
  url.search = search.toString();
  return url.toString();
};

/**
 * @returns URL to ca_web's /excises
 */
export const getCaWebConsumptionTaxSummaryUrl = (): string => {
  const url = new URL(getCaWebTopUrl());
  url.pathname = "/excises";
  return url.toString();
};

/**
 * @returns URL to ca_web's /books
 */
export const getCaWebJournalBookUrl = (): string => {
  const url = new URL(getCaWebTopUrl());
  url.pathname = "/books";
  return url.toString();
};

/**
 * @returns URL to ca_web's /offices/edit
 */
export const getCaWebOfficeSettingUrl = (): string => {
  const baseUrl = new URL(getCaWebTopUrl());
  baseUrl.pathname = "/offices/edit";
  return baseUrl.toString();
};

/**
 * @returns URL to ca_web's /supports
 */
export const getCaWebSupportsUrl = (): string => {
  const baseUrl = new URL(getCaWebTopUrl());
  baseUrl.pathname = "/supports";
  return baseUrl.toString();
};

/**
 * @returns URL to ca_web's excise list screen
 */
export const getCaWebExciseSettingUrl = (): string => {
  const baseUrl = new URL(getCaWebTopUrl());
  baseUrl.pathname = "/excises/list";
  return baseUrl.toString();
};

/**
 * @returns URL to ca_web's excise batch update screen
 */
export const getCaWebExciseBatchUpdateUrl = (): string => {
  const baseUrl = new URL(getCaWebTopUrl());
  baseUrl.pathname = "/bulk_action/starting_invoice";
  return baseUrl.toString();
};

/**
 * @returns URL to ca_web's office member not found
 */
export const getCaWebOfficeMemberNotFound = (): string => {
  const baseUrl = new URL(getCaWebTopUrl());
  baseUrl.pathname = "/office_access_error";
  return baseUrl.toString();
};

import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import { ValueOf } from "type-fest";
import { ErrorType } from "../../generated/graphql-types";

/** GraphQLError parser for error handling */
export class GraphQLErrorParser {
  readonly errors: readonly GraphQLError[];

  constructor(errors: readonly GraphQLError[]) {
    this.errors = errors;
  }

  static fromApolloError(error: ApolloError): GraphQLErrorParser {
    return new GraphQLErrorParser(error.graphQLErrors);
  }

  hasErrorCode(code: ValueOf<ErrorType>): boolean {
    return this.errors.some((error) => error.extensions.code === code);
  }

  displayMessages(): string[] {
    const messages: string[] = [];
    for (const error of this.errors) {
      const message = error.extensions.displayMessage;
      if (typeof message === "string" && message !== "") {
        messages.push(message);
      }
    }
    return messages;
  }
}

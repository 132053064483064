import { css } from "@emotion/react";
import { createPortal } from "react-dom";
import { Z_INDEX } from "../../utils/styles";
import { Backdrop } from "./backdrop";

const CSS_WIDTH_WIDE = "is_wide";

const styles = {
  container: css`
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${Z_INDEX.MODAL};
    width: 100vw;
    height: 100vh;
    overflow: auto;
  `,
  backdrop: css`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  `,
  modal: css`
    width: 480px;
    min-width: 480px;
    margin: 16px;
    background: #fff;
    border-radius: 8px;
    box-shadow:
      0 8px 10px rgb(0 0 0 / 14%),
      0 3px 14px rgb(0 0 0 / 12%),
      0 5px 5px rgb(0 0 0 / 20%);

    &.${CSS_WIDTH_WIDE} {
      width: 560px;
      min-width: 560px;
    }
  `,
  header: css`
    padding: 16px 24px;
    font-size: 18px;
    font-weight: normal;
  `,
  body: css`
    padding: 16px 24px;
    font-size: 14px;
  `,
  footer: css`
    display: flex;
    flex-flow: row wrap;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
  `,
} as const;

type ModalWidthKind = "default" | "wide";

const ModalDialog: React.FC<{
  children?: React.ReactNode;
  width?: ModalWidthKind;
}> = (props) => {
  const classList: string[] = [];
  if (props.width === "wide") {
    classList.push(CSS_WIDTH_WIDE);
  }
  return (
    <div css={styles.modal} className={classList.join(" ")} data-testid="modalDialog">
      {props.children}
    </div>
  );
};

export const Modal: React.FC<{
  children?: React.ReactNode;
  width?: ModalWidthKind;
  "data-testid"?: string | undefined;
}> = (props) => {
  return createPortal(
    <div css={styles.container} data-testid={props["data-testid"]}>
      <Backdrop css={styles.backdrop}>
        <ModalDialog width={props.width}>{props.children}</ModalDialog>
      </Backdrop>
    </div>,
    window.document.body
  );
};

export const ModalHeader: React.FC<{
  children?: React.ReactNode;
  className?: string;
  "data-testid"?: string | undefined;
}> = (props) => (
  <div className={props.className} data-testid={props["data-testid"]} css={styles.header}>
    {props.children}
  </div>
);

export const ModalBody: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = (props) => (
  <div className={props.className} css={styles.body}>
    {props.children}
  </div>
);

export const ModalFooter: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = (props) => (
  <div className={props.className} css={styles.footer}>
    {props.children}
  </div>
);

import { css } from "@emotion/react";
import { createFocusTrap } from "focus-trap";
import { createRef, useEffect } from "react";

const styles = {
  backdrop: css`
    min-width: 100%;
    min-height: 100%;
    background: rgb(66 73 84 / 70%);
  `,
} as const;

/**
 * @param active activate this if true
 */
export const useBodyOverflowHidden = (active = true): void => {
  useEffect(() => {
    if (!active) {
      return;
    }
    const { overflowX, overflowY } = document.body.style;
    document.body.style.overflowX = "hidden";
    document.body.style.overflowY = "hidden";
    return (): void => {
      document.body.style.overflowX = overflowX;
      document.body.style.overflowY = overflowY;
    };
  }, [active]);
};

/**
 * @param ref DOM element React ref
 * @note ref element MUST be focusable.
 */
const useFocusTrap = (ref: React.RefObject<HTMLElement | null>): void => {
  useEffect(() => {
    const el = ref?.current;
    if (el == null) {
      return;
    }
    const focusTrap = createFocusTrap(el, {
      // specify `fallbackFocus` because focus-trap throw an exception if there is no focusable element.
      fallbackFocus: el,
      // allow to click the outside elements because the user cannot click Toasts due to this.
      allowOutsideClick: true,
    });
    focusTrap.activate();
    return () => void focusTrap.deactivate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};

/** Backdrop for Modal component */
export const Backdrop: React.FC<{
  className?: string | undefined;
  children?: React.ReactNode;
}> = (props) => {
  const backdropRef = createRef<HTMLDivElement>();
  useBodyOverflowHidden();
  useFocusTrap(backdropRef);
  return (
    <div
      ref={backdropRef}
      css={styles.backdrop}
      className={props.className}
      data-testid="modalBackdrop"
      // make this focusable by JS to specify this as `fallbackFocus`
      tabIndex={-1}
    >
      {props.children}
    </div>
  );
};

import { css, SerializedStyles } from "@emotion/react";
import { forwardRef, useMemo } from "react";

export const ALL_BUTTON_THEMES = ["contained", "outline", "text"] as const;

export type ButtonTheme = (typeof ALL_BUTTON_THEMES)[number];

export const ALL_BUTTON_SIZES = ["small", "default", "large"] as const;

export type ButtonSize = (typeof ALL_BUTTON_SIZES)[number];

export type ButtonProps = React.ComponentPropsWithRef<"button"> & {
  theme: ButtonTheme;
  size?: ButtonSize;
  "data-testid"?: string;
};

const styles = {
  /** common style */
  button: css`
    /* 指定漏れがあってもボタンが縮んだりしないように透明な border をあらかじめ入れておく */
    border: 1px solid transparent;
    border-radius: 4px;

    &:disabled {
      /* disabled のスタイルは theme に依存せず全部一緒 */
      color: #fff;
      cursor: not-allowed;
      background: #dce0e6;
      border-color: #dce0e6;
    }

    &:hover:not(:disabled) {
      cursor: pointer;
    }
  `,
  /** themed style */
  themeContained: css`
    color: #fff;
    background: #3b7de9;
    border-color: #3b7de9;

    &:hover:not(:disabled) {
      background: #76a4f0;
      border-color: #76a4f0;
    }
  `,
  themeOutlint: css`
    color: #3b7de9;
    background: #fff;
    border-color: #3b7de9;

    &:hover:not(:disabled) {
      color: #76a4f0;
      border-color: #76a4f0;
    }
  `,
  themeText: css`
    color: #333;
    background: #fff;
    border-color: #c5cbcf;

    &:hover:not(:disabled) {
      color: #666;
      border-color: #d4d8dd;
    }
  `,
  /** size style */
  sizeDefault: css`
    padding: 8px 12px;
    font-size: 13px;
  `,
  sizeLarge: css`
    padding: 10px 24px;
    font-size: 16px;
  `,
  sizeSmall: css`
    padding: 4px 8px;
    font-size: 12px;
  `,
} as const;

const styleByTheme: Readonly<Record<ButtonTheme, SerializedStyles>> = {
  contained: styles.themeContained,
  outline: styles.themeOutlint,
  text: styles.themeText,
};
const styleBySize: Readonly<Record<ButtonSize, SerializedStyles>> = {
  default: styles.sizeDefault,
  large: styles.sizeLarge,
  small: styles.sizeSmall,
};

const makeCssProps = (theme: ButtonTheme, size: ButtonSize | undefined): SerializedStyles => {
  const styleList: SerializedStyles[] = [styles.button];

  styleList.push(styleByTheme[theme]);
  styleList.push(styleBySize[size ?? "default"]);

  return css(styleList);
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { theme, size, children, ...others } = props;
  const cssProp = useMemo(() => makeCssProps(theme, size), [theme, size]);

  return (
    <button {...others} css={cssProp} ref={ref}>
      {children}
    </button>
  );
});

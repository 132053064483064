/* eslint-disable */
// This file was automatically generated and should not be edited.
// @ts-nocheck
import * as Types from "../../../generated/graphql-types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type SaveWebAccessLogVariables = Types.Exact<{
  input: Types.SaveWebAccessLogInput;
}>;

export type SaveWebAccessLog = { readonly saveWebAccessLog: boolean };

export const SaveWebAccessLogDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SaveWebAccessLog" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "SaveWebAccessLogInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveWebAccessLog" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SaveWebAccessLog, SaveWebAccessLogVariables>;

import Rollbar from "rollbar";
import { createRollbar } from "./create-rollbar";

let rollbarInstance: Rollbar | null = null;

export const getRollbar = (): Rollbar | null => {
  if (rollbarInstance == null) {
    rollbarInstance = createRollbar();
  }
  return rollbarInstance;
};

import { DataLayerItem } from "./types";

/**
 * GTM が window に定義されていることを期待する変数の型
 * @see https://developers.google.com/tag-manager/devguide
 */
declare const window: Window["window"] & {
  dataLayer?: unknown[];
};

/**
 * window に dataLayer が宣言されていなければ宣言する
 */
export const defineDataLayer = (): void => {
  window.dataLayer = window.dataLayer || [];
};

/**
 * window に宣言された dataLayer を削除する
 */
export const deleteDataLayer = (): void => {
  delete window.dataLayer;
};

/**
 * dataLayer にアイテムを追加
 * @param item
 */
export const pushDataLayerItem = (item: DataLayerItem): void => {
  window.dataLayer?.push(item);
};

/**
 * dataLayer を返す
 * @returns
 */
export const getDataLayer = (): unknown[] | undefined => {
  return window.dataLayer;
};

import { ApolloClient, DefaultOptions, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { getAccessToken } from "../cookie";
import { publicRuntimeConfig } from "../next-helpers/runtime-config";

type ApolloClientWithCacheObject = ApolloClient<NormalizedCacheObject>;

const createHeaders = (): Record<string, string> => {
  const defaultHeaders = {};
  const accessToken = getAccessToken();
  if (!accessToken) {
    return defaultHeaders;
  }
  return {
    ...defaultHeaders,
    Authorization: `Bearer ${accessToken}`,
  };
};

export const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: "no-cache",
  },
  mutate: {
    fetchPolicy: "no-cache",
  },
  watchQuery: {
    fetchPolicy: "no-cache",
  },
};

const createApolloClient = (): ApolloClientWithCacheObject => {
  const headers = createHeaders();
  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: publicRuntimeConfig.ensureString("NEXT_PUBLIC_BACKEND_URL"),
    headers,
    defaultOptions,
    /**
     * デフォルトの挙動だと AbortController で Abort された query/mutation が再実行されないため
     * @see https://github.com/apollographql/apollo-client/issues/4150#issuecomment-500127694
     */
    queryDeduplication: false,
  });
};

let apolloClientSingleton: ApolloClientWithCacheObject | undefined;

export const getApolloClient = (): ApolloClientWithCacheObject => {
  if (apolloClientSingleton) {
    return apolloClientSingleton;
  }

  apolloClientSingleton = createApolloClient();
  return apolloClientSingleton;
};
